import { Grid, Typography, Button, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import {
  useCopyOtherMenuMutation,
  useGetMenusForUserQuery,
} from "../../../../store/apis/MenuApis";
import BaseContent from "../../../shared/baseContent/BaseContent";
import useMsalAccount from "utils/useMsalAccount";

export default function CopyOtherMenuoptions() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("menuId");
  const location = useLocation();
  const { userId } = useMsalAccount();
  const title = location?.state?.title;

  const {
    data: menuCategories,
    // isSuccess: mcSuccess,
    isLoading: mcLoading,
  } = useGetMenusForUserQuery(
    {
      userId: userId,
      searchTerm: "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const menuCategoriesSelect = menuCategories?.map((value) => ({
    id: value.id,
    label: value.menuName,
  }));

  const [
    CopyOtherMenuSelection,
    { isLoading: addSubmitting, isSuccess: addSuccess },
  ] = useCopyOtherMenuMutation();

  const isSubmitting = addSubmitting;
  const isSuccess = addSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    let interval;
    if (!isSubmitting && isSuccess) {
      interval = setInterval(() => navigate(-1), 3000);
    }
    return () => clearInterval(interval);
  }, [isSubmitting, isSuccess]);

  function onSubmit(data) {
    CopyOtherMenuSelection({
      fromMenuId: data?.fromMenuId,
      toMenuId: id,
    });
  }

  return (
    <>
      <BaseContent
        headerText={"Edit Corporate Group Access"}
        disableHeight={true}>
        <Box height={{ md: "78vh", xl: "84vh" }}>
          <Typography variant="h5" marginY={"10px"}>
            Copy Other Menu Selections to {title} Menu
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <BaseSelect
                name="fromMenuId"
                id="fromMenuId"
                label="Select a Menu to copy from"
                control={control}
                errors={errors}
                options={menuCategoriesSelect || []}
                loading={mcLoading}
              />
            </Grid>
            <Grid item md={6} />

            <Grid item md={6}>
              <Button
                sx={{ marginBlock: "5px" }}
                color={"primary"}
                className="baseButton"
                variant="contained"
                onClick={handleSubmit(onSubmit)}>
                Copy Selection
              </Button>
            </Grid>
          </Grid>
        </Box>
      </BaseContent>
    </>
  );
}
