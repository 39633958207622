import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { BaseButton } from "components/shared/BaseButton";
import { InfoOutlined } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useForm } from "react-hook-form";
import BaseSelect from "components/shared/baseForm/BaseSelect";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useGetMealExtraSelectBoxQuery } from "store/apis/MealExtrasApis";
import {
  useAddFacilityMealExtraMutation,
  useGetFacilityMealExtraByIdQuery,
  useGetFacilityMealExtraQuery,
  useUpdateFacilityMealExtraMutation,
  useUpdateImportStandardMealExtraMutation,
} from "store/apis/FacilityMealExtraApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import { EditSkeleton } from "components/admin/admin.overlays";

export default function AddFacilityMealExtrasSelection() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const currentFacilityId = useSelector(selectFacilityId);
  const [filteredData, setFilteredData] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isEdit = Boolean(id);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const { data: selectMealExtras, isLoading: selectLoading } =
    useGetMealExtraSelectBoxQuery();

  const {
    data: facilityMealExtras,
    // isError: facilityMealExtrasError,
    // isFetching: facilityMealExtrasLoading,
  } = useGetFacilityMealExtraQuery(
    {
      facilityId: currentFacilityId,
      searchText: "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (facilityMealExtras) {
      const dataa = facilityMealExtras?.map((item) => item.mealExtraId);
      setFilteredData(
        selectMealExtras?.filter((mealExtra) => !dataa.includes(mealExtra.id))
      );
    }
  }, [facilityMealExtras, selectMealExtras]);

  const { data: mealExtraData, isLoading: mealExtraLoading } =
    useGetFacilityMealExtraByIdQuery(id, {
      skip: !isEdit,
    });

  const [importDiningRDMealExtras, { isLoading: importLoading }] =
    useUpdateImportStandardMealExtraMutation({
      facilityId: currentFacilityId,
    });

  const [
    addFacilityMealExtras,
    { isLoading: addLoading, isSuccess: addSuccess },
  ] = useAddFacilityMealExtraMutation();

  const [
    updateFacilityMealExtras,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateFacilityMealExtraMutation();

  useEffect(() => {
    if (isEdit && mealExtraData) {
      setValue("mealExtraId", mealExtraData.mealExtraId);
    }
  }, [isEdit, mealExtraData, setValue]);

  useEffect(() => {
    if (addSuccess || updateSuccess) navigate(-1);
  }, [addSuccess, updateSuccess, navigate]);

  const onSubmit = (data) => {
    if (isEdit) {
      const updateData = {
        id,
        facilityId: currentFacilityId,
        mealExtraId: data.mealExtraId,
        ...mealExtraData,
      };
      updateFacilityMealExtras(updateData);
    } else {
      addFacilityMealExtras({
        ...data,
        facilityId: currentFacilityId,
        mealExtraName: "",
        facilityName: "",
      });
    }
  };

  if (mealExtraLoading) return <EditSkeleton />;

  return (
    <Box paddingY="10px">
      <Box display="flex" justifyContent="space-between">
        <BaseButton
          text="Back to Meal Add-Ons"
          startIcon={
            <span className="material-icons">keyboard_arrow_left</span>
          }
          onClick={() => navigate(-1)}
        />
        <Box>
          <BaseButton
            text="Import ALL Meal Add-Ons"
            endIcon={<span className="material-icons">import_export</span>}
            colors="warning"
            isSubmitting={importLoading}
            onClick={() => importDiningRDMealExtras(currentFacilityId)}
          />
          <Tooltip
            arrow
            title={`"Import ALL Meal Add-Ons" will allow importing of DiningRD's current list of Meal Add-Ons to the "House Extras" list.`}>
            <IconButton color="primary">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
          <BaseButton
            text="Remove ALL Meal Add-Ons"
            endIcon={<span className="material-icons">delete</span>}
            colors="error"
            onClick={() => setOpenDeleteModal(true)}
          />
          <Tooltip
            arrow
            title={`"Remove ALL Meal Add-Ons" will remove ALL DiningRD's Meal Add-Ons from the "House Extras" list leaving only "House Extras"`}>
            <IconButton color="primary">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {openDeleteModal && (
        <DeleteModal
          id={currentFacilityId}
          entityName="All DiningRD Meal Add-Ons"
          apiPath="updateRemoveStandardMealExtra"
          open={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
        />
      )}
      <Box width="30vw" marginTop={3} marginLeft={1.5}>
        <BaseSelect
          label="Meal Add-On"
          name="mealExtraId"
          id="mealExtraId"
          control={control}
          options={filteredData || []}
          errors={errors}
          loading={selectLoading}
          validationProps={{
            required: REQUIRED_ERROR("Meal Add-On"),
          }}
        />
        <Box marginY={1}>
          <BaseSubmitButton
            text={isEdit ? "Submit" : "Add"}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            isSubmitting={addLoading || updateLoading}
          />
        </Box>
      </Box>
    </Box>
  );
}
