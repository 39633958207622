import {
  breakfastQty1,
  censusText,
  endingDayShoppingList,
  offset6,
  offset8,
  shoppingListGrouping,
  startingDayShoppingList,
  lunchQty2,
  supperQty3,
  formatTypeId,
} from "../form.constants";

export const ShoppingListByDayForms = {
  Category: true,
  DayForm: [
    {
      ...startingDayShoppingList,
      validationProps: {
        ...startingDayShoppingList.validationProps,
        validate: (value, formValues) =>
          parseInt(value) + 6 < parseInt(formValues.endingDay)
            ? "Starting day should not be more than 7 days from the ending day"
            : null,
      },
    },
    offset6,
    {
      ...endingDayShoppingList,
      validationProps: {
        ...endingDayShoppingList.validationProps,
        validate: (value, formValues) =>
          parseInt(value) > parseInt(formValues.startingDay) + 6
            ? "Ending day should not be more than 7 days from the starting day"
            : null,
      },
    },
    offset6,
    shoppingListGrouping,
    offset6,
  ],
  Census: [
    censusText,
    breakfastQty1,
    offset8,
    lunchQty2,
    offset8,
    supperQty3,
    offset8,
  ],
  Diet: true,
  Report: [formatTypeId],
};
