import { Box, IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";
import { RecipeCard } from "./RecipeCard";
import { MealViewModal } from "./MealViewModal";
import { RecipesViewModal } from "./RecipesViewModal";
import { useCustomDrop } from "../../../../shared/customeHooks/useCustomDrop";
import { isUndefined } from "lodash";
import { Menu } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";

export const ColumnWithData = ({
  day,
  mealName,
  isDescription,
  isCost,
  recipeArr,
  setMenuPlannerData,
  menuPlannerData,
  rows,
  column,
  backroundColor,
  setBackroundColor,
  isOtherMenu,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const [recipeData, setRecipeData] = useState("");
  const [openViewModal, setOpenViewModal] = useState(false);
  const dispatch = useDispatch();

  const statusChange = (item) => {
    const menuPlanner = structuredClone(menuPlannerData);
    //check whether we want to sort the recipes
    if (item?.column !== column || item?.rows !== rows) {
      if (item?.rows !== undefined) {
        //check whether its fully new entry in menuplanner

        let duplicatedRecipes = menuPlannerData?.resultSet[rows]?.days[
          column
        ]?.recipes?.filter(
          (individualRecipe) =>
            individualRecipe.recipeId === item?.finalData.recipeId
        );
        //:TODO
        //check whether the recipes is already exist or not
        if (duplicatedRecipes?.length === 0) {
          dispatch(setIsChangeTrue(true));
          let previousRecipes =
            menuPlannerData?.resultSet[item?.rows]?.days[item?.column]?.recipes;
          previousRecipes = previousRecipes.filter(
            (individualRecipe) =>
              individualRecipe.recipeId !== item?.finalData.recipeId
          );
          let updatedRecipes = structuredClone(
            menuPlannerData?.resultSet[rows]?.days[column]?.recipes
          );
          updatedRecipes.splice(item?.index, 0, item?.finalData);
          menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
          menuPlanner.resultSet[item.rows].days[item.column].recipes =
            previousRecipes;

          setMenuPlannerData(menuPlanner);
        }
      } else {
        dispatch(setIsChangeTrue(true));
        let duplicatedRecipes =
          menuPlannerData?.resultSet[rows]?.days[column]?.recipes;
        duplicatedRecipes = duplicatedRecipes.filter(
          (individualRecipe) =>
            individualRecipe.recipeId === item?.finalData?.recipeId
        );

        if (duplicatedRecipes?.length === 0) {
          let updatedRecipes = structuredClone(
            menuPlannerData?.resultSet[rows]?.days[column]?.recipes
          );

          updatedRecipes.splice(item?.index, 0, item?.finalData);
          menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
          setMenuPlannerData(menuPlanner);
        }
      }
    }
  };
  const { isOver, drop } = useCustomDrop("card", statusChange);
  const moveCard = useCallback(
    (dragIndex, hoverIndex, item) => {
      if (item.column === column && item.rows === rows) {
        if (!isUndefined(dragIndex)) {
          const menuPlanner = structuredClone(menuPlannerData);

          let dragCard =
            menuPlannerData?.resultSet[rows]?.days[column]?.recipes[dragIndex];

          const updatedCards = structuredClone(
            menuPlannerData?.resultSet[rows]?.days[column]?.recipes
          ); // Create a shallow copy of the cards array
          if (dragCard) {
            updatedCards.splice(dragIndex, 1); // Remove the dragged card from its original position
            updatedCards.splice(hoverIndex, 0, dragCard); // Insert the dragged card at the new position
          }
          menuPlanner.resultSet[rows].days[column].recipes = updatedCards;
          setMenuPlannerData(menuPlanner);
        }
      }
    },

    [menuPlannerData]
  );
  const renderCard = (data, index) => {
    return (
      <RecipeCard
        categoryStrips={true}
        key={index}
        rows={rows}
        column={column}
        isDescription={isDescription}
        isCost={isCost}
        mealName={mealName}
        dayName={day?.label}
        data={data}
        index={index}
        setOpenViewModal={setOpenViewModal}
        setRecipeData={setRecipeData}
        moveCard={moveCard}
        backroundColor={backroundColor}
        setBackroundColor={setBackroundColor}
      />
    );
  };
  return (
    <>
      <Box
        ref={drop}
        height={{ md: "auto" }}
        margin="0px"
        width={{ md: "100%", xl: "100%" }}
        boxShadow="var(--darkBoxShadow)"
        border="0.2px solid #CCCCCC"
        borderRadius="10px"
        position="relative">
        <Box position="absolute" left="0.5px">
          <IconButton size="small" onClick={() => setOpenModal(true)}>
            <Menu color="warning" />
          </IconButton>
        </Box>
        <Box
          sx={{
            opacity: isOver ? 0.5 : 1,
            fontSize: "small",
          }}
          display="flex"
          flexDirection="column"
          paddingTop="30px"
          overflow="auto">
          {recipeArr?.recipes?.map((data, index) => renderCard(data, index))}
        </Box>
      </Box>

      {openModal ? (
        <MealViewModal
          setMenuPlannerData={setMenuPlannerData}
          menuPlannerData={menuPlannerData}
          rows={rows}
          column={column}
          open={openModal}
          close={(event) => {
            event.preventDefault();
            setOpenModal(false);
          }}
        />
      ) : null}
      {openViewModal ? (
        <RecipesViewModal
          open={openViewModal}
          close={(event) => {
            event.preventDefault();
            setOpenViewModal(false);
          }}
          recipeData={recipeData}
          setMenuPlannerData={setMenuPlannerData}
          menuPlannerData={menuPlannerData}
          rows={rows}
          column={column}
          isOtherMenu={isOtherMenu}
        />
      ) : null}
    </>
  );
};
