import { Grid } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useGetResidentSnackListQuery } from "../../../../store/apis/ResidentApis";
import { useGetMealTypesListQuery } from "../../../../store/apis/GenericApis";
import { useGetUnitsQuery } from "../../../../store/apis/UnitApis";
import { useGetFoodTypeByIdQuery } from "../../../../store/apis/FoodTypesApis";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";

export default function ResidentSnacks() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentSnacks,
    isLoading,
    isError,
  } = useGetResidentSnackListQuery(residentId, { skip: !residentId });

  const { data: mealTypes } = useGetMealTypesListQuery();

  const { data: units } = useGetUnitsQuery("");

  const snackColumns = [
    {
      field: "foodTypeId",
      minWidth: 50,
      flex: 2,
      headerName: "Food",
      renderCell: (params) => (
        <>{useGetFoodTypeByIdQuery(params?.row?.foodTypeId).data?.name}</>
      ),
    },
    {
      field: "description",
      minWidth: 50,
      flex: 2,
      headerName: "Description",
    },
    {
      field: "amount",
      minWidth: 50,
      flex: 1,
      headerName: "Amount",
      renderCell: (params) => (
        <>
          {params?.row?.quantity}{" "}
          {units?.find((unit) => unit.id === params?.row?.unitId).label}
        </>
      ),
    },
    {
      field: "mealId",
      minWidth: 50,
      flex: 1,
      headerName: "Time",
      renderCell: (params) => (
        <>
          {
            mealTypes?.find((mealType) => mealType.id === params?.row?.mealId)
              .label
          }
        </>
      ),
    },
    {
      field: "days",
      minWidth: 50,
      flex: 1,
      headerName: "Days",
      renderCell: (params) => {
        const daysArray = [];

        params?.row?.onSun ? daysArray.push("Su") : null;
        params?.row?.onMon ? daysArray.push("Mo") : null;
        params?.row?.onTue ? daysArray.push("Tu") : null;
        params?.row?.onWed ? daysArray.push("We") : null;
        params?.row?.onThu ? daysArray.push("Th") : null;
        params?.row?.onFri ? daysArray.push("Fr") : null;
        params?.row?.onSat ? daysArray.push("Sa") : null;

        const daysStr = daysArray.join(" ");

        return <>{daysStr}</>;
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        console.log(params.row);
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentSnacks}
            editProps={{
              To: `addResidentSnack?id=${residentId}&snackId=${params.row.id}`,
              title: "Edit Snack/Supplement",
            }}
            hasEdit={true}
            hasDelete={true}
            deleteProps={{
              entityName: useGetFoodTypeByIdQuery(params?.row?.foodTypeId).data
                ?.name,
              apiPath: "deleteResidentSnack",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={12} md={4} lg={3} xl={3} marginTop={1}>
          <BaseButton
            text={"Add Snack/Supplement"}
            endIcon={"post_add"}
            To={`addResidentSnack?id=${residentId}`}
            marginLeft="0px !important"
            sx={{
              marginY: "0px !important",
            }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={residentSnacks}
            columns={snackColumns}
            error={isError}
            loading={isLoading}
            autoHeight={false}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "55vh",
              lg: "62vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
