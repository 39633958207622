import React from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import BaseContent from "../../../../shared/baseContent/BaseContent";
import { Box } from "@mui/material";

export const HeadingDetails = () => {
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("id");
  const ingredientName = searchParams.get("name") || "";
  const ingredientId = searchParams.get("ingredientId");
  const menuId = searchParams.get("menuId");

  const currentLocation = useLocation();
  const path = "/plateFul/Menus/MissingVendors/AddVendorIngredients";
  return (
    <>
      <BaseContent
        backLink={
          currentLocation.pathname === path
            ? `/plateFul/Menus/MissingVendors/VendorList?id=${ingredientId}&name=${ingredientName}&menuId=${menuId}`
            : `/plateFul/Menus/MissingVendor?menuId=${menuId}`
        }
        headerText={
          currentLocation.pathname === path
            ? editId
              ? `Edit Vendor Ingredient (${ingredientName})`
              : `Add Vendor Ingredient (${ingredientName})`
            : currentLocation.pathname ===
              "/plateFul/Menus/MissingVendors/editIngredient"
            ? "Edit Ingredient"
            : `Vendor List `
        }
        disableHeight={true}>
        <Box height={{ md: "75vh", xl: "80vh" }}>
          <Outlet />
        </Box>
      </BaseContent>
    </>
  );
};
