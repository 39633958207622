import { Box, Grid } from "@mui/material";
import React from "react";
import { useFieldArray } from "react-hook-form";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { DMStyles } from "styles/Styles";
import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";

export default function GenericFieldForm({
  name,
  control,
  errors,
  fieldPropName,
  appendObjectExample,
  nameOfObject,
  isSortOrder = false,
  fieldList,
  ChildComponent,
  childProps,
}) {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: name,
  });

  const moveUp = (index) => {
    if (index > 0) {
      console.log(index, fields.length);
      move(index, index - 1);
      updateSortOrder(index, index - 1);
    }
  };

  const moveDown = (index) => {
    if (index < fields.length - 1) {
      console.log(index, fields.length);
      move(index, index + 1);
      updateSortOrder(index, index + 1);
    }
  };

  const updateSortOrder = (index1, index2) => {
    const temp = fields[index1].sortOrder;
    fields[index1].sortOrder = fields[index2].sortOrder;
    fields[index2].sortOrder = temp;
  };

  return (
    <Box p={2}>
      <Grid
        border={"1px solid black"}
        borderRadius={DMStyles.BorderRadius}
        py={1}
        pr={1}
        my={2}
        container
        spacing={2}>
        {fields.map((item, index) => {
          let inputFields = [];
          inputFields = fieldList?.map((field) => {
            const newClone = {
              ...structuredClone(field),
              props: {
                ...field.props,
                name: `${fieldPropName}[${index}].${field.props.name}`,
              },
            };

            return newClone;
          });
          return (
            <React.Fragment key={index}>
              <GeneratedForm
                list={inputFields}
                errors={errors}
                control={control}
                key={index}
              />
              {ChildComponent ? (
                <Grid item xs={12}>
                  <ChildComponent {...childProps} nestIndex={index} />
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <BaseSubmitButton
                  icon="true"
                  color="error"
                  startIcon={<Delete />}
                  text=""
                  onClick={() => remove(index)}
                />
                {isSortOrder ? (
                  <>
                    <BaseSubmitButton
                      icon="true"
                      startIcon={<ArrowUpward />}
                      onClick={() => moveUp(index)}></BaseSubmitButton>
                    <BaseSubmitButton
                      icon="true"
                      onClick={() => moveDown(index)}
                      startIcon={<ArrowDownward />}></BaseSubmitButton>
                  </>
                ) : null}
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12}>
          <BaseSubmitButton
            text={`Add ${nameOfObject}`}
            onClick={() => append(appendObjectExample)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
