import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetWeightBulkQuery,
  useAddWeightBulkMutation,
} from "../../../store/apis/WeightAlertApis";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function BulkWeight() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const facilityId = useSelector(selectFacilityId);

  const [DataArray, setDataArray] = useState([]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [disable, setDisable] = useState(false);
  const [showDelayedText, setShowDelayedText] = useState(false);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    watch,
    // setValue,
    formState: { errors },
  } = methods;

  const measureDate = watch("measurementDate");
  const {
    data: weightBulks,
    isFetching: loading,
    isError: hasError,
  } = useGetWeightBulkQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const [addBulkWeight, { isSuccess: addWeightSuccess, isLoading }] =
    useAddWeightBulkMutation();

  useEffect(() => {
    const todayDate = dayjs(new Date()).format(SCREEN_CONSTANTS.Date_Format);
    if (dayjs(measureDate).format(SCREEN_CONSTANTS.Date_Format) <= todayDate) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [measureDate]);

  useEffect(() => {
    if (addWeightSuccess) {
      enqueueSnackbar(`Resident Weight Updated Successfully`, {
        variant: "success",
      });
      navigate("/MealCard");
    }
  }, [addWeightSuccess]);

  const onSubmit = () => {
    setTimeout(() => {
      setShowDelayedText(true);
    }, 4000);
  };

  useEffect(() => {
    if (showDelayedText) {
      var updatedDataArray = DataArray.map((row) => {
        if (isNaN(parseInt(row.weightValue))) {
          return { ...row, weightValue: 0 };
        } else {
          return row;
        }
      });

      addBulkWeight({
        measurementDate: new Date(measureDate),
        residentList: updatedDataArray,
      });
    }
  }, [showDelayedText]);

  useEffect(() => {
    setDataArray(weightBulks?.residentList);
    // setValue("measurementDate", new Date(weightBulks?.measurementDate));
  }, [weightBulks?.residentList]);

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const residentsColumns = [
    {
      field: "residentName",
      headerName: "Resident",
      flex: 1,
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 0.8,
    },
    {
      field: "roomNumber",
      headerName: "Room Number",
      flex: 0.8,
    },
    {
      field: "currentWeightValue",
      headerName: "Last Weight",
      flex: 0.8,
    },
    {
      field: "weightValue",
      headerName: "New Weight Value",
      type: "number",
      cellClassName: "bulkWeightInput",
      flex: 1,
      maxLength: 2000,
      editable: true,
    },
    {
      field: "weightNote",
      headerName: "Weight Note",
      cellClassName: "bulkWeightInput",
      flex: 2,
      editable: true,
    },
  ];

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      weightValue: Math.round(Number(newRow.weightValue)),
    };
    setDataArray(
      DataArray.map((row) =>
        row.residentId === newRow.residentId ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const onRowEditStart = (params) => {
    if (params.field === "weightValue" && params.row.weightValue == 0) {
      const updatedRow = { ...params.row, weightValue: "" };
      setDataArray(
        DataArray.map((row) =>
          row.residentId === params.row.residentId ? updatedRow : row
        )
      );
      return updatedRow;
    }
  };

  return (
    <>
      <Typography variant="h6" margin={1} component="h2">
        {`Weight Bulk Entry`}
      </Typography>

      <FlexBox justifyContent={"space-between"} pr={1} my={1}>
        <Box width={"30%"}>
          <BaseDatePicker
            name={"measurementDate"}
            id="measurementDate"
            label="Measurement Date"
            size={"small"}
            control={control}
            errors={errors}
            defaultValue={new Date().toISOString().split("T")[0]}
            disableFuture={true}
            validationProps={{
              max: {
                value: new Date().toISOString().split("T")[0],
                message: "Date Must be in Past",
              },
            }}
          />
        </Box>
        <FlexBox justifyContent={"end"}>
          <BaseButton
            text="Save"
            disabled={disable || DataArray?.length > 0 ? false : true}
            isSubmitting={isLoading}
            onClick={() => {
              onSubmit();
            }}
          />
          <BaseButton
            text="Weight Alerts"
            endIcon={<span className="material-icons">receipt</span>}
            To="/MealCard/settings/weightAlert"
            colors={"warning"}
          />
        </FlexBox>
      </FlexBox>
      <Box py={1} pr={1}>
        <BaseDataGrid
          getRowId={(row) => row.residentId + row.residentName}
          rows={DataArray}
          loading={loading}
          columns={residentsColumns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowEditStart={onRowEditStart}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={processRowUpdate}
          error={hasError || undefined}
          autoHeight={false}
          height={{
            xs: "50vh",
            sm: "65vh",
            md: "56vh",
            lg: "57vh",
            xl: "62vh",
          }}
          sx={{
            marginY: "0px !important",
            paddingY: "0px !important",
          }}
        />
      </Box>
    </>
  );
}
