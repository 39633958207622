import React, { useEffect, useState } from "react";
import HeaderBox from "../../../../shared/HeaderBox";
import { useSearchParams } from "react-router-dom";
import { SCREEN_CONSTANT } from "../../constants";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { BaseButton } from "../../../../../shared/BaseButton";
import { VisitDetailsForm } from "../../shared/visitdetailsForm";
import { ConsultantFieldsDefaults } from "../../consultantDetails/consultantDetails/formConstants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useCreateEditVisitPlanMutation } from "../../../../../../store/apis/ConsultantApis";
import { SendReportDetails } from "./sendReportDetails/SendReportDetails";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import { isEqual } from "lodash";
import { theme } from "styles/theme";
import { DMStyles } from "styles/Styles";

export const SendReport = () => {
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [completed] = useState({});
  const currentPlaneId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const steps = ["Enter Time Out", "Send Report"];
  const IsEdit_Query = "isEdit";
  const isEdit = searchParams.get(IsEdit_Query);

  // useForm for consultant Field Only
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: ConsultantFieldsDefaults,
  });
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const isFutureNA = watch("nextConsultNA");

  const dietitianId = useSelector(
    (state) => state.dietitionId.dietition
  );

  // visitFacilityId from local state
  const visitFacilityId = useSelector(
    (state) => state.facilityId.visitFacilityId
  );

  // api to update VisitPlane data
  const [createVisitPlane, { isSuccess, isLoading: isCreateVisitLoading }] =
    useCreateEditVisitPlanMutation();
  // function which will be called if some click on next button only if active step is 0
  const onSave = (data) => {
    const newData = {
      dateOfVisit: data.dateOfVisit,
      nextConsult: isFutureNA ? null : data.nextConsult,
      isFinal: data?.isFinal,
      facilityId: visitFacilityId?.facilityId,
      substituteRD: data?.substituteRD,
      nextVisitNotes: isFutureNA ? data.nextVisitNotes : "",
      nextConsultNA: isFutureNA,
      visitPlanId: currentPlaneId,
      dietitianId: dietitianId,
      scheduled: data.scheduled,
      community: `${data.facilityId}|0`,
    };
    createVisitPlane(newData);
  };

  const deleteVisitPlan = (event) => {
    event.preventDefault();
    setOpenDeleteModal(true);
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const handleNext = (event) => {
    event.preventDefault();
    isEqual(activeStep, 0) ? handleSubmit(onSave)() : null;
  };

  const handleBack = (event) => {
    event.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function which will assign custom icons to steppes
  const CustomStepIcon = (props) => {
    const { active, completed } = props;
    const stepIcons = {
      1: <span className="material-icons">query_builder</span>,
      2: <span className="material-icons">plagiarism</span>,
    };
    return (
      <div
        className={
          active
            ? "icon-tab-style-active"
            : completed
            ? "icon-tab-style-active"
            : "icon-tab-style"
        }>
        {stepIcons[String(props.icon)]}
      </div>
    );
  };

  // UseEffect Section
  useEffect(() => {
    if (isSuccess && !isCreateVisitLoading) {
      const newActiveStep =
        activeStep === steps.length - 1 && !(completedSteps() === steps.length)
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  }, [isSuccess, isCreateVisitLoading]);

  //function which will provide different component on the basis of active step which we wanna to show
  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <VisitDetailsForm
            control={control}
            watch={watch}
            setValue={setValue}
            reset={reset}
            errors={errors}
            isEdit={isEdit}
            isFutureNA={isFutureNA}
          />
        );
      case 1:
        return <SendReportDetails />;
    }
  };
  const ScreenNames = [
    {
      id: 1,
      screenName: false,
      bgColor: theme.palette.primary.main,
      fontWeight: "600",
      color: DMStyles.White,
      marginBottom: "5px",
    },
    {
      id: 2,
      screenName: "Send Report To Community",
      bgColor: theme.palette.gray.darkGray,
      fontWeight: "500",
      color: DMStyles.White,
      marginBottom: "0px",
    },
  ];
  return (
    <>
      <Box margin={"10px"}>
        <HeaderBox ScreensArray={ScreenNames} visitPlanId={currentPlaneId} />
        <Box marginTop={"10px"} display={"flex"} justifyContent={"right"}>
          <Box height={"10px"}>
            <BaseButton
              text={"Delete"}
              colors={"error"}
              endIcon={"delete"}
              onClick={deleteVisitPlan}
            />
          </Box>
        </Box>
        <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
          <Stepper
            activeStep={activeStep}
            className="icons-Tabs"
            style={{ width: "50%" }}>
            {steps.map((label, index) => (
              <Step key={label + index}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box>
          {
            <>
              {getStepContent()}

              <Box
                marginTop={"10px"}
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}>
                <BaseButton
                  text={"Back"}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  startIcon={"keyboard_arrow_left"}
                />
                {activeStep !== 1 ? (
                  <BaseButton
                    text={"Next"}
                    disabled={activeStep === 1}
                    onClick={handleNext}
                    endIcon={"keyboard_arrow_right"}
                    isSubmitting={isCreateVisitLoading}
                  />
                ) : null}
              </Box>
            </>
          }
        </Box>
        {openDeleteModal ? (
          <DeleteModal
            To={"/Consultant/MyRosters"}
            id={currentPlaneId}
            apiPath={"deleteVisitPlane"}
            entityName={"This Visit"}
            open={openDeleteModal}
            close={() => {
              setOpenDeleteModal(false);
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
