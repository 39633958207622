import React from "react";
import { Box, Divider } from "@mui/material";
import { useGetRegionListQuery } from "../../../../../store/apis/RegionsApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { Terrain } from "@mui/icons-material";

export default function Region() {
  const {
    data: regions,
    isLoading: isLoading,
    isError: isError,
  } = useGetRegionListQuery();

  const RegionColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "managerName",
      headerName: "Manager",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "states",
      headerName: "States",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={regions}
            hasEdit={true}
            editProps={{
              To: `addRegion?id=${params.row.id}`,
              title: "Region",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteRegion",
              title: "Region",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box marginY={"8px"}>
        <BaseButton
          text={"Create New Region"}
          marginLeft={"0px !important"}
          to="addRegion"
          endIcon={<Terrain />}
        />
      </Box>

      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <BaseDataGrid
        rows={regions}
        columns={RegionColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "54dvh", xl: "55dvh" }}
      />
    </>
  );
}
