import { Card, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { useGetCorporateBeverageSelectBoxQuery } from "../../../../../store/apis/BeverageApis";
import { useGetUnitsQuery } from "../../../../../store/apis/UnitApis";
import { useGetMealSelectQuery } from "../../../../../store/apis/PrintReportApis";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentBeverageMutation,
  useResidentBeveragesByIdQuery,
  useUpdateResidentBeverageMutation,
} from "../../../../../store/apis/ResidentApis";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { BaseMultiSelect } from "../../../../shared/baseForm/BaseMultiSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";

export default function AddResidentBeverage() {
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("beverageId");
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const {
    data: currentBeverage,
    isFetching: loadingCurrent,
    isSuccess: successCurrent,
  } = useResidentBeveragesByIdQuery(id, { skip: !id });

  const { data: beverages, isLoading: beveragesLoading } =
  useGetCorporateBeverageSelectBoxQuery(facilityId);

  const isEditLoading = loadingCurrent && isEdit;
  const { data: units, isLoading: unitsLoading } = useGetUnitsQuery("");
  const { data: meals, isLoading: mealsLoading } = useGetMealSelectQuery({
    includeAll: true,
  });

  const [
    addBeverage,
    { isLoading: addLoading, isSuccess: addSuccess, error: addError },
  ] = useAddResidentBeverageMutation();
  const [
    updateBeverage,
    { isLoading: updateLoading, isSuccess: updateSuccess, error: updateError },
  ] = useUpdateResidentBeverageMutation();

  const isSubmitting = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const errorData = addError || updateError;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (successCurrent && !loadingCurrent) {
      reset(currentBeverage);
    }
  }, [successCurrent, loadingCurrent]);

  function onSubmit(data) {
    data["residentId"] = residentId;

    if (data["beverageId"] !== -1) {
      data["description"] = "";
    }

    if (isEdit) {
      data["id"] = id;
      updateBeverage(data);
      return;
    }
    addBeverage(data);
  }

  if (!isSubmitting && isSuccess) {
    navigate(-1);
  }

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Card>
          <Grid container spacing={2} margin={"12px"}>
            <Grid item md={6}>
              <BaseSelect
                name="beverageId"
                id="beverageId"
                label="Beverage"
                control={control}
                errors={errors}
                options={beverages || []}
                validationProps={{
                  required: REQUIRED_ERROR("Beverage"),
                }}
                loading={beveragesLoading}
              />
            </Grid>
            <Grid item md={6} />
            {watch("beverageId") === -1 && (
              <>
                <Grid item md={6}>
                  <BaseInput
                    name="description"
                    id="description"
                    label="Description"
                    control={control}
                    validationProps={{
                      required:
                        "Description field is required when selecting Other",
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item md={6} />
              </>
            )}
            <Grid item md={6}>
              <BaseInput
                name="quantity"
                id="quantity"
                label="Quantity"
                control={control}
                validationProps={{ required: REQUIRED_ERROR("Quantity") }}
                errors={errors}
                type="number"
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={6}>
              <BaseSelect
                name="unitId"
                id="unitId"
                label="Unit"
                control={control}
                errors={errors}
                options={units || []}
                validationProps={{ required: REQUIRED_ERROR("Unit") }}
                loading={unitsLoading}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={6}>
              {isEdit ? (
                <BaseSelect
                  name="mealId"
                  id="mealId"
                  label="Meal"
                  control={control}
                  errors={errors}
                  options={meals || []}
                  validationProps={{ required: REQUIRED_ERROR("Meal") }}
                  loading={mealsLoading}
                />
              ) : (
                <BaseMultiSelect
                  name="meals"
                  id="meals"
                  label="Meals"
                  control={control}
                  setValue={setValue}
                  options={meals || []}
                  loading={mealsLoading}
                  validationProps={{ required: REQUIRED_ERROR("Meals") }}
                  errors={errors}
                />
              )}

              <Grid item md={6} />
              <Grid item md={6}>
                <BaseSubmitButton
                  sx={{ marginTop: "20px" }}
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  validationSummary={errorData}
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
}
