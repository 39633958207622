import React from "react";
import { Typography, MenuItem, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import { Link } from "react-router-dom";

import { useGetDiningAreaListQuery } from "../../../store/apis/ResidentApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseMenu from "components/shared/BaseMenu";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";

export default function DiningArea() {
  const facilityId = useSelector(selectFacilityId);

  const {
    data: diningAreaList,
    isFetching: loading,
    isError: hasError,
  } = useGetDiningAreaListQuery(
    {
      facilityId: facilityId,
    },
    { refetchOnMountOrArgChange: true, skip: !facilityId }
  );

  const diningAreasColumns = [
    {
      field: "name",
      headerName: "Dining Area",
      flex: 1,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      flex: 1,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diningAreaList}
            hasEdit={true}
            editProps={{
              To: `AddDiningArea?id=${params.row.id}&facilityId=${facilityId}`,
              title: "Dining Area",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.name || "",
              apiPath: "deleteDiningArea",
              additionalText: `This dining area will be removed from ${params.row.residentsCount} residents and ${params.row.ordersCount} orders.`,
            }}
            childrenLocation={"end"}>
            {" "}
            <BaseMenu>
              <MenuItem
                component={Link}
                to={`/MealCard/settings/DiningAreaTables?diningAreaId=${params.row.id}`}
                disableRipple>
                Tables
              </MenuItem>
            </BaseMenu>
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={1} paddingRight={1} marginTop={1}>
        <Grid item sm={10} md={10} lg={10} xl={10}>
          <Typography variant="h6" component="h2">
            Dining Areas
          </Typography>
        </Grid>
        <Grid item sm={2} md={2} lg={2} xl={2}>
          <BaseButton
            text="Add Dining Area"
            To={`AddDiningArea?facilityId=${facilityId}`}
            endIcon={<PostAdd />}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <BaseDataGrid
            rows={diningAreaList}
            columns={diningAreasColumns}
            loading={loading}
            autoHeight={false}
            error={hasError}
            height={{
              xs: "50vh",
              sm: "65vh",
              md: "58vh",
              lg: "62.5vh",
              xl: "67vh",
            }}
            sx={{
              marginY: "0px !important",
              paddingY: "0px !important",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
