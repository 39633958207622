import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import BaseInput from "../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentWeightMutation,
  useUpdateResidentWeightMutation,
} from "../../../../../store/apis/ResidentApis";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { positiveNumber } from "utils/ValidationRegex";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import { BackButton } from "components/shared/BackButton";

export default function AddResidentWeight() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDirty, setIsDirty] = useState(false);
  const currentWeight = location?.state?.data;

  const id = location?.state?.data?.id;
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const [
    addResidentWeight,
    { isLoading: addResidentWeightSubmitting, isSuccess: addSuccess },
  ] = useAddResidentWeightMutation();

  const [
    updateResidentWeight,
    { isLoading: updateResidentWeightSubmitting, isSuccess: updateSuccess },
  ] = useUpdateResidentWeightMutation();

  const isSubmitting =
    addResidentWeightSubmitting || updateResidentWeightSubmitting;

  const isSuccess = addSuccess || updateSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty: valuesChange },
  } = methods;

  useEffect(() => {
    isEdit ? reset(currentWeight) : null;
  }, [isEdit]);

  function onSubmit(data) {
    setIsDirty(false);
    data.weightValue = Math.round(parseFloat(data.weightValue));
    if (isEdit) {
      updateResidentWeight({
        ...data,
        id: id,
        residentId: residentId,
      });
    } else {
      addResidentWeight({ ...data, residentId: residentId });
    }
  }
  isSuccess && !isSubmitting ? navigate(-1) : null;
  useEffect(() => {
    setIsDirty(valuesChange);
  }, [valuesChange]);
  return (
    <>
      <BaseBlockerModal isDirty={isDirty} />
      <Box sx={{ padding: "5px", marginTop: "10px" }}>
        <BackButton To={-1} />
        <Typography variant="p" component={"p"} marginY={2} fontWeight={600}>
          {"Resident Weight"}
        </Typography>

        <Grid marginY={"8px"} container spacing={2}>
          <Grid item md={4}>
            <BaseDatePicker
              name="weightDate"
              id="weightDate"
              label="Weight Date"
              control={control}
              errors={errors}
              validationProps={{
                required: "Weight date is required",
              }}
            />
          </Grid>
          <Grid item md={8} />
          <Grid item md={4}>
            <BaseInput
              name="weightValue"
              id="weightValue"
              label="weight"
              type={"number"}
              control={control}
              errors={errors}
              min={0}
              validationProps={{
                required: "Weight is required",
                pattern: {
                  value: positiveNumber,
                  message: "Please enter a positive whole number",
                },
              }}
            />
          </Grid>
          <Grid item md={8} />
          <Grid item md={4}>
            <BaseInput
              name="note"
              id="note"
              label="Note"
              multiline
              rows={4}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={8} />

          <Grid item md={4}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isSubmitting}
              text={isEdit ? "Submit" : "Create"}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
