import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import {
  useFacilityEHRLiquidMappingByIdQuery,
  useAddfacilityEHRLiquidMappingMutation,
  useUpdatefacilityEHRLiquidMappingMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { useGetThickenedLiquidSelectBoxQuery } from "../../../../../../../store/apis/LiquidConsistencyApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import {
  INVALID_EHR_LIQUID_NAME_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";

export default function AddCommunityEHRLiquidMapping() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("id");
  const id = searchParams.get("mappingId");

  const isEdit = !!id;

  const { data: liquids, isLoading: liquidsLoading } =
    useGetThickenedLiquidSelectBoxQuery(" ", {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: facilityEHRMapping,
    isFetching: isFetchingEHRMapping,
    isSuccess: isSuccessEHRMapping,
  } = useFacilityEHRLiquidMappingByIdQuery(id, {
    skip: !isEdit,
  });

  const [
    addFacilityEHRMapping,
    {
      isSuccess: isAddSuccess,
      isSubmitting: isAddSubmitting,
      isError: isAddError,
      error: addError,
    },
  ] = useAddfacilityEHRLiquidMappingMutation();
  const [
    updateFacilityEHRMapping,
    {
      isSuccess: isUpdateSuccess,
      isSubmitting: isUpdateSubmitting,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdatefacilityEHRLiquidMappingMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (facilityEHRMapping && !isFetchingEHRMapping && isSuccessEHRMapping) {
      reset(facilityEHRMapping);
    }
  }, [facilityEHRMapping, isFetchingEHRMapping, isSuccessEHRMapping]);

  const onSubmit = (data) => {
    data.facilityId = facilityId;

    if (isEdit) {
      data.id = id;
      updateFacilityEHRMapping(data);
      return;
    }
    addFacilityEHRMapping(data);
  };

  const isEditLoading = isEdit && isFetchingEHRMapping;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box>
          <BaseButton
            text="Back"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
          <Grid container spacing={2} padding="10px">
            <Grid item xs={12} sm={6}>
              <BaseInput
                name="ehrthickenedLiquid"
                control={control}
                label="EHR Liquid Consistency Name"
                id="ehrthickenedLiquid"
                validationProps={{
                  required: REQUIRED_ERROR("EHR Liquid Consistency Name"),
                  pattern:
                    INVALID_EHR_LIQUID_NAME_VALIDATION("EHR liquid name"),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item sm={6} />
            <Grid item xs={6}>
              <BaseSelect
                label="Liquid Consistency"
                name="thickenedLiquidId"
                id="thickenedLiquidId"
                control={control}
                options={liquids || []}
                errors={errors}
                loading={liquidsLoading}
              />
            </Grid>
            <Grid item xs={6}></Grid>

            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Grid>

            <Grid item xs={12}>
              {isError ? (
                <Typography color={"error"}>{error?.data?.messages}</Typography>
              ) : undefined}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
