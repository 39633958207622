import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import GeneratedForm from "../../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseContent from "../../../../shared/baseContent/BaseContent";
import { useInsertNonPlannedVisitMutation } from "../../../../../store/apis/ConsultantApis";
import { nonPlannedFields } from "./form.constant";
import dayjs from "dayjs";

const NonPlannedVisit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dietitianId = searchParams.get("dietitianId");

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const [AddNonPlannedVisit, { isSuccess, isError }] =
    useInsertNonPlannedVisitMutation();

  useEffect(() => {
    setValue("date", dayjs(new Date()).format("YYYY-MM-DD"));
  }, []);
  useEffect(() => {
    if (isSuccess) {
      reset();
      navigate(-1);
    }
  }, [isSuccess, navigate, isError]);

  const onSubmit = (data) => {
    AddNonPlannedVisit({
      dietitianId: dietitianId,
      facilityId: data.community,
      planDate: dayjs(data.date).isValid() ? dayjs(data.date).format("YYYY-MM-DD") : null,
    });
  };

  return (
    <BaseContent headerText="Non Planned Visit">
      <Box width={{ md: "25vw", xl: "20vw" }} margin={"30px"}>
        <Grid
          container
          spacing={1}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}>
          <GeneratedForm
            list={nonPlannedFields}
            control={control}
            setValue={setValue}
            errors={errors}
          />
          <Grid item md={12}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              sx={{ width: "100%", margin: "10px 0" }}
              text={"Create"}
            />
          </Grid>
        </Grid>
      </Box>
    </BaseContent>
  );
};

export default NonPlannedVisit;
