import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import {
  useFacilityEHRDietMappingByIdQuery,
  useAddfacilityEHRDietMappingMutation,
  useUpdatefacilityEHRDietMappingMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { useGetFacilityDietSelectBoxQuery } from "../../../../../../../store/apis/DietsApi";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { WarningText } from "../../../../../../shared/WarningText";
import { INVALID_EHR_LIQUID_NAME_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";

export default function AddCommunityEHRMapping() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("id");
  const id = searchParams.get("mappingId");

  const [dietList, setDietList] = useState([]);
  const [dietTextureList, setDietTextureList] = useState([]);

  const isEdit = !!id;

  const {
    data: diets,
    isLoading: dietsLoading,
    isSuccess: dietsSuccess,
  } = useGetFacilityDietSelectBoxQuery(
    facilityId,
    {
      refetchOnMountOrArgChange: true,
    },
    {
      skip: !facilityId,
    }
  );

  const {
    data: facilityEHRMapping,
    isFetching: isFetchingEHRMapping,
    isSuccess: isSuccessEHRMapping,
  } = useFacilityEHRDietMappingByIdQuery(id, {
    skip: !isEdit,
  });

  const [
    addFacilityEHRMapping,
    {
      isSuccess: isAddSuccess,
      isSubmitting: isAddSubmitting,
      isError: isAddError,
      error: addError,
    },
  ] = useAddfacilityEHRDietMappingMutation();
  const [
    updateFacilityEHRMapping,
    {
      isSuccess: isUpdateSuccess,
      isSubmitting: isUpdateSubmitting,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdatefacilityEHRDietMappingMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (diets && !dietsLoading && dietsSuccess) {
      setDietList(diets.filter((x) => x.isTexture === false));
      setDietTextureList(diets.filter((x) => x.isTexture === true));
    }
  }, [diets, dietsLoading, dietsSuccess]);

  useEffect(() => {
    if (facilityEHRMapping && !isFetchingEHRMapping && isSuccessEHRMapping) {
      reset(facilityEHRMapping);
    }
  }, [facilityEHRMapping, isFetchingEHRMapping, isSuccessEHRMapping]);

  const onSubmit = (data) => {
    data.facilityId = facilityId;

    if (isEdit) {
      data.id = id;
      updateFacilityEHRMapping(data);
      return;
    }
    addFacilityEHRMapping(data);
  };

  const isEditLoading = isEdit && isFetchingEHRMapping;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box>
          <BaseButton
            text="Back"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
          <Grid container spacing={2} padding="10px">
            <Grid item xs={12}>
              <WarningText
                sx={{ marginLeft: "0px !important" }}
                text={`NOTE: Select either a diet or a diet texture. If both are selected, only the diet will be used. 
                    If NPO is selected, the diet and diet texture will be ignored.`}
                width="90%"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BaseInput
                name="ehrDietName"
                control={control}
                label="EHR Diet Name"
                id="ehrDietName"
                validationProps={{
                  required: REQUIRED_ERROR("EHR Diet Name"),
                  pattern: INVALID_EHR_LIQUID_NAME_VALIDATION("EHR diet name"),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item sm={6} />
            <Grid item xs={6}>
              <BaseSelect
                label="Diet"
                name="dietId"
                id="dietId"
                control={control}
                options={dietList || []}
                validationProps={{
                  validate: (value, formValues) =>
                    value && formValues.dietTextureId
                      ? "Please select either diet or texture"
                      : null,
                }}
                errors={errors}
                loading={dietsLoading}
              />
            </Grid>
            <Grid item sm={6} />

            <Grid item xs={6}>
              <BaseSelect
                label="Diet Texture"
                name="dietTextureId"
                id="dietTextureId"
                control={control}
                options={dietTextureList || []}
                validationProps={{
                  validate: (value, formValues) =>
                    value && formValues.dietId
                      ? "Please select either diet or texture"
                      : null,
                }}
                errors={errors}
                loading={dietsLoading}
              />
            </Grid>
            <Grid item sm={6} />

            <Grid item xs={6}>
              <BaseInput
                name="dietOther"
                control={control}
                label="Diet Other"
                id="dietOther"
                validationProps={{
                  pattern: INVALID_EHR_LIQUID_NAME_VALIDATION("EHR diet name"),
                }}
                errors={errors}
                helperText="Select either a diet or a diet texture to be used for the diet other field."
              />
            </Grid>
            <Grid item xs={8}>
              <BaseCheckBox
                control={control}
                label={"Is NPO"}
                name={"isNPO"}
                errors={errors}
              />
            </Grid>
            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              {isError ? (
                <Typography color={"error"}>{error?.data?.messages}</Typography>
              ) : undefined}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
