import React from "react";
import { useAddCorporateGroupExtendMutation } from "../../../../../../store/apis/CorporateGroupApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function AddAllergy() {
  const [addExpiration, { isError: addError, isLoading: addLoading }] =
    useAddCorporateGroupExtendMutation();

  const isError = addError;
  const isSubmitting = addLoading;
  const [searchParams] = useSearchParams();
  const corporateId = searchParams.get("id");

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    addExpiration({ ...data, corporateGroupId: corporateId });

    if (!isError) {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="leftActions">
        <Button
          component={Link}
          color={"primary"}
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={<KeyboardArrowLeft />}>
          {"Back to  Corporate list"}
        </Button>
      </div>
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <FormProvider>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              {/* <Typography variant="p">Menu Expiration</Typography> */}
              <BaseDatePicker
                style={{ marginTop: 10 }}
                name={"menuExpiration"}
                id={"menuExpiration"}
                label="Menu Expiration"
                control={control}
                errors={errors}
                validationProps={{
                  required: REQUIRED_ERROR("Menu Expiration"),
                }}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              {/* <Typography variant="p">MealCard Expiration</Typography> */}
              <BaseDatePicker
                style={{ marginTop: 10 }}
                name={"mealCardExpiration"}
                label="MealCard Expiration"
                control={control}
                errors={errors}
                validationProps={{
                  required: REQUIRED_ERROR("Mealcard Expiration"),
                }}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              {/* <Typography variant="p">TableSide Expiration</Typography> */}
              <BaseDatePicker
                style={{ marginTop: 10 }}
                name={"tableSideExpiration"}
                label="TableSide Expiration"
                control={control}
                errors={errors}
                validationProps={{
                  required: REQUIRED_ERROR("TableSide Expiration"),
                }}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
              </Box>
              {isError ? (
                <Typography color={"error"}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
