import React, { useState, useEffect } from "react";
import "./ReserveList.css";
import {
  FormControlLabel,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  Modal,
  Fade,
  Box,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import {
  useGetReservedListQuery,
  useDeleteReservedMutation,
  useMoveResidentToActiveMutation,
  useGetResidentProfileReportQuery,
} from "../../../store/apis/ResidentApis";

import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../shared/baseSearch/BaseSearch";
import { DownloadLinkHelper } from "../../shared/utils/helpers";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";
import BaseMenu from "components/shared/BaseMenu";
import BaseActionBox from "components/shared/BaseActionBox";
import { Delete, Report } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function ReservedList() {
  const facilityId = useSelector(selectFacilityId);
  const [DataArray, setDataArray] = useState([]);
  const [DataSelectArray, setDataSelectArray] = useState([]);
  const [ischeckBox, setCheckox] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [residentId, setResidentId] = useState(0);
  const [fetchReport, setFetchReport] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const SEARCH_PARAM = "search";

  const {
    data: reservedList,
    isFetching: loading,
    isError: hasError,
  } = useGetReservedListQuery(
    {
      facilityId: facilityId,
      searchText: debouncedSearchValue,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [deleteResident, { isSuccess: deleteSuccess }] =
    useDeleteReservedMutation();

  const [moveToActive, { isLoading: moveLoading }] =
    useMoveResidentToActiveMutation();

  const { data: profileReport, isSuccess: reportSuccess } =
    useGetResidentProfileReportQuery(residentId, { skip: !fetchReport });

  const success = deleteSuccess;

  const onSubmit = (Ids) => {
    deleteResident(Ids);
    setOpenDeleteModal(false);
    if (success) {
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    const temp = [];
    reservedList?.length > 0 &&
      reservedList?.map((item, index) => {
        temp?.push({
          id: item.id,
          lastName: item.lastName,
          firstName: item.firstName,
          dateOfBirth: item.dateOfBirth,
          archivedDate: item.archivedDate,
          selected: false,
          index: index,
        });
      });
    setDataArray(temp);
  }, [reservedList]);

  const updateftn = (data) => {
    const newArr = [...DataArray];
    newArr[data.index].selected = !data.selected;
    setDataArray(newArr);
    if (data.selected) {
      setDataSelectArray([...DataSelectArray, newArr[data.index].id]);
    } else {
      let deleteSelect = DataSelectArray.filter(function (el) {
        return newArr[data.index].id != el;
      });
      setDataSelectArray(deleteSelect);
    }
  };

  useEffect(() => {
    if (reportSuccess && fetchReport) {
      DownloadLinkHelper(profileReport);
      setFetchReport(false);
    }
  }, [profileReport]);

  const moveSelectedToActive = () => {
    if (DataSelectArray.length > 0) {
      moveToActive(DataSelectArray);
    }
  };

  const CheckBoxUI = ({ params }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={params.row?.selected}
            onClick={() => {
              updateftn(params.row);
            }}
          />
        }
        label={params.row.lastName}
      />
    );
  };

  const residentsColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <CheckBoxUI params={params} />
          </div>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).toDate() : "",
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "archivedDate",
      headerName: "Archived Date",
      flex: 1,
      valueGetter: (params) =>
        params.value ? dayjs(params.value).toDate() : "",
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={DataArray || []}
            childrenLocation={"end"}>
            <DeleteButton index={params.row.index} data={params.row} />
            <OptionsButton index={params.row.id} name={params.row.firstName} />
          </BaseActionBox>
        );
      },
    },
  ];

  function DeleteButton({ data }) {
    return (
      <Button
        title="Remove User"
        icon="true"
        aria-label="Remove User"
        color="error"
        variant="contained"
        onClick={() => {
          setOpenDeleteModal(true);
          setCheckox(false);
          setDeleteData(data);
        }}>
        <Delete />
      </Button>
    );
  }

  function DeleteSelectedButton({ open, close }) {
    return (
      <Modal open={open} onClose={close}>
        <Fade in={open}>
          <Box className={"deleteModal"}>
            <FlexBox
              alignItems={"center"}
              gap={0.5}
              paddingX={3}
              justifyContent={"space-between"}>
              <Report color="error" sx={{ fontSize: "90px" }} />
              <Box flexGrow={1}>
                <Typography
                  id={`delete-modal-title`}
                  variant="h6"
                  component="h2">
                  {`Are you sure you want to delete the ${
                    ischeckBox == true
                      ? "selected"
                      : deleteData.firstName + " " + deleteData.lastName
                  }?`}
                </Typography>
                <Typography id={`delete-modal-description`} sx={{ mt: 1 }}>
                  {`This item will be deleted immediately. You can't undo this action.`}
                </Typography>
              </Box>
            </FlexBox>
            <FlexBox justifyContent={"flex-end"} paddingRight={3} paddingY={1}>
              <Button variant="contained" onClick={close} color={"warning"}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (ischeckBox == true) onSubmit(DataSelectArray);
                  else onSubmit([deleteData?.id]);
                }}
                color={"error"}>
                Delete
              </Button>
            </FlexBox>
          </Box>
        </Fade>
      </Modal>
    );
  }

  function OptionsButton({ index }) {
    const handleMoveToActive = () => {
      var data = [];
      data.push(index);

      moveToActive(data);
    };

    const handleProfileReport = () => {
      // console.log("handleProfileReport", index);
      setResidentId(index);
      setFetchReport(true);
    };

    return (
      <BaseMenu index={index}>
        <MenuItem onClick={handleMoveToActive} disableRipple>
          Move to Active
        </MenuItem>
        <MenuItem onClick={handleProfileReport} disableRipple>
          Profile Report
        </MenuItem>
      </BaseMenu>
    );
  }

  return (
    <FlexBox justifyContent={"start"} flexDirection={"column"} height={"100%"}>
      <FlexBox pr={1} my={1} justifyContent={"space-between"}>
        <Typography minWidth={"150px"} variant="h6" component="h2">
          Reserved List
        </Typography>
        <FlexBox>
          <BaseButton
            text="Delete Selected"
            colors="error"
            disabled={DataSelectArray?.length === 0}
            onClick={() => {
              if (DataSelectArray?.length > 0) {
                setOpenDeleteModal(true);
                setCheckox(true);
              }
            }}
          />
          <BaseButton
            colors="warning"
            text="Move Selected to Active"
            isSubmitting={moveLoading}
            onClick={() => moveSelectedToActive()}
          />
        </FlexBox>
        <Box m={0}>
          <BaseSearch
            sx={{ marginRight: "0px" }}
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedSearchValue}
            setSearchParams={setSearchParams}
          />
        </Box>
      </FlexBox>
      <FlexBox minHeight={"90%"} maxHeight={"90%"} mr={1} pb={1}>
        <BaseDataGrid
          rows={DataArray.length > 0 ? DataArray : []}
          columns={residentsColumns}
          loading={loading}
          error={hasError}
          // autoHeight={true}
          useContainer={false}
          height={"100%"}
          sx={{
            marginY: "0px !important",
            paddingY: "0px !important",
          }}
        />
      </FlexBox>
      {openDeleteModal && (
        <DeleteSelectedButton
          open={openDeleteModal}
          close={() => {
            setOpenDeleteModal(false);
          }}
        />
      )}
    </FlexBox>
  );
}
