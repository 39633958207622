import React, { useEffect, useState } from "react";
import { Box, Card, Tab, Tabs } from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/OptionsDetails.md)
 */
export const OptionsDetails = ({ data }) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [value, setValue] = useState(data?.length > 1 ? 1 : 0);
  const [searchParams] = useSearchParams();
  const visitId = searchParams.get("visitPlanId");
  const facilityId = searchParams.get("facilityId");
  const facilityName = searchParams.get("facilityName");
  const corporateId = searchParams.get("corporateId");
  const facilityCorporateGroup = searchParams.get("facilityCorporateGroup");

  const [menuItems, setMenuItems] = useState(data);
  const query = visitId
    ? "?visitPlanId="
    : facilityId
    ? "?facilityId="
    : corporateId
    ? "?corporateId="
    : "";

  const id = visitId || facilityId || corporateId;
  const queryName = facilityName
    ? "facilityName="
    : facilityCorporateGroup
    ? "facilityCorporateGroup="
    : "";
  const name = facilityName || facilityCorporateGroup;

  useEffect(() => {
    let currentLink;
    if (currentLocation) {
      currentLink = menuItems.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      data.indexOf(
        data.find(
          (res) =>
            currentLocation.pathname.includes(res.Link) &&
            res.name !== "My Roster" &&
            res.optionName !== "Back"
        )
      ) || 0;
    setValue(currentIndex !== -1 ? currentIndex : 1);
    setMenuItems(
      data.map((res) => {
        if (currentLocation.pathname.includes(res.Link)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [currentLocation]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          paddingLeft: "0px",
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Box>
          <Tabs
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
              marginX: "5px",
            }}
            value={value}
            aria-label="recipe nav tabs ">
            {menuItems.map((data, index) => {
              return (
                <LinkTab
                  sx={{
                    paddingY: "10px",
                  }}
                  optionName={data?.name}
                  name={name}
                  queryName={queryName}
                  query={query}
                  key={index}
                  value={index}
                  id={id}
                  label={`${data.optionName}`}
                  href={`${data.Link}?visitId=${visitId}`}
                  link={data.Link}
                  navigate={navigate}
                  disabled={currentLocation.pathname.includes("Print")}
                />
              );
            })}
          </Tabs>
        </Box>
        {/* {button ? (
          <Box marginTop="25px">
            <Button
              style={{
                height: "30px",
              }}
              sx={{
                textTransform: "none !important",
                fontWeight: "bold",
                borderBottomLeftRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
              component={Link}
              to={"/Consultant/MyRosters"}
              color={"primary"}
              // className="baseButton"
              variant="contained">
              {"My Rosters"}
            </Button>
          </Box>
        ) : null} */}
      </Box>
      <Card
        sx={{
          paddingLeft: "8px",
          borderTopLeftRadius: "0px !important",
          borderTopRightRadius: "0px !important",
        }}>
        <Box height={{ md: "82.4dvh", xl: "84.7dvh" }} overflow={"auto"}>
          <Outlet />
        </Box>
      </Card>
    </>
  );
};

function LinkTab({
  id,
  label,
  query,
  optionName,
  link,
  navigate,
  name,
  queryName,
  ...rest
}) {
  return (
    <Tab
      disabled={rest.disabled}
      component="a"
      sx={{ marginLeft: "4px" }}
      className={
        label === "Back" || optionName === "My Roster"
          ? "backTab"
          : "consultantTabs"
      }
      onClick={(event) => {
        event.preventDefault();
        {
          label === "Resources" || label === "My Rosters"
            ? navigate({
                pathname: link,
              })
            : navigate({
                pathname: link,
                search:
                  ((query || id) && `${query}${id}`) +
                  ((name || queryName) && `&${queryName}${name}`),
              });
        }
      }}
      label={label === "Back" ? "< Back" : label}
      {...rest}
    />
  );
}
