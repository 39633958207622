import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import Fade from "@mui/material/Fade";

const ErrorContainer = styled(Box)(() => ({
  marginTop: "75px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
  padding: "20px",
}));

const ErrorTitle = styled(Typography)({
  fontWeight: "bold",
  marginTop: "20px",
});

const ErrorMessage = styled(Typography)({
  marginTop: "10px",
});

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/NotFound.md)
 */
export default function NotFound() {
  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <ErrorContainer className="errorContainer">
          <Typography color="primary" textAlign="center" variant="h1">
            404 | Not Found!
          </Typography>
          <ErrorTitle>
            The resource you were looking for was not found!
          </ErrorTitle>
          <ErrorMessage>
            Please check the URL or contact your administrator for more details.
          </ErrorMessage>
        </ErrorContainer>
      </Grid>
    </Fade>
  );
}
