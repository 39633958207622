import { Typography, Grid, FormControl, Input, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useSearchParams, Link, useLocation } from "react-router-dom";
import { useGetMenuRecipeReprintListQuery } from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import styles from "./RecipeReprint.module.css";
import BaseContent from "../../../shared/baseContent/BaseContent";

export default function RecipeReprint() {
  const SEARCH_PARAM = "search";

  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const id = searchParams.get("menuId");
  const location = useLocation();
  const title = location?.state?.title;

  const {
    data: ItemsData,
    isError: hasError,
    isLoading,
  } = useGetMenuRecipeReprintListQuery({
    menuId: id,
  });

  const [debouncedValue, setDebouncedValue] = useState([]);
  useEffect(() => {
    setDebouncedValue(ItemsData);
  }, [ItemsData]);

  const SearchFilter = (text) => {
    const newData = ItemsData.filter((item) => {
      const itemData = `${item.recipeName.toUpperCase()}`;
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    setDebouncedValue(newData);
  };

  const reprintColumns = [
    {
      field: "recipeName",
      minWidth: 100,
      flex: 1,
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Link
            to={`/plateFul/Recipes/Print?recipeId=${params.row.recipeId}`}
            style={{ color: "black" }}>
            {params.value}
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <BaseContent
        headerText={"Recipe Reprint"}
        backLink={"/plateful/Menus"}
        disableHeight={true}>
        <Box height={{ md: "80vh", xl: "85vh" }}>
          <Grid container spacing={1} padding={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h5" className="print-heading">
                Menu: {title}
              </Typography>
            </Grid>
            <FormControl>
              <Input
                disableUnderline={true}
                type="text"
                className={styles.basicInput}
                value={searchValue}
                placeholder="Filter Recipe..."
                onChange={(event) => {
                  setSearchValue(event.target.value);
                  SearchFilter(event.target.value);
                }}
              />
            </FormControl>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <BaseDataGrid
                rows={debouncedValue ? debouncedValue : []}
                loading={isLoading}
                columns={reprintColumns}
                error={hasError}
                autoHeight={false}
                height={{ md: "62vh", xl: "70vh" }}
              />
            </Grid>
          </Grid>
        </Box>
      </BaseContent>
    </>
  );
}
