import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useAddVendorCostMutation } from "../../../../../../store/apis/RecipeAPIs";
import { Card, Grid, Button, Link } from "@mui/material";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { VendorCostFields, vendorCostDefaults } from "./form.constants";
import { useForm } from "react-hook-form";
import { setErrorsForProperties } from "../../../../../shared/utils/helpers";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";

export default function AddVendorCost() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: vendorCostDefaults,
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const [
    addVendorCost,
    { isLoading: isSubmitting, error: errorResponse, isSuccess },
  ] = useAddVendorCostMutation();

  const onSubmit = (data) => {
    data["recipeId"] = recipeId;
    addVendorCost(data);
  };

  useEffect(() => {
    if (errorResponse && errorResponse?.status === 400) {
      setErrorsForProperties({
        setError: setError,
        responseError: errorResponse,
      });
    }
  }, [errorResponse]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        component={Link}
        color={"primary"}
        className="baseButton"
        variant="contained"
        onClick={() => navigate(-1)}
        startIcon={<span className="material-icons">keyboard_arrow_left</span>}>
        {"Back"}
      </Button>
      <Card>
        <Grid container spacing={2} margin={"10px"}>
          <GeneratedForm
            list={VendorCostFields}
            setValue={setValue}
            control={control}
            errors={errors}
          />
          <Grid item xs={6}>
            <BaseSubmitButton
              isSubmitting={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              className="baseButton"
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
