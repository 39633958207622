import { React, useEffect, useState } from "react";
import {
  FormControl,
  Autocomplete,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

function AutocompleteInput({
  control,
  validationProps,
  rest,
  options,
  errors,
  defaultValue,
  // for auto selection when clicked TAB button
  setSelectedValue,
  // selectedValue,
}) {
  const renderInput = (params) => (
    <TextField
      {...params}
      label={rest?.label}
      placeholder={rest?.placeholder || `Select a ${rest?.label || rest?.name}`}
      error={errors ? !!errors[rest?.name] : false}
      helperText={errors ? errors[rest?.name]?.message ?? "" : ""}
      // below onKeyUp code is for auto selection when clicked TAB button
      onKeyUp={(e) => {
        const selectValue =
          options?.find((x) => x.id == e.target.value) ||
          options?.find((x) => x.label == e.target.value) ||
          defaultValue;
        const filtered = options?.filter((option) =>
          option.label.toLowerCase().includes(e.target.value)
        );
        if (Object.entries(filtered).length == 1) {
          setSelectedValue(filtered[0]);
        } else setSelectedValue(selectValue);
      }}
    />
  );

  if (control) {
    return (
      <Controller
        control={control}
        name={rest?.name}
        rules={validationProps}
        defaultValue={defaultValue?.id}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disablePortal
            disableClearable={rest.disableClearable || false}
            onChange={(event, item) => {
              onChange(item?.id);
            }}
            getOptionDisabled={(option) => option.disabled}
            value={options?.find((option) => option.id === value) || null}
            loading={rest.loading}
            options={options}
            getOptionLabel={(item) => item?.label}
            isOptionEqualToValue={(option, value) =>
              option.id === value.id || value.id === null
            }
            {...rest}
            // below onKeyUp code is for auto selection when clicked TAB button
            onKeyUp={(e) => {
              const item = options?.find(
                (option) => option.label === e.target.value
              );

              if (item == undefined) {
                const filtered = options?.filter((option) =>
                  option.label.toLowerCase().includes(e.target.value)
                );

                if (Object.entries(filtered).length == 1)
                  onChange(filtered[0]?.id);
              } else onChange(item?.id);
            }}
            renderInput={renderInput}
            renderOption={(props, option, index) => {
              const key = `AutoComplete-${option?.id}-${index?.index}`;
              return (
                <li {...props} key={key}>
                  <Grid container direction={"column"}>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}>
                      <Typography
                        variant="p"
                        fontWeight={option?.description ? "bold" : "regular"}>
                        {option?.label}
                      </Typography>
                      {option?.description && (
                        <Typography variant="body2" color="text.secondary">
                          {option?.description}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />
    );
  }

  return (
    <Autocomplete
      disableClearable={rest.disableClearable || false}
      {...rest}
      fullWidth
      disablePortal
      options={options}
      renderInput={renderInput}
    />
  );
}

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSelect.md)
 */
export default function BaseSelect({
  options,
  defaultValue,
  control,
  // eslint-disable-next-line no-unused-vars
  validationProps,
  errors,
  ...props
}) {
  // below code is for auto selection when clicked TAB button
  const [selectedValue, setSelectedValue] = useState({ id: null, label: "" });

  useEffect(() => {
    const selectedValueChange = (value) => {
      const selectValue =
        options?.find((x) => x.id == value) ||
        options?.find((x) => x.label == value) ||
        defaultValue;
      setSelectedValue(selectValue);
    };
    selectedValueChange(defaultValue?.id);
  }, [options, defaultValue]);
  // ends code here Tab
  return (
    <FormControl
      sx={{
        ...props.sx,
      }}
      fullWidth>
      <AutocompleteInput
        control={control}
        rest={props}
        validationProps={validationProps}
        options={options}
        // below 2 code lines is for auto selection when clicked TAB button
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        errors={errors}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
}
