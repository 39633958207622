import React from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

// {breakfastDiningArea?.name}
// {breakfastTable?.name}

export default function DiningInformationSection({
  residentId,
  resident,
  breakfastDiningAreaName,
  breakfastTableName,
  lunchDiningAreaName,
  lunchTableName,
  supperDiningAreaName,
  supperTableName,
}) {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          boxShadow: "0px 4px 4px 0px #00000040",
          padding: "10px 0px",
        }}>
        <Box
          backgroundColor="#C4E1E2"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderRadius: "4px 4px 0 0",
          }}
          padding="8px 16px">
          <span className="residentTitle" style={{ fontSize: "22px" }}>
            Dining Information
          </span>
          <Link
            to={`/MealCard/addResident?id=${residentId}&step=3`}
            className="newEditButton">
            <Typography component="span" fontWeight={700}>
              Edit
            </Typography>
          </Link>
        </Box>
        <TableContainer>
          <Table
            size="small"
            sx={{
              borderCollapse: "collapse",
              "& .MuiTableCell-root": { border: "none !important" },
              "& .MuiTableRow-root": {
                borderBottom: "1px solid #bdbdbd !important",
              },
            }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                <TableCell></TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Dining Area
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Table
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Seat
                </TableCell>
                <TableCell sx={{ fontSize: "1.125rem !important" }}>
                  Fluid Restriction
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              xs={{
                borderBottom: "3px solid red !important",
              }}>
              <TableRow key={"breakfast"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}>
                  Breakfast
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {breakfastDiningAreaName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {breakfastTableName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.breakfastSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.breakfastRestrictQty}
                </TableCell>
              </TableRow>
              <TableRow key={"lunch"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}>
                  Lunch
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {lunchDiningAreaName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {lunchTableName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.lunchSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.lunchRestrictQty}
                </TableCell>
              </TableRow>
              <TableRow key={"supper"}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#F6F6F6",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}>
                  Dinner
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {supperDiningAreaName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {supperTableName}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.supperSeat}
                </TableCell>
                <TableCell sx={{ fontSize: "1rem !important" }}>
                  {resident.supperRestrictQty}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
