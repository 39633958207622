import { Grid, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useResidentThickenedLiquidSelectQuery } from "../../../../../store/apis/GenericApis";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useUpdateResidentLiquidMutation,
  useAddResidentLiquidMutation,
} from "../../../../../store/apis/ResidentApis";

export default function AddResidentLiquid() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentLiquid = location?.state?.data;
  // const ResidentName = location?.state?.name;

  const id = location?.state?.data?.id;
  const residentId = searchParams.get("residentId");

  const isEdit = !!id;

  const { data: thickendLiquid, isFetching: LiquidLoading } =
    useResidentThickenedLiquidSelectQuery();

  const [
    addResidentLiquid,
    {
      isLoading: addResidentLiquidSubmitting,

      isSuccess: addResidentNoteSuccess,
    },
  ] = useAddResidentLiquidMutation();

  const [
    updateResidentLiquid,
    {
      isLoading: updateResidentLiquidSubmitting,

      isSuccess: updateResidentNoteSuccess,
    },
  ] = useUpdateResidentLiquidMutation();

  const isSubmitting =
    addResidentLiquidSubmitting || updateResidentLiquidSubmitting;
  const isSuccess = addResidentNoteSuccess || updateResidentNoteSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isEdit) {
      reset(currentLiquid);
    }
  }, [isEdit]);

  if (!isSubmitting && isSuccess) {
    navigate(-1);
  }

  function onSubmit(data) {
    data["residentId"] = residentId;
    if (isEdit) {
      data["id"] = id;
      updateResidentLiquid(data);
      return;
    }
    addResidentLiquid(data);
  }

  return (
    <Card>
      <Typography variant="p" component={"p"} margin={2} fontWeight={600}>
        {"Resident Liquid Consistency"}
      </Typography>

      <Grid margin={"8px"} container spacing={2}>
        <Grid item md={4}>
          <BaseSelect
            name="thickenedLiquidId"
            id="thickenedLiquidId"
            label="Liquid Consistency"
            control={control}
            errors={errors}
            options={thickendLiquid || []}
            loading={LiquidLoading}
          />
        </Grid>
        <Grid item md={8} />

        <Grid item md={4}>
          <BaseSubmitButton
            onClick={handleSubmit(onSubmit)}
            isSubmitting={isSubmitting}
            text={isEdit ? "Submit" : "Create"}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
