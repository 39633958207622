import React from "react";
import { RouterProvider, useNavigate } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./utils/ApplicationInsightsService";
import { SnackbarProvider } from "notistack";
import ErrorPage from "./components/shared/errorPage/ErrorPage";
import { useMsalEvents } from "hooks/useMSALEvents";
// import { useFeatureFlagInfo } from "hooks/useFeatureFlagInfo";
import { router } from "routes/Routes";

function App({ pca }) {
  useMsalEvents(pca);
  // useFeatureFlagInfo();

  return (
    <MsalProvider instance={pca}>
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorPage}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={5000}
            disableWindowBlurListener={true}>
            <RouterProvider router={router}>
              <ClientSideNavigation pca={pca}>
                <AppInsightsContext.Provider value={reactPlugin}>
                  <div className="App" />
                </AppInsightsContext.Provider>
              </ClientSideNavigation>
            </RouterProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </AppInsightsErrorBoundary>
    </MsalProvider>
  );
}

function ClientSideNavigation({ pca, children }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return children;
}

export default App;
