/* eslint-disable */

import {
  Box,
  Button,
  Grid,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import { visitHoursData, visitMileageData } from "./form.constants";
import { DMStyles } from "../../../../../../styles/Styles";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "../../../../../shared/EditButton";
import DeleteButton from "../../../../../shared/DeleteButton";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
// import HeaderBox from "../../../../shared/HeaderBox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetVisitDetailsQuery,
  useGetVisitReportsQuery,
  useGenerateVisitReportMutation,
} from "../../../../../../store/apis/ConsultantApis";
import { isEmpty, isEqual, isUndefined, size } from "lodash";
import { BaseButton } from "../../../../../shared/BaseButton";
import { SCREEN_CONSTANT } from "../../constants";

import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { Roles, SCREEN_CONSTANTS } from "components/shared/constants";
import { buttonsData } from "../visitReport/constants";
import { WarningText } from "components/shared/WarningText";
import { theme } from "styles/theme";
import {
  innerLogsData,
  logsHeaders,
  milageHeaders,
  totalLogData,
} from "./constants";
import useMsalAccount from "utils/useMsalAccount";
import { BackButton } from "components/shared/BackButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { Print } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { userAllowedWithRoles } from "./form.constants";

export const VisitDetails = () => {
  // States
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userId, roles } = useMsalAccount();

  const isAdmin = roles?.includes(Roles.Admin);

  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  // Api Section
  // api to get the visit Details
  const {
    data: visitData,
    isLoading: hasLoading,
    isError: hasError,
  } = useGetVisitDetailsQuery(visitPlanId);
  // Destructuring
  const {
    dietitianReportSendLog,
    visitHours,
    nextConsult,
    dateOfVisit,
    community,
    totalBillableHour,
    totalMileage,
    id,
    isFinal,
    isVisitFinished,
  } = visitData || [];

  const editableRole = isFinal
    ? isFinal
    : isVisitFinished
    ? !roles.some((roleToCheck) => userAllowedWithRoles.includes(roleToCheck))
    : false;

  const {
    data: visitDetailsData,
    isLoading: visitLoading,
    isError: visitError,
  } = useGetVisitReportsQuery(id, {
    skip: !id,
  });
  const isSummaryReport = visitDetailsData?.some(
    (data) => data.visitReportTypeId === 1 || data.visitReportTypeId === 6
  );

  // Mutation Section
  // mutation to generate Report
  const [
    GenerateReport,
    {
      data: visitReport,
      isError: visitReportError,
      isSuccess: isVisitSuccess,
      isLoading: visitReportLoading,
      error: visitTypeError,
    },
  ] = useGenerateVisitReportMutation();

  // Function which will be called mutation Function
  const onSubmit = (ID, e) => {
    e.preventDefault();
    GenerateReport({
      id: ID,
    });
  };

  // Veriables
  const isError = visitError || hasError || visitReportError;
  const isLoading = visitLoading || visitReportLoading;
  const error = visitTypeError;
  // useEffect Section
  useEffect(() => {
    if (isVisitSuccess) {
      DownloadLinkHelper(visitReport?.data);
    }
    if (isError && error) {
      if (error?.data?.messages) {
        enqueueSnackbar(`Report Does not Exist`, {
          variant: "warning",
        });
      }
    }
  }, [isError, error, visitReport]);

  useEffect(() => {
    if (
      !isAdmin &&
      !isUndefined(visitData?.dietitianId) &&
      visitData?.dietitianId != userId
    )
      navigate("/forbidden", { state: { isAuthorized: false } });
  }, [userId, isAdmin, visitData]);

  // Column Section For Data Grid
  const viewReportColumns = [
    {
      field: "visitReportType",
      minWidth: 200,
      flex: 1,
      headerName: "Report Types",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "description",
      minWidth: 200,
      flex: 1,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            childrenLocation={"start"}
            // data={MenusData}
            hasEdit={!editableRole}
            index={params.row.id}
            editProps={{
              title: "Visit Report",
              To:
                params.row.visitReportTypeId == 1 ||
                params.row.visitReportTypeId == 2 ||
                params.row.visitReportTypeId == 3 ||
                params.row.visitReportTypeId == 6
                  ? `/Consultant/MyRosters/VisitDetails/VisitReport?Id=${params.row.id}&visitPlanId=${visitPlanId}&visitReportTypeId=${params.row.visitReportTypeId}&visitId=${params.row.visitId}`
                  : params.row.visitReportTypeId == 5
                  ? `/Consultant/MyRosters/VisitDetails/addMemo?visitId=${params.row.visitId}&visitReportTypeId=${params.row.visitReportTypeId}&id=${params.row.id}&visitPlanId=${visitPlanId}`
                  : params.row.visitReportTypeId
                  ? `/Consultant/MyRosters/VisitDetails/NutritionalCares?visitPlanId=${visitPlanId}&visitReportId=${params.row.id}&visitReportTypeId=${params.row.visitReportTypeId}&visitId=${params.row.visitId}&isEdit=true`
                  : `/Consultant/MyRosters/VisitDetails/AddMenus?recipeId=${params.row.id}`,
            }}
            hasDelete={!isFinal && !editableRole}
            deleteProps={{
              entityName: params.row?.visitReportType,
              apiPath: "deleteVisitReport",
            }}>
            <Button
              component={Link}
              color={"primary"}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(params.row.id, e);
              }}
              variant="contained"
              endIcon={<Print />}>
              Print
            </Button>
          </BaseActionBox>
        );
      },
    },
  ];
  const viewReportColumn = [
    {
      field: "senderEmail",
      minWidth: 100,
      flex: 1,
      headerName: "From",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "sentTo",
      minWidth: 100,
      flex: 1,
      headerName: "To",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "dateSent",
      minWidth: 100,
      flex: 1,
      headerName: "Date Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDateValid = dayjs(params.row.dateSent).isValid();
        return isDateValid
          ? dayjs(params.row.dateSent).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "reportsSent",
      minWidth: 100,
      flex: 2,
      headerName: "Report Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];

  // Ui Section
  return (
    <>
      <BackButton
        sx={{
          marginLeft: "5px",
          marginTop: "10px",
        }}
        To={"/Consultant/MyRosters"}
        iconDisabled={true}
      />
      <Box marginY={"10px"} marginX={"20px"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          p={"16px"}
          color={DMStyles.White}
          bgcolor={theme.palette.primary.main}>
          {!community ? (
            <Skeleton variant="text" width={200} height={40} />
          ) : (
            <Typography fontWeight={700} variant="h5">
              {community}
            </Typography>
          )}
        </Box>
        <Box
          position={"relative"}
          marginTop={"10px"}
          textAlign={"center"}
          p={"5px"}
          bgcolor={theme.palette.yellow.main}>
          {!dateOfVisit ? (
            <Box
              sx={{
                marginLeft: "50%",
                marginRight: "50%",
                transform: "translateX(-50%)",
              }}>
              <Skeleton variant="text" width={200} height={40} />
            </Box>
          ) : (
            <Typography fontWeight={500} variant="h7">
              Date of Consult:{" "}
              {dayjs(dateOfVisit).format(SCREEN_CONSTANTS.Date_Format)}
            </Typography>
          )}

          <Box position={"absolute"} right={"20px"} top={"5px"} width={"50px"}>
            {!isFinal ? (
              <Box>
                <BaseButton
                  sx={{ height: "25px" }}
                  colors={"white"}
                  marginLeft={"0px !important"}
                  text={"Edit"}
                  To={`/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${visitPlanId}&isEdit=${true}`}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box>
          {!hasLoading ? (
            <Box>
              {visitHours?.map((visit, index) => {
                const visitHeaders =
                  visit.consultingTypeId === 1 ? milageHeaders : logsHeaders;
                return (
                  <Box key={index}>
                    <Grid
                      sx={{
                        padding: "10px",
                        backgroundColor: "#F6F6F6",
                        alignItems: "center",
                      }}
                      container>
                      {visitHeaders?.map((item) => {
                        return (
                          <Grid
                            key={item.id}
                            item
                            xs={item.size}
                            textAlign={item?.textAlign}>
                            <Typography
                              lineHeight={"24px"}
                              fontSize={"15px"}
                              fontWeight={"700"}
                              variant="h7"
                              color="white">
                              {item.name}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {isEqual(visit?.consultingTypeId, 1) ? (
                      <Grid
                        sx={{
                          alignItems: "center",
                        }}
                        container>
                        {milageHeaders?.map((item) => {
                          const visitData =
                            visit[
                              isEqual(item.name, "Mileage")
                                ? "quantity"
                                : item.name
                            ];
                          return (
                            <Grid
                              padding="10px"
                              key={item.id}
                              item
                              borderRight={
                                item.border ? undefined : "1px solid #00000033 "
                              }
                              xs={item.size}
                              textAlign={item?.textAlign}>
                              <Typography
                                lineHeight={"24px"}
                                fontSize={"15px"}
                                fontWeight={"500"}
                                variant="h7"
                                color="white">
                                {visitData}
                                {isEqual(item.name, "Mileage")
                                  ? " miles"
                                  : null}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <Grid
                        borderTop={"1px solid #00000033"}
                        borderBottom={"1px solid #00000033"}
                        sx={{
                          alignItems: "center",
                        }}
                        container>
                        <Grid item xs={3} padding={"10px"} textAlign={"center"}>
                          <Typography
                            lineHeight={"24px"}
                            fontSize={"14px"}
                            fontWeight={"500"}
                            variant="h7"
                            color="white">
                            {visit?.consultingTypeName}
                          </Typography>
                        </Grid>
                        <Grid borderLeft={"1px solid #00000033"} item xs={9}>
                          <Grid container direction="column">
                            <Grid
                              container
                              borderBottom={"1px solid #00000033"}
                              borderRight={"1px solid #00000033"}
                              height={"100%"}>
                              {innerLogsData.map((itemData) => {
                                const visitData = visit[itemData.name];
                                return (
                                  <Grid
                                    key={itemData.id}
                                    item
                                    xs={itemData.size}
                                    textAlign={itemData.textAlign}
                                    padding={"10px"}
                                    borderRight={
                                      itemData.border
                                        ? undefined
                                        : "1px solid #00000033"
                                    }>
                                    <Typography
                                      lineHeight={"24px"}
                                      fontSize={"14px"}
                                      fontWeight={"500"}
                                      variant="h7"
                                      color="white">
                                      {visitData}
                                    </Typography>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                          {/* <Grid item padding={"10px"}>
                            <Typography
                              lineHeight={"24px"}
                              fontSize={"15px"}
                              fontWeight={"500"}
                              variant="h7"
                              color="white">
                              Notes: {visit?.notes}
                            </Typography>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                );
              })}

              {!isEqual(size(visitHours), 0) ? (
                <Grid
                  sx={{
                    backgroundColor: theme.palette.yellow.main,
                    alignItems: "center",
                    borderTop: "1px solid black",
                  }}
                  container>
                  {totalLogData?.map((item, index) => {
                    const visitTotals = {
                      nextConsult: nextConsult
                        ? dayjs(nextConsult).format(
                            SCREEN_CONSTANTS.Date_Format
                          )
                        : "",
                      totalMileage,
                      totalBillableHour,
                    };
                    const totalLogsValue = visitTotals[item?.id];
                    return (
                      <Grid
                        paddingLeft={"5px"}
                        key={index}
                        item
                        xs={item.size}
                        borderRight={
                          item?.border ? undefined : "1px solid #00000033 "
                        }
                        textAlign={item?.textAlign}>
                        <Typography
                          lineHeight={"24px"}
                          fontSize={"15px"}
                          fontWeight={"700"}
                          variant="h7"
                          color="white">
                          {item.name} {totalLogsValue}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}
            </Box>
          ) : (
            <Box>
              {[...Array(3)].map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    backgroundColor: "#F6F6F6",
                    marginBottom: "10px",
                  }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={24}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={24}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={24}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={24}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          )}

          <Box display={"flex"} justifyContent={!isFinal ? "center" : ""}>
            <Box
              width={"90%"}
              marginY={"10px"}
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={"space-between"}
              alignItems={"center"}>
              {!isFinal ? (
                buttonsData?.map((data, index) =>
                  (data?.visitReportTypeId == 1 ||
                    data?.visitReportTypeId == 6) &&
                  isSummaryReport ? null : (
                    <Box key={index} marginBottom={"10px"}>
                      <BaseButton
                        colors={data.color}
                        startIcon={"add"}
                        text={data.text}
                        To={`${data.Link}?visitPlanId=${visitPlanId}&visitId=${id}&visitReportTypeId=${data?.visitReportTypeId}`}
                      />
                    </Box>
                  )
                )
              ) : (
                // <Box width={"100%"}>
                <WarningText
                  margin={"0px"}
                  text={
                    "This reports have been submitted to DiningRD, no further changes can be made"
                  }
                  color={"warning"}
                />
                // </Box>
              )}
            </Box>
          </Box>
          <Box marginY={"10px"}>
            {!isUndefined(visitDetailsData) && !isEmpty(visitDetailsData) ? (
              <BaseDataGrid
                rows={visitDetailsData || []}
                columns={viewReportColumns}
                loading={isLoading}
                error={isError}
                height={"100%"}
                // getRowHeight={() => "auto"}
                getRowId={(row) => row.id}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                }}
              />
            ) : null}
          </Box>
          <Box marginY={"10px"}>
            {!isEmpty(dietitianReportSendLog) ? (
              <BaseDataGrid
                rows={dietitianReportSendLog || []}
                columns={viewReportColumn}
                loading={hasLoading}
                error={hasError}
                height={"100%"}
                // getRowHeight={() => "auto"}
                getRowId={(row) => row.id}
                getRowHeight={() => "auto"}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                }}
              />
            ) : null}
          </Box>
        </Box>
        {size(visitDetailsData) > 0 ? (
          <FlexBox mt={1} justifyContent={"flex-end"}>
            <Tooltip
              title={
                !isSummaryReport
                  ? "This report cannot be submitted until a Visit Summary has been created."
                  : null
              }
              placement="top">
              <Box>
                <BaseButton
                  text={"Submit Report to Community"}
                  To={`/Consultant/MyRosters/VisitDetails/SendReport?visitPlanId=${visitPlanId}&isEdit=true`}
                  // colors={"white"}
                  // fontColor={DMStyles.Teal}
                  // border={`1px solid ${DMStyles.Teal}`}
                  disabled={!isSummaryReport}
                />
              </Box>
            </Tooltip>
            {!isFinal ? (
              <Tooltip
                title={
                  !isSummaryReport
                    ? "This report cannot be submitted until a Visit Summary has been created."
                    : null
                }
                placement="top">
                <Box>
                  <BaseButton
                    text={"Submit Report to DiningRD"}
                    To={`/Consultant/MyRosters/VisitDetails/SubmitReportToHealthTech?visitPlanId=${visitPlanId}`}
                    disabled={!isSummaryReport}
                    toolTip={!isSummaryReport}
                  />
                </Box>
              </Tooltip>
            ) : null}
          </FlexBox>
        ) : null}
      </Box>
    </>
  );
};
