import React from "react";
import { useDrop } from "react-dnd";
import { COLUMN_IDS } from "./customizedPostedMenu.constants";
import { Box } from "@mui/material";
import BaseInput from "components/shared/baseForm/BaseInput";
import { MAX_LENGTH_VALIDATION } from "../../../../utils/errorMessages";

export const Column = ({
  name,
  control,
  children,
  className,
  title,
  errors,
  setIsDirty,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "card",
    drop: () => {
      setIsDirty(true);
      return { name: title };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => {
      const {
        UnGrouped,
        Grouping1,
        Grouping2,
        Grouping3,
        Grouping4,
        Grouping5,
        Grouping6,
      } = COLUMN_IDS;
      const { currentColumnName } = item;
      return (
        UnGrouped ||
        Grouping1 ||
        Grouping2 ||
        Grouping3 ||
        Grouping4 ||
        Grouping5 ||
        Grouping6 ||
        currentColumnName
      );
    },
  });

  const getBackgroundColor = () => {
    if (isOver) {
      if (canDrop) {
        return "rgb(188,251,255)";
      } else if (!canDrop) {
        return "rgb(255,188,188)";
      }
    } else {
      return "";
    }
  };

  return (
    <div
      ref={drop}
      className={className}
      style={{ backgroundColor: getBackgroundColor() }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        width={"100%"}
        marginTop={"5px"}>
        {name ? (
          <BaseInput
            control={control}
            size={"small"}
            placeholder={"Name"}
            name={name}
            id={name}
            fullWidth={true}
            validationProps={{
              maxLength: MAX_LENGTH_VALIDATION("Name", 50),
            }}
            errors={errors}
            unit={<span className="material-icons">mode_edit</span>}
          />
        ) : null}
        {children}
      </Box>
    </div>
  );
};
