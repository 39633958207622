import React from "react";
import { useSearchParams } from "react-router-dom";
import { useFacilityVisitsQuery } from "../../../../../../store/apis/FacilityApis";
import { Typography } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseActionBox from "components/shared/BaseActionBox";

export default function CommunityVisits() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const {
    data: visits,
    isLoading,
    isError,
  } = useFacilityVisitsQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const communityColumns = [
    {
      field: "name",
      headerName: "Consultant Name",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.dietitianLastName +
              ", " +
              params?.row?.dietitianFirstName}
          </>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    {
      field: "visitDate",
      minWidth: 50,
      flex: 1,
      headerName: "Visit Date",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueGetter: (params) => dayjs(params.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params.value).isValid();
        return isDateValid
          ? dayjs(params.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "View",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.visitId}
            data={visits?.facilityVisitList}
            viewProps={{
              To: `/admin/communities/visitsDetail/detail?visitId=${params?.row?.id}&facilityId=${visits?.facilityId}&visitPlanId=${params?.row?.visitPlanId}`,
              title: "Visits",
            }}
            hasView={true}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"start"} my="8px">
        <Typography variant="h6">
          Average hours per month: {visits?.averageHours.toFixed(2)}
        </Typography>
      </FlexBox>
      <BaseDataGrid
        rows={visits?.facilityVisitList}
        columns={communityColumns}
        error={isError}
        loading={isLoading}
        autoHeight={true}
      />
    </>
  );
}
