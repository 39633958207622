import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Typography, Skeleton, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAddIngredientAllergyMutation } from "../../../../../../store/apis/IngredientAPIs";
import { useGetAllergiesLookupQuery } from "../../../../../../store/apis/AllergyAPIs";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { BaseMultiSelect } from "../../../../../shared/baseForm/BaseMultiSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "../../../../../shared/BaseButton";

function AddIngredientAllergy() {
  const [
    addIngredientAllergy,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddIngredientAllergyMutation();

  const { data: allergyList, isFetching: allergyListLoading } =
    useGetAllergiesLookupQuery("");

  const isError = isAddError;
  const error = addError;
  const isSuccess = addSuccess;
  const isSubmitting = addLoading;
  const [searchParams] = useSearchParams();
  const currentIngredientId = searchParams.get("ingredientId");
  const ingredientName = searchParams.get("name");

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(`Allergies added successfully.`, { variant: "success" });
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.allergyFoods.map((value) => {
      const newData = {
        ingredientId: currentIngredientId,
        allergyFoodId: value,
      };

      addIngredientAllergy(newData);
    });
  };

  return (
    <>
      <BaseButton
        text="Back To Ingredient Allergies"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />

      <Typography
        color="primary"
        variant="h5"
        className="userFacilityTitle"
        sx={{ marginTop: "10px" }}>
        Add Allergies for {ingredientName}
      </Typography>

      <React.Fragment>
        <FormProvider>
          <form>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12}>
                {allergyListLoading ? (
                  <Skeleton />
                ) : (
                  <BaseMultiSelect
                    label="Allergy"
                    name="allergyFoods"
                    id="allergyFoods"
                    setValue={setValue}
                    control={control}
                    options={allergyList}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Allergy"),
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Box>
            {isError ? (
              <Typography color={"error"}>{error?.data?.messages}</Typography>
            ) : undefined}
          </form>
        </FormProvider>
      </React.Fragment>
    </>
  );
}

export default AddIngredientAllergy;
