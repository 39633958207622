import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";

export const REGION_FIELDS = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Region Name",
      placeholder: "Region Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Region Name"),
    },
    size: 6,
  },
  {
    props: {
      name: "managerDietitianId",
      id: "managerDietitianId",
      label: "Region Manager",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRegionalManagerUserRoleSelectList",
  },
];
