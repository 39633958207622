import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { useGetMenusForUserQuery } from "../../../../../../store/apis/MenuApis";
import YesNoDisplay from "../../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";

export default function UserMenus() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const currentId = searchParams.get("id");
  const menuName = searchParams.get("name");

  const {
    data: menuList,
    isError: isError,
    isFetching: isLoading,
  } = useGetMenusForUserQuery(
    {
      userId: currentId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const MenuListColumns = [
    {
      field: "menuName",
      headerName: "Menu",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "htiMenu",
      headerName: "HTI Menu",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.htiMenu} />;
      },
    },
  ];

  return (
    <>
      <Box display={"flex"}>
        <BaseButton
          sx={{ marginLeft: "0px !important" }}
          text="Back to User"
          startIcon={"  keyboard_arrow_left"}
          To={`/admin/usermenu/usersetup?search=&archived=false`}
        />
      </Box>
      <Typography
        variant="h4"
        sx={{ fontSize: "24px", fontWeight: "semibold", marginTop: "10px" }}>
        Menus accessible to {name}
      </Typography>

      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            // sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isNameID={true}
            name={menuName}
            Id={currentId}
          />
        </div>
      </div>

      <BaseDataGrid
        rows={menuList}
        columns={MenuListColumns}
        loading={isLoading}
        error={isError}
        defaultPageSize={100}
        getRowId={(row) => row.id}
        autoHeight={false}
        height={{ md: "40vh", xl: "47vh" }}
      />
    </>
  );
}
