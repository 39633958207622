import React from "react";
import { Outlet } from "react-router-dom";
import { RecipeSetupElements } from "./recipesetup.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import { Box } from "@mui/system";

export default function RecipeSetup() {
  return (
    <>
      <AdminTabs Data={RecipeSetupElements} />
      <Card
        sx={{
          padding: "5px",
          overflow: "auto",
          borderTopLeftRadius: "0px !important",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}>
        <Box height={"auto"}>
          <Outlet />
        </Box>
      </Card>
    </>
  );
}
