import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Card } from "./Card";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { DMStyles } from "../../../../../styles/Styles";
import {
  useUpdateVisitPlansMutation,
  useVisitPlansMutation,
} from "../../../../../store/apis/ConsultantApis";
import { ButtonConstants } from "../constants";
import { useGetFacilitiesByDietitianQuery } from "../../../../../store/apis/DietitianAPis";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
// import { size } from "lodash";

export const MyCalendar = ({
  events,
  calendarDate,
  calendarKey,
  dietitionId,
}) => {
  // states
  const calendarRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState({
    planId: "",
    visitId: "",
  });
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  // const [height, setHeight] = useState([]);
  // Api section
  // Api to get facilitiesData on the basis of dietition ID
  const { data: facilitiesData } = useGetFacilitiesByDietitianQuery(
    dietitionId,

    {
      skip: !dietitionId || dietitionId === 0,
      refetchOnMountOrArgChange: true,
    }
  );
  // Mutation Section
  // api to create visitPlane
  const [visitPlane] = useVisitPlansMutation();
  // use to update visitPlane on the basis of Date
  const [updatedVisitPlane] = useUpdateVisitPlansMutation();

  // useEffect Section
  // use to enable drag and drop functionality in calender
  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    if (draggableEl) {
      const draggable = new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let facilityid = eventEl.getAttribute("data-facilityid");
          let dietitianid = eventEl.getAttribute("data-dietitianid");
          let visitid = eventEl.getAttribute("data-visitid");
          return {
            title: title,
            facilityid: facilityid,
            dietitianid: dietitianid,
            visitid: visitid,
          };
        },
      });
      return () => {
        draggable.destroy();
      };
    }
  }, []);
  // used to get the height on the basis of event and dynamically change the height of weekly time column in calender
  // useEffect(() => {
  //   var elements = document.querySelectorAll(".fc-day-sun");
  //   // Check if elements were found
  //   if (elements.length > 0) {
  //     const heights = [];
  //     elements.forEach((element) => {
  //       const height = element.getBoundingClientRect().height.toFixed(2);
  //       heights.push(height);
  //     });
  //     setHeight(heights);
  //   }
  // }, [events]);

  // function for date formatting
  const dataFormat = (item) => {
    const date = new Date(item);
    // const timezoneOffset = date.getTimezoneOffset();
    // date.setMinutes(date.getMinutes() - timezoneOffset);
    return date;
  };

  // handleDrop is used when we drag card internally in the calander and past into the other date
  const handleDrop = (info) => {
    const dietitianId = info.event._def.extendedProps.dietitionId;
    const facilityId = info.event._def.extendedProps.facilityId;
    const visitId =
      info.event._def.extendedProps.visitId == "undefined"
        ? null
        : info.event._def.extendedProps.visitId;
    const selectedDate = dataFormat(info.event.start);
    const newEvent = {
      id: info.event.id,
      dietitianId: parseInt(dietitianId), // You should generate a unique ID for the new event
      facilityId: parseInt(facilityId),
      visitId: parseInt(visitId) == 0 ? null : parseInt(visitId),
      planDate: dayjs(selectedDate).isValid()
        ? dayjs(selectedDate).format("YYYY-MM-DD")
        : null,
      scheduled: true,
      isCdmvisit: false,
      dietitian: null,
      facility: null,
      visit: null,
    };
    updatedVisitPlane(newEvent);
  };

  // handleEventReceive is used when we drag extrnally events into the calander
  const handleEventReceive = (info) => {
    const dietitianId = info.event._def.extendedProps.dietitianid;
    const facilityId = info.event._def.extendedProps.facilityid;
    const visitId =
      info.event._def.extendedProps.visitid == "undefined"
        ? 0
        : info.event._def.extendedProps.visitid;

    const selectedDate = dataFormat(info.event.start);
    const newEvent = {
      dietitianId: parseInt(dietitianId), // You should generate a unique ID for the new event
      facilityId: parseInt(facilityId),
      visitId: parseInt(visitId) == 0 ? null : parseInt(visitId),
      planDate: dayjs(selectedDate).isValid()
        ? dayjs(selectedDate).format("YYYY-MM-DD")
        : null,
      scheduled: true,
      isCdmvisit: false,
      dietitian: null,
      facility: null,
      visit: null,
    };
    visitPlane(newEvent);
  };

  // when we clicked on the event this function will call which show the pop up
  function handleEventClick(info) {
    // `info` contains information about the clicked event
    const event = info.jsEvent;
    setId({
      planId: info.event.id,
      visitId: info.event.extendedProps.visitId,
      contractedHours: info.event.extendedProps.contractedHours,
      averageHours: info.event.extendedProps.averageHours,
    });
    setPopupPosition({
      top: event.y + 10,
      left: event.x,
    });
    setModalOpen(true);
  }
  // used to formate event array
  const eventArray = events?.map((item) => {
    const bgColor = ButtonConstants.find(
      (color) => color.id == item.visitStatus
    );
    return {
      id: item.visitPlanId, // You should use a unique identifier here
      dietitionId: item.dietitianId,
      visitId: item.visitId,
      facilityId: item.facilityId,
      contractedHours: item.contractedHours,
      averageHours: item.averageHours,
      title: item.facilityName,
      start: item.startDate, // Assuming startDate is the start date
      color: bgColor?.backgroundColor,
      // Assuming the same date for start and end
    };
  });

  // its just temporary data for the extra column, replace it once weekly time data is available
  // const columndatas = [
  //   { id: 1, data: 1.2 },
  //   { id: 2, data: 1.2 },
  //   { id: 3, data: 1.2 },
  //   { id: 4, data: 1.2 },
  //   { id: 5, data: 1.2 },
  // ];
  // const columndatas1 = [
  //   { id: 1, data: 1.2 },
  //   { id: 2, data: 1.2 },
  //   { id: 3, data: 1.2 },
  //   { id: 4, data: 1.2 },
  //   { id: 5, data: 1.2 },
  //   { id: 6, data: 1.2 },
  // ];
  // // check used to find the length of the weeks in month
  // const columndata = size(height) > 6 ? columndatas1 : columndatas;

  return (
    <>
      <Box marginTop="10px">
        <Box display="flex" width="100%">
          <Box display={"flex"} flexDirection="column" width="30%">
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
              }}>
              Facility
            </Typography>
            <Box
              id="external-events"
              width="100%"
              marginTop="20px"
              marginRight="20px"
              borderRadius="10px"
              padding="5px"
              maxHeight={{ md: "400px", xl: "450px" }}
              overflow={"auto"}>
              {facilitiesData?.length > 0 ? (
                facilitiesData?.map((item, index) => (
                  <Card key={index} item={item} />
                ))
              ) : (
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: DMStyles.Teal,
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  No Facility Available{" "}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            width={"70%"}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}>
            <Box width="80%">
              <FullCalendar
                key={calendarKey}
                ref={calendarRef}
                initialDate={`${calendarDate}`}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                headerToolbar={false}
                height={"100%"}
                events={eventArray}
                editable={true}
                droppable={true}
                eventDrop={handleDrop}
                eventReceive={handleEventReceive}
                eventClick={handleEventClick} // Add this line
                fixedWeekCount={false}
              />
            </Box>

            {/* <Box
              border="1px solid #CCCCCC"
              borderLeft={"none"}
              borderBottom={"none"}
              // height={"100%"}
              width={"12%"}
              // overflowY="auto" // Enable vertical scrolling if needed
              display={"flex"}
              flexDirection={"column"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderBottom="1px solid #CCCCCC"
                height={`29px`}>
                <Typography
                  fontWeight={"bold"}
                  fontSize={{ md: "13px", xl: "15px" }}>
                  Weekly Time{" "}
                </Typography>
              </Box>
              {columndata?.map((data, index) => (
                <Box
                  key={index}
                  borderBottom="1px solid #CCCCCC"
                  height={`${height[index + 1] + 1}px`}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  padding={"5px"}>
                  {data?.data}
                </Box>
              ))}
            </Box> */}
          </Box>
        </Box>
      </Box>

      {isModalOpen ? (
        <>
          <Box
            position={"absolute"}
            zIndex={"10"}
            top="0px"
            height={"100vh"}
            width={"100%"}
            onClick={() => setModalOpen(false)}></Box>
          <Box
            sx={{ backgroundColor: DMStyles.White }}
            position="absolute"
            top={`${popupPosition.top}px`}
            left={`${popupPosition.left}px`}
            zIndex={"10"}
            border={`1px solid ${DMStyles.Gray}`}
            borderRadius={"10px"}
            onClose={() => setModalOpen(false)}>
            <Box padding={"5px"}>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                Contracted Hours : {id.contractedHours}
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
                Average Hours : {id.averageHours}
              </Typography>
              {/* <Typography sx={{ fontWeight: "bold", fontSize: "10px" }}>
                Time Calculator : 8.00
              </Typography> */}

              <Button
                component={Link}
                to={
                  id.visitId
                    ? `/Consultant/MyRosters/VisitDetails?visitPlanId=${id.planId}`
                    : `/Consultant/MyRosters/ConsultantsDetails?visitPlanId=${id.planId}`
                }
                sx={{
                  fontSize: "15px",
                  textTransform: "none",
                  border: `1px solid ${DMStyles.OrangeColor}`,
                  color: "black",
                  fontWeight: "bold",
                  padding: "2px",
                  borderRadius: "10px",
                }}>
                View/ Add Consult
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
    </>
  );
};
