import React, { useState, useEffect } from "react"; //{ useEffect }
import "../Ingredient.css";
import { useSearchParams, Link, useLocation } from "react-router-dom";
import {
  useCopyVendorIngredientMutation,
  useGetVendorIngredientsQuery,
} from "../../../../../../store/apis/IngredientAPIs";
import DeleteModal from "../../../../../shared/deleteModal/DeleteModal";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Grid,
  Modal,
  Fade,
  Divider,
  CircularProgress,
} from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import { useForm } from "react-hook-form";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { searchFields } from "../../../../../plateFul/menus/missingVendor/headingDetails/copyForm.constant";
import { useSnackbar } from "notistack";
import { DMStyles } from "../../../../../../styles/Styles";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseMenu from "components/shared/BaseMenu";
import { AddBusiness, Report } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function VendorIngredient() {
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openCopyModalId, setOpenCopyModalId] = useState(false);
  const [openCopyModalVendorName, setOpenCopyModalVendorName] = useState(false);
  const currentLocation = useLocation();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const ingredientId = searchParams.get("id");
  const menuId = searchParams.get("menuId");
  const ingredientName = searchParams.get("name" || "");


  const {
    data: vendorList,
    isError,
    isFetching: isLoading,
  } = useGetVendorIngredientsQuery(
    {
      ingredientId: ingredientId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const VendorIngredientOptions = ({ index, id, name, vendorName }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <BaseMenu index={index}>
          <MenuItem
            disableRipple
            onClick={() => {
              setOpenCopyModal(true);
              setOpenCopyModalId(index);
              setOpenCopyModalVendorName(vendorName);
            }}>
            {currentLocation.pathname ===
            "/admin/recipes/ingredients/VendorIngredient" ? (
              <Link
                to={`./CopyVendorIngredient?id=${id}&name=${name}&vendor=${vendorName}`}
                style={{ textDecoration: "none", color: "black" }}>
                {" "}
                Copy
              </Link>
            ) : null}
            {currentLocation.pathname !==
            "/admin/recipes/ingredients/VendorIngredient"
              ? "Copy"
              : null}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete
          </MenuItem>
        </BaseMenu>

        {openModal ? (
          <DeleteModal
            id={id}
            entityName={vendorName}
            apiPath={"deleteVendorIngredient"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const VendorListColumns = [
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "packSize",
      headerName: "Pack Size",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 50,
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "problems",
      headerName: "Problems",
      headerAlign: "center",
      cellClassName: "cellCenterAlign red-text",
      flex: 0.5,
      width: 50,
      valueFormatter: ({ value }) => (value > 0 ? "*" : ""),
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={vendorList}
            hasEdit={true}
            editProps={{
              To:
                currentLocation.pathname ===
                "/admin/recipes/ingredients/VendorIngredient"
                  ? `addVendorIngredient?id=${params.row.id}`
                  : `/plateFul/Menus/MissingVendors/AddVendorIngredients?menuId=${menuId}&ingredientId=${ingredientId}&id=${params.row.id}&name=${ingredientName}`,

              state: {
                title: "Edit Vendor Ingredient",
                data: vendorList.find((row) => row.id === params.row.id),
              },
            }}
            childrenLocation={"end"}>
            <VendorIngredientOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={ingredientName}
              vendorName={params.row["vendorName"] || ""}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <Grid container alignItems={"center"} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          {currentLocation.pathname ===
          "/admin/recipes/ingredients/VendorIngredient" ? (
            <BackButton text={"Back To Recipe Vendor Costs"} />
          ) : null}

          <BaseButton
            text="Add Vendors"
            To={`/plateFul/Menus/MissingVendors/AddVendorIngredients?menuId=${menuId}&ingredientId=${ingredientId}&name=${ingredientName}`}
            endIcon={<AddBusiness />}
            sx={
              currentLocation.pathname ===
              "/admin/recipes/ingredients/VendorIngredient"
                ? {
                    marginLeft: "20px",
                    marginBottom: "0px",
                  }
                : {
                    marginLeft: "2px !important",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }
            }
          />
        </Grid>

        <Grid item xs={6}>
          {currentLocation.pathname !==
          "/admin/recipes/ingredients/VendorIngredient" ? (
            <Box
              sx={{
                color: DMStyles.Teal,
                border: DMStyles.Border,
                padding: "10px",
                margin: "10px !important",
                borderRadius: DMStyles.BorderRadius,
                width: "97%",
              }}>
              <span>A &apos;</span>
              <span style={{ color: DMStyles.Red }}>*</span>
              <span>
                &apos; in any line below means that the base cost could not be
                calculated.
              </span>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ marginTop: "25px" }}>
              <span>A &apos;</span>
              <span style={{ color: "red" }}>*</span>
              <span>
                &apos; in any line below means that the base cost could not be
                calculated.
              </span>
            </Box>
          )}
        </Grid>
      </Grid>
      <Typography color="primary" variant="h5" className="userFacilityTitle">
        Vendors for {ingredientName}
      </Typography>

      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Box>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isNameID={true}
            name={ingredientName}
            Id={ingredientId}
          />
        </Box>
      </FlexBox>
      {openCopyModal ? (
        <CopyModal
          id={openCopyModalId}
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          name={ingredientName}
          vendorName={openCopyModalVendorName}
        />
      ) : null}
      <BaseDataGrid
        rows={vendorList}
        columns={VendorListColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50vh", xl: "55vh" }}
      />
    </>
  );
}

function CopyModal({ id, open, close, name, vendorName }) {
  const [
    copyVendorIngredient,
    { data: copiedVendor, isSuccess: copySuccess, isLoading: copyLoading },
  ] = useCopyVendorIngredientMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (copySuccess) {
      enqueueSnackbar(
        `Vendor ${vendorName} copied to ${copiedVendor?.data?.vendorName} successfully.`,
        { variant: "success" }
      );
      close();
    }
  }, [copySuccess]);
  const onSubmit = (data) => {
    data.vendorIngredientId = Number(id);
    copyVendorIngredient(data);
  };
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}>
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <FlexBox
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}>
            <Report color="error" sx={{ fontSize: "90px" }} />

            <Box flexGrow={1}>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h5"
                component="h1"
                sx={{ textAlign: "center" }}
                className="modalTitle">
                Copy {name} From {vendorName}
              </Typography>
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "5px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <GeneratedForm
                  list={searchFields}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </Grid>
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox
            justifyContent={"flex-end"}
            paddingRight={"24px"}
            paddingY={"10px"}
            gap={"10px"}>
            <Button variant="contained" onClick={close} color={"warning"}>
              Cancel
            </Button>
            <Button
              variant="contained"
              component={Link}
              onClick={handleSubmit(onSubmit)}
              color={"error"}
              startIcon={
                copyLoading ? (
                  <CircularProgress color="white" size={18} />
                ) : null
              }>
              Copy
            </Button>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}
