import { Box, Grid } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import { BackButton } from "components/shared/BackButton";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddVisitReportTypeMutation,
  useGetVisitReportTypeByIdQuery,
  useUpdateVisitReportTypeMutation,
} from "store/apis/VisitReportAPIs";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import {
  VISIT_REPORT_TYPE_DEFAULTS,
  VISIT_REPORT_TYPE_FORM,
} from "./addVisitReportType.constants";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import SectionGroup from "./forms/SectionGroup";

export default function AddVisitReportType() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const isEdit = !!id;

  const { data, isLoading } = useGetVisitReportTypeByIdQuery(id, {
    skip: !id,
  });

  const [addVisitReportType, { isSuccess: addSuccess, isLoading: addLoading }] =
    useAddVisitReportTypeMutation();

  const [
    updateVisitReportType,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateVisitReportTypeMutation();

  const isEditLoading = isEdit && isLoading;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitLoading = addLoading || updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: VISIT_REPORT_TYPE_DEFAULTS,
  });

  // Destructing
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isLoading && data) {
      reset(data);
    }
  }, [isLoading, data]);

  if (isSuccess) navigate("/admin/dietitian/visitReportBuilder");

  function onSubmit(data) {
    console.log(data);
    if (isEdit) {
      updateVisitReportType(data);
      return;
    }
    addVisitReportType(data);
    return;
  }

  return (
    <Box>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignContent={"center"}>
            <BackButton />
            <BaseSubmitButton
              isSubmitting={isSubmitLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
          <Grid my={2} container spacing={2}>
            <GeneratedForm
              errors={errors}
              control={control}
              list={VISIT_REPORT_TYPE_FORM}
            />
          </Grid>
          <SectionGroup errors={errors} control={control} />
        </Box>
      )}
    </Box>
  );
}
