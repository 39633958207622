import { Grid, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import {
  useGetChangeHistoryQuery,
  useGetMenuByIdQuery,
} from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../../shared/baseContent/BaseContent";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import { SCREEN_CONSTANTS } from "components/shared/constants";

export const ChangeHistoryMenu = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("menuId");

  dayjs.extend(utcPlugin);

  //query for a specific id menu
  const { data: MenuData } = useGetMenuByIdQuery(id, { skip: !id });
  const {
    data: changeHistoryData,
    isLoading: changeHistoryLoading,
    error: hasError,
  } = useGetChangeHistoryQuery(id, { skip: !id });

  const changeHistoryColumns = [
    {
      field: "description",
      minWidth: 400,
      flex: 2,
      headerName: "Change Description",
    },
    {
      field: "day",
      maxWidth: 90,
      flex: 1,
      headerName: "Day",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "mealName",
      maxWidth: 90,
      flex: 1,
      headerName: "Meal",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "lastUpdated",
      minWidth: 100,
      flex: 1,
      headerName: "Last Updated",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return dayjs
            .utc(params?.value)
            .local()
            .format(SCREEN_CONSTANTS.DateTime_Format);
        }
      },
    },
    {
      field: "updatedBy",
      minWidth: 100,
      flex: 1,
      headerName: "Updated By",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];
  return (
    <>
      <BaseContent headerText={"Change History"} backLink={"/plateFul/Menus"}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontSize: "30px",
                marginY: "8px",
              }}>
              {`Menu: `}
              {MenuData?.menuName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <BaseDataGrid
              rows={changeHistoryData}
              loading={changeHistoryLoading}
              columns={changeHistoryColumns}
              error={hasError}
              autoHeight={true}
              height={{ md: "68vh", xl: "72vh" }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                  py: "22px",
                },
              }}
            />
          </Grid>
        </Grid>
      </BaseContent>
    </>
  );
};
