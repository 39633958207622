import { REQUIRED_ERROR } from "utils/errorMessages";

export const addVendorFields = [
  {
    props: {
      name: "itemNumber",
      id: "itemNumber",
      label: "Vendor Item #",
    },
    validationProps: {
      required: REQUIRED_ERROR("Vendor Item #"),
      min: {
        value: 1,
        message: "Value must be greater than 0.",
      },
    },
    min: 1,
    size: 12,
    type: "number",
  },
  {
    props: {
      name: "price",
      id: "price",
      label: "Price",
      type: "number",
    },
    validationProps: {
      min: {
        value: 0,
        message: "Price must be 0 or greater than 0.",
      },
    },
    defaultValue: 0,
    unit: "$",
    unitPosition: "start",
    size: 12,
  },
  {
    props: {
      name: "packSize",
      id: "packSize",
      label: "Pack Size",
      placeholder: "Pack Size",
    },
    size: 12,
  },
  {
    props: {
      name: "quantity",
      id: "quantity",
      label: "Quantity",
      placeholder: "Quantity",
      type: "number",
    },
    defaultValue: 0,
    size: 12,
  },
];
