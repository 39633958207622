import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetCommunicationListQuery } from "../../../../../../store/apis/FacilityApis";
import { Grid } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";

export default function CommunityCommunications() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const {
    data: communications,
    isLoading,
    isError,
  } = useGetCommunicationListQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const communityColumns = [
    {
      field: "createdDate",
      minWidth: 50,
      flex: 1,
      headerName: "Date",
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "clientContact",
      minWidth: 50,
      flex: 1,
      headerName: "Client Contact",
    },
    {
      field: "communicationType",
      minWidth: 50,
      flex: 1,
      headerName: "Communication Type",
      renderCell: (params) => {
        switch (params.row.communicationType) {
          case 1:
            return "New Service/Product";
          case 2:
            return "Issue/Complaint";
          case 3:
            return "Technical Support";
          case 4:
            return "Other";
          default:
            return "";
        }
      },
    },
    {
      field: "note",
      minWidth: 50,
      flex: 1,
      headerName: "Note",
    },
    {
      field: "createdBy",
      minWidth: 50,
      flex: 1,
      headerName: "Team Member",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={communications}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addCommunityCommunication?id=${id}&communicationId=${params.row.id}`,
              title: "Corporate Group",
            }}
            deleteProps={{
              entityName: params.row?.clientContact,
              apiPath: "deleteCommunication",
              title: "Corporate Group",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Grid my={"8px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add Communication"}
          To={`addCommunityCommunication?id=${id}`}
          endIcon={<PostAdd />}
        />
      </Grid>
      <BaseDataGrid
        rows={communications}
        columns={communityColumns}
        error={isError}
        loading={isLoading}
        autoHeight={true}
      />
    </>
  );
}
