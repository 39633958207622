import React, { useEffect, useState, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Typography, Grid, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BaseAutoComplete from "../../../shared/baseForm/BaseAutoComplete";
import { useGetVendorSelectBoxQuery } from "../../../../store/apis/VendorApis";
import { useGetVendorCompareReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import { REQUIRED_ERROR } from "utils/errorMessages";

import LinearProgress from "@mui/material/LinearProgress";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import { debounce } from "lodash";
import { BaseButton } from "components/shared/BaseButton";

export default function CompareVendorPricing() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetVendorCompareReportMutation();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const onSubmit = (data) => {
    // console.log(data);

    getReport(data);
  };

  return (
    <>
      <Box marginTop="10px">
        <BaseButton
          text="Back To Vendor Reports"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
      </Box>

      <Box marginLeft="10px">
        <Typography
          sx={{ marginTop: "10px" }}
          color="primary"
          variant="h5"
          className="userFacilityTitle">
          Select Vendors to Compare
        </Typography>
      </Box>

      <React.Fragment>
        <FormProvider>
          <form>
            <Grid container spacing={2} style={{ marginTop: "24px" }}>
              <Grid item xs={5}>
                <VendorSelectBox
                  name="vendorId1"
                  label="Vendor 1"
                  control={control}
                  errors={errors}
                  defaultOptions={{ id: null, label: "" }}
                  validationProps={{
                    required: REQUIRED_ERROR("Vendor 1"),
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Box sx={{ textAlign: "center", marginTop: "10px" }}>
                  <CompareArrowsIcon fontSize="large" />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <VendorSelectBox
                  name="vendorId2"
                  label="Vendor 2"
                  control={control}
                  errors={errors}
                  defaultOptions={{ id: null, label: "" }}
                  validationProps={{
                    required: REQUIRED_ERROR("Vendor 2"),
                  }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading ? true : false}
                onClick={handleSubmit(onSubmit)}>
                Compare Vendors
              </Button>
            </Box>
            <Fade
              in={isLoading}
              unmountOnExit
              sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <LinearProgress />
            </Fade>
            {isError ? (
              <Typography color={"error"}>
                {/* {error?.data?.messages} */}
              </Typography>
            ) : undefined}
          </form>
        </FormProvider>
      </React.Fragment>
    </>
  );
}

function VendorSelectBox({
  name,
  label,
  control,
  errors,
  defaultOptions,
  validationProps,
}) {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data, isLoading } = useGetVendorSelectBoxQuery(debouncedSearch, {
    skip: !debouncedSearch,
  });
  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );
  return (
    <BaseAutoComplete
      formSelectProps={{
        props: {
          name: `${name}`,
          id: `${name}`,
          label: `${label}`,
        },
        validationProps: validationProps,
      }}
      control={control}
      errors={errors}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      handleSearch={handleSearch}
    />
  );
}
