import React from "react";
import { useGetUnassignedFacilitiesQuery } from "../../../../../store/apis/DietitianAPis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";

export default function UnassignedCommunities() {
  const {
    data: unassignedCommunities,
    isLoading: isLoading,
    isError: isError,
  } = useGetUnassignedFacilitiesQuery();

  const UnassignedCommunitiesColumns = [
    {
      field: "facilityName",
      headerName: "Facility",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "corporateGroup",
      headerName: "Corporate Group",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      minWidth: 70,
    },
    {
      field: "regionName",
      headerName: "Region",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "contractHours",
      headerName: "Contract Hours",
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => params.value.toFixed(2),
    },
    {
      field: "contractMinimumPer",
      headerName: "Visit Schedule",
      flex: 1,
      minWidth: 90,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox childrenLocation={"end"}>
            <BaseButton
              text={"Assign"}
              To={`/admin/dietitian/communityDietitian?facilityId=${params.row.facilityId}`}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <BaseDataGrid
        loading={isLoading}
        rows={unassignedCommunities}
        getRowId={(row) => row.facilityId}
        error={isError}
        columns={UnassignedCommunitiesColumns}
        autoHeight={false}
        height={{ md: "58vh", xl: "60vh" }}
      />
    </>
  );
}
