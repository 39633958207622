import { Grid, Typography, Button, Box } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useLocation } from "react-router-dom";
import BaseAutoComplete from "../../../shared/baseForm/BaseAutoComplete";
import { useGetFacilityByUserFacilitiesQuery } from "../../../../store/apis/FacilityApis";
import { debounce } from "lodash";
import {
  useGetMenuFacilitiesQuery,
  useShareMenuMutation,
} from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../../shared/baseContent/BaseContent";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseActionBox from "components/shared/BaseActionBox";

export default function ShareMenu() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("menuId");
  const location = useLocation();

  const title = location?.state?.title;

  const [facilityDebouncedSearch, setFacilityDebouncedSearch] = useState();

  const {
    data: facilityDropdown,
    isFetching: facilityLoading,
    isError: facilitiesError,
  } = useGetFacilityByUserFacilitiesQuery({
    searchTerm: facilityDebouncedSearch || "",
  });

  const {
    data: menuFacilities,
    isFetching: menuFacilityLoading,
    isError: menuFacilityError,
  } = useGetMenuFacilitiesQuery(id);

  const [shareMenu, { isError: isShareMenuError, error: shareMenuError }] =
    useShareMenuMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const handleFacilitySearch = useMemo(
    () =>
      debounce((query) => {
        setFacilityDebouncedSearch(query);
      }, 400),
    []
  );

  function onSubmit(data) {
    data.menuId = id;
    shareMenu(data);
  }

  const menuFacilitiesColumns = [
    {
      field: "facility",
      minWidth: 100,
      flex: 3,
      headerName: "Name",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={menuFacilities}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.facility,
              apiPath: "deleteMenuFacility",
              title: "Menu Facility",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <BaseContent
        headerText={`Share ${title} Menu`}
        backLink={"/plateful/Menus"}
        disableHeight={true}>
        <Box height={{ md: "82vh", xl: "87vh" }}>
          <Grid
            marginBottom={"15px"}
            sx={{ alignItems: "center" }}
            container
            spacing={2}>
            <Grid item md={4}>
              <BaseAutoComplete
                options={facilityDropdown || []}
                loading={facilityLoading}
                defaultOptions={[]}
                size={"small"}
                control={control}
                handleSearch={handleFacilitySearch}
                formSelectProps={{
                  props: {
                    label: "Facility",
                    id: "facilityId",
                    name: "facilityId",
                  },
                  validationProps: {
                    required: REQUIRED_ERROR("Facility"),
                  },
                }}
                errors={errors}
                loadingError={facilitiesError}
              />
              {isShareMenuError ? (
                <Typography color={"error"}>
                  {shareMenuError?.data?.errors?.facilityId[0]}
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item md={2}>
              <Button
                color={"primary"}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#55c2c5",
                  border: "2px solid #55c2c5",
                  width: "48%",
                }}
                variant="contained"
                onClick={handleSubmit(onSubmit)}>
                Share
              </Button>
            </Grid>
            <Grid item md={6} />
          </Grid>
          <BaseDataGrid
            rows={menuFacilities}
            loading={menuFacilityLoading}
            columns={menuFacilitiesColumns}
            error={menuFacilityError}
            autoHeight={false}
            height={{ md: "65vh", xl: "70vh" }}
          />
        </Box>
      </BaseContent>
    </>
  );
}
