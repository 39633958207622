import { Box } from "@mui/material";
import React from "react";
import BaseContent from "../../../../shared/baseContent/BaseContent";
import { OptionsDetails } from "../../../../shared/OptionsDetails";
import { OptionsList } from "./headerConstant";

export const ConsultantVisitDetails = () => {
  return (
    <BaseContent
      disableBack={true}
      disableHeight={true}
      headerText={"Consultant"}
      transparent={true}>
      <Box marginTop={"-32px"} marginLeft={"-15px"}>
        <OptionsDetails data={OptionsList} button={true} />
      </Box>
    </BaseContent>
  );
};
