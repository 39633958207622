import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { OptionsDetails } from "../shared/OptionsDetails";
import { OptionsList } from "../consultant/headerDetails/headerConstant";
import BaseContent from "../shared/baseContent/BaseContent";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export const Consultant = () => {
  const location = useLocation();
  const match = matchPath(location.pathname, "/consultant");
  const navigate = useNavigate();

  useEffect(() => {
    if (match) navigate(OptionsList[0]?.Link);
  }, [match]);

  return (
    <BaseContent
      disableBack={true}
      disableHeight={true}
      headerText={"Consultant"}
      transparent={true}>
      <Box marginTop={"-32px"} marginLeft={"-15px"}>
        <OptionsDetails data={OptionsList} />
      </Box>
    </BaseContent>
  );
};
