import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useGetResidentMealsByDayQuery } from "store/apis/ResidentAllergyDislikeApis";
import CloseIcon from "@mui/icons-material/Close";
import { EditSkeleton } from "components/admin/admin.overlays";
import { Grid, Typography } from "@mui/material";

// Modal Styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 1,
  width: "50%",
  height: "60%",
};

function SubstitutionModal({ open, onClose, menuDayId, menuId, residentId }) {
  // Menu for Day
  const {
    data: menuForDay,
    isLoading: menuForDayLoading,
    // isError: menuForDayError,
  } = useGetResidentMealsByDayQuery(
    { menuDayId, menuId, residentId },
    {
      skip: !menuDayId || !menuId || !residentId,
    }
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        {menuForDayLoading ? (
          <EditSkeleton />
        ) : (
          <>
            <Box>
              <CloseIcon
                sx={{
                  float: "right",
                  border: "1px solid",
                  borderRadius: "50%",
                  borderColor: "primary.main",
                  padding: "5px",
                  margin: "5px",
                  cursor: "pointer",
                }}
                onClick={onClose}
              />
            </Box>
            <Box>
              <Typography variant="h5" fontWeight="bold" paddingX="10px">
                Day {menuDayId}
              </Typography>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  textAlign="center">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      borderColor: "primary.main",
                      padding: "10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      bgcolor: "primary.main",
                      color: "white !important",
                    }}>
                    Breakfast
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: {
                        sm: "40vh",
                        md: "40vh",
                        lg: "37vh",
                        xl: "45vh",
                      },
                      overflow: "auto",
                      marginTop: "5px",
                      gap: 0.5,
                    }}>
                    {menuForDay
                      ?.filter((item) => item.mealTime == "Breakfast")
                      ?.map((item, index) => (
                        <Typography
                          variant="h6"
                          key={index}
                          sx={{
                            border: "1px solid",
                            borderColor: "black",
                            padding: "3px",
                            borderRadius: "5px",
                            fontSize: "17px",
                          }}>
                          {item.mealName}
                        </Typography>
                      ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  textAlign="center">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      borderColor: "primary.main",
                      padding: "10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      bgcolor: "primary.main",
                      color: "white !important",
                    }}>
                    Lunch
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: {
                        sm: "40vh",
                        md: "40vh",
                        lg: "37vh",
                        xl: "45vh",
                      },
                      overflow: "auto",
                      marginTop: "5px",
                      gap: 0.5,
                    }}>
                    {menuForDay
                      ?.filter((item) => item.mealTime == "Lunch")
                      ?.map((item, index) => (
                        <Typography
                          variant="h6"
                          key={index}
                          sx={{
                            border: "1px solid",
                            borderColor: "black",
                            padding: "3px",
                            borderRadius: "5px",
                            fontSize: "17px",
                          }}>
                          {item.mealName}
                        </Typography>
                      ))}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  textAlign="center">
                  <Typography
                    variant="h6"
                    sx={{
                      borderColor: "primary.main",
                      padding: "10px",
                      borderRadius: "5px",
                      fontWeight: "bold",
                      bgcolor: "primary.main",
                      color: "white !important",
                    }}>
                    Dinner
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      height: {
                        sm: "40vh",
                        md: "40vh",
                        lg: "37vh",
                        xl: "45vh",
                      },
                      overflow: "auto",
                      marginTop: "5px",
                      gap: 0.5,
                    }}>
                    {menuForDay
                      ?.filter((item) => item.mealTime == "Dinner")
                      ?.map((item, index) => (
                        <Typography
                          variant="h6"
                          key={index}
                          sx={{
                            border: "1px solid",
                            borderColor: "black",
                            padding: "3px",
                            borderRadius: "5px",
                            fontSize: "17px",
                          }}>
                          {item.mealName}
                        </Typography>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default SubstitutionModal;
