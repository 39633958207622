import React, { useEffect } from "react";
import { Checkbox, TextField } from "@mui/material";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";
import "./BaseCellEditable.css";
import { useGridApiContext } from "@mui/x-data-grid";

export default function renderBaseEditCell(params) {
  return <BaseCellEditable {...params} />;
}

export function renderBaseCell(params) {
  return <BaseCell {...params} />;
}

function BaseCell(params) {
  const { value, colDef, hasFocus } = params;
  const apiRef = useGridApiContext();

  // Effect to start edit mode when the cell receives focus
  useEffect(() => {
    setTimeout(() => {
      if (hasFocus) {
        if (apiRef.current.getRowMode(params.id) === "view") {
          apiRef.current.startRowEditMode({
            id: params.id,
            field: params.field,
          });
        }
      }
    }, 100);
  }, [hasFocus, params.id, params.field, apiRef]);

  switch (colDef?.type) {
    case EDIT_CELL_TYPES.BOOLEAN:
      return <Checkbox checked={Boolean(value)} disabled />;

    case EDIT_CELL_TYPES.STRING:
      if (colDef?.multiline) {
        return <TextField multiline value={value || ""} readOnly />;
      }
      return <div>{value}</div>;
    default:
      return <div>{value}</div>;
  }
}

export function BaseCellEditable(props) {
  const { id, value, field, tabIndex, hasFocus, colDef } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();

  const handleChange = (event) => {
    if (colDef?.checkField) {
      apiRef.current.setEditCellValue({
        id,
        field: colDef?.checkField,
        value: true,
      });
    }
    switch (colDef?.type) {
      case EDIT_CELL_TYPES.BOOLEAN:
        apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.checked,
        });
        // apiRef.current.stopRowEditMode({ id, field });

        return;
      case EDIT_CELL_TYPES.NUMBER:
        if (isNaN(event.target.value)) return;
        if (
          colDef?.min !== undefined &&
          colDef?.min !== null &&
          event.target.value < colDef?.min
        ) {
          const input = ref.current.querySelector(
            `input[type='${EDIT_HTML_TYPES[colDef?.type] ?? "text"}']`
          );
          input.style.backgroundColor = "var(--redColor)";
          setTimeout(() => {
            input.style.backgroundColor = "";
          }, 1000);
          return;
        }
        apiRef.current.setEditCellValue({
          id,
          field,
          value: parseInt(event.target.value),
        });
        break;
      default:
        apiRef.current.setEditCellValue({
          id,
          field,
          value: event.target.value,
        });
        break;
    }
  };

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input = colDef?.multiline
        ? ref.current.querySelector("textarea")
        : ref.current.querySelector(
            `input[type='${EDIT_HTML_TYPES[colDef?.type] ?? "text"}']`
          );
      input?.focus();
    }
  }, [hasFocus, value, field]);

  const handleFocus = (event) => {
    event.target.select();
    if (colDef?.type === EDIT_CELL_TYPES.BOOLEAN) {
      event.target.checked = !value;
      apiRef.current.setEditCellValue({
        id,
        field,
        value: !value,
      });
    }
  };

  switch (colDef?.type) {
    case EDIT_CELL_TYPES.BOOLEAN:
      return (
        <Checkbox
          checked={Boolean(value)}
          ref={ref}
          onChange={handleChange}
          onFocus={handleFocus}
          tabIndex={tabIndex}
        />
      );
    default:
      return (
        <TextField
          type={colDef?.type}
          InputProps={{ inputProps: { min: colDef?.min } }}
          value={value || ""}
          min={colDef?.min}
          ref={ref}
          multiline={colDef?.multiline}
          onChange={handleChange}
          rows={colDef?.rows}
          onFocus={handleFocus}
          tabIndex={tabIndex}
        />
      );
  }
}

export const EDIT_CELL_TYPES = {
  NUMBER: "number",
  BOOLEAN: "boolean",
  DATE: "date",
  DATETIME: "dateTime",
  STRING: "string",
  SINGLE_SELECT: "singleSelect",
  ACTIONS: "actions",
};

export const EDIT_HTML_TYPES = {
  string: "text",
  boolean: "checkbox",
  number: "number",
};
