import React, { useEffect, useState } from "react";
import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableByIdQuery,
  useAddDiningAreaTableMutation,
  useUpdateDiningAreaTableMutation,
} from "../../../../store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Typography, Card } from "@mui/material";
import BaseInput from "../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../admin/admin.overlays";
import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";

export default function AddDiningAreaTables() {
  const [addTable, { isError: addError, isLoading: addLoading }] =
    useAddDiningAreaTableMutation();

  const [updateTable, { isError: updateError, isLoading: updateLoading }] =
    useUpdateDiningAreaTableMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const EditId = searchParams.get("id");
  const diningAreaId = searchParams.get("diningAreaId");

  const isEdit = !!EditId;

  const {
    data: diningTable,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetDiningAreaTableByIdQuery(EditId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && diningTable) {
      reset(diningTable);
    }
  }, [diningTable]);

  const onSubmit = (data) => {
    data.diningAreaId = diningAreaId;

    if (isEdit) {
      updateTable(data);
    } else {
      data.id = 0;
      addTable(data);
    }
    if (!isError) {
      navigate(-1);
    }
  };

  const [DiningAreaName, setDiningAreaName] = useState();
  const {
    data: diningArea,
    isFetching: currentDAFetching,
    isSuccess: currentDASuccess,
  } = useGetDiningAreaByIdQuery(diningAreaId);

  useEffect(() => {
    if (currentDASuccess && !currentDAFetching && diningArea) {
      setDiningAreaName(diningArea.name);
    }
  }, [diningArea]);

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Card>
            <Typography variant="h6" component="h2" margin={"10px"}>
              {`Table for  ${DiningAreaName}`}
            </Typography>

            <Box margin={"20px"}>
              <FormProvider>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <BaseInput
                        label={"Table Name"}
                        name={"name"}
                        placeholder="Table Name"
                        control={control}
                        errors={errors}
                        validationProps={{
                          required: REQUIRED_ERROR("Table Name"),
                          maxLength: MAX_LENGTH_VALIDATION("Table Name", 20),
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") e.preventDefault();
                        }}
                      />
                    </Grid>

                    <Grid item xs={3} />
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <BaseSubmitButton
                          fullWidth
                          onClick={handleSubmit(onSubmit)}
                          isSubmitting={isSubmitting}
                          text={isEdit ? "Submit" : "Create"}
                        />
                      </Box>
                      {isError ? (
                        <Typography color={"error"}>
                          There was an error submitting the form, please try
                          again.
                        </Typography>
                      ) : undefined}
                    </Grid>
                    <Grid item xs={3} />
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          </Card>
        </>
      )}
    </>
  );
}
