import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import {
  useAddFacilityEHRMutation,
  useFacilityEHRByIdQuery,
  useUpdateFacilityEHRMutation,
} from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import {
  INVALID_PERSON_NAME_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";

export default function AddCommunityEHR() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const ehrId = searchParams.get("ehrId");

  const isEdit = !!ehrId;

  const {
    data: facilityEHR,
    isFetching: isFetchingEHR,
    isSuccess: isSuccessEHR,
  } = useFacilityEHRByIdQuery(ehrId, {
    skip: !isEdit,
  });

  const [
    addFacilityEHR,
    { isSuccess: isAddSuccess, isSubmitting: isAddSubmitting },
  ] = useAddFacilityEHRMutation();
  const [
    updateFacilityEHR,
    { isSuccess: isUpdateSuccess, isSubmitting: isUpdateSubmitting },
  ] = useUpdateFacilityEHRMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (facilityEHR && !isFetchingEHR && isSuccessEHR) {
      reset(facilityEHR);
    }
  }, [facilityEHR, isFetchingEHR, isSuccessEHR]);

  const onSubmit = (data) => {
    data.facilityId = id;

    if (isEdit) {
      data.id = ehrId;
      updateFacilityEHR(data);
      return;
    }
    addFacilityEHR(data);
  };

  const isEditLoading = isEdit && isFetchingEHR;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Box>
          <BaseButton
            text="Back"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />
          <Grid container spacing={2} padding="10px">
            <Grid item xs={12} sm={6}>
              <BaseInput
                name="ehridentifier"
                control={control}
                label="EHR Identifier"
                id="ehridentifier"
                validationProps={{
                  required: REQUIRED_ERROR("EHR Identifier"),
                  pattern: INVALID_PERSON_NAME_VALIDATION("EHR Identifier"),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item sm={6} />
            <Grid item xs={12} sm={6}>
              <BaseInput
                name="alternateEhridentifier"
                control={control}
                label="Alt EHR Identifier"
                id="alternateEhridentifier"
                validationProps={{
                  pattern: INVALID_PERSON_NAME_VALIDATION("Alt EHR Identifier"),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item sm={6} />
            <Grid item md={6}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
