import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import styles from "../Resource.module.css";
import { GuidelineManualList } from "../constants";
import { DropdownUI } from "../dropdownUI/DropdownUI";

export const GuidelineManual = () => {
  const [isActiveId, setIsActiveId] = useState("");
  return (
    <Box padding="8px">
      <Box
        display="Flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="10px"
        marginBottom="10px"
        width="100%">
        <Typography variant="h6" component="h4" className={styles.title}>
          Guidelines and Procedures
        </Typography>

        <Button
          className={styles.downloadBtn}
          variant="contained"
          endIcon={<span className="material-icons">download</span>}
          href={
            "https://healthtechdocs.blob.core.windows.net/2020guidelineproceduremanual/2020%20TOC.pdf"
          }
          target={"_blank"}>
          {"Table of Content"}
        </Button>
      </Box>

      {GuidelineManualList?.map((item, index) => {
        return (
          <DropdownUI
            key={index}
            item={item}
            index={index}
            isActiveId={isActiveId}
            setIsActiveId={setIsActiveId}
            isDietScreen={false}
          />
        );
      })}
    </Box>
  );
};
