import { Grid, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GeneratedForm from "../../../shared/generatedForm/GeneratedForm";
import { MenusFields } from "./form.constants";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { useForm } from "react-hook-form";
import { EditSkeleton } from "../../../admin/admin.overlays";
import {
  useAddMenuMutation,
  useGetMenuByIdQuery,
  useUpdateMenuMutation,
} from "../../../../store/apis/MenuApis";
import BaseContent from "../../../shared/baseContent/BaseContent";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import { Roles } from "../../../shared/constants";
import useMsalAccount from "utils/useMsalAccount";

const AddMenus = () => {
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState([]);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("menuId");
  const facilityId = useSelector(selectFacilityId);

  function roleCheck(roles) {
    const { roles: userRoles } = useMsalAccount();

    const isInRole = userRoles.some((roleToCheck) =>
      roles.includes(roleToCheck)
    );
    return isInRole;
  }

  const [queries] = useState([
    {
      name: "seasonId",
      query: { showArchived: roleCheck([Roles.Admin]) },
    },
  ]);

  const {
    data: MenuData,
    isLoading: currentMenuDataLoading,
    isSuccess: currentMenuDataSuccess,
  } = useGetMenuByIdQuery(id, { skip: !id });

  const [
    updateMenu,
    { isLoading: updateMenuDataLoading, isSuccess: updateMenuDataSuccess },
  ] = useUpdateMenuMutation();
  const [
    AddMenu,
    { isLoading: addMenuDataLoading, isSuccess: addMenuDataSuccess },
  ] = useAddMenuMutation();
  const isEdit = !!id;
  const isEditLoading = isEdit && currentMenuDataLoading;
  const isSubmitting = addMenuDataLoading || updateMenuDataLoading;
  const isSuccess = addMenuDataSuccess || updateMenuDataSuccess;
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentMenuDataSuccess && !currentMenuDataLoading) {
      reset(MenuData);
      setDefaultValues([...defaultValues]);
    }
  }, [MenuData, currentMenuDataLoading, currentMenuDataSuccess]);
  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);
  const onSubmit = (data) => {
    if (isEdit) {
      updateMenu(data);
      return;
    }
    data.currentFacilityId = facilityId;
    AddMenu(data);
  };
  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <BaseContent
          headerText={"Add New Menu"}
          backLink={"/plateFul/Menus"}
          disableHeight={true}>
          <Box height={{ md: "78vh", xl: "82vh" }}>
            <Box margin={"20px"}>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <GeneratedForm
                  list={MenusFields}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  defaultOptions={defaultValues}
                  queries={queries}
                />
              </Grid>

              <BaseSubmitButton
                onClick={handleSubmit(onSubmit)}
                sx={{ marginTop: "20px" }}
                isSubmitting={isSubmitting}
                text={isEdit ? "Save" : "Create"}
              />
            </Box>
          </Box>
        </BaseContent>
      )}
    </>
  );
};

export default AddMenus;
