import { Box, Card, Grid, Button, Link } from "@mui/material";
import React, { useEffect } from "react";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetRecipeFoodTypeByIdQuery,
  useAddRecipeFoodTypeMutation,
  useUpdateRecipeFoodTypeMutation,
} from "../../../../../../store/apis/RecipeFoodTypeAPIs";

import { useGetFoodTypesSelectListQuery } from "../../../../../../store/apis/FoodTypesApis";

import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin/admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function AddDislikesIntolerances() {
  const [searchParams] = useSearchParams();

  const recipeId = searchParams.get("recipeId");
  const id = searchParams.get("id");

  // console.log(recipeId);

  const navigate = useNavigate();

  const [newFoodTypes, setNewFoodTypes] = React.useState([]);

  const {
    data: availableFoodTypes,
    isLoading: availableFoodTypesLoading,
    isSuccess: availableFoodTypesSuccess,
  } = useGetFoodTypesSelectListQuery();

  const {
    data: currentRecipeFoodType,
    isLoading: currentRecipeFoodTypeLoading,
    isSuccess: currentRecipeFoodTypeSuccess,
  } = useGetRecipeFoodTypeByIdQuery(id, { skip: !id });

  const [
    addRecipeFoodType,
    {
      isLoading: addRecipeFoodTypeSubmitting,
      isSuccess: addRecipeFoodTypeSuccess,
    },
  ] = useAddRecipeFoodTypeMutation();

  const [
    updateRecipeFoodType,
    {
      isLoading: updateRecipeFoodTypeSubmitting,
      isSuccess: updateRecipeFoodTypeSuccess,
    },
  ] = useUpdateRecipeFoodTypeMutation();

  const isSubmitting =
    addRecipeFoodTypeSubmitting || updateRecipeFoodTypeSubmitting;

  const isSuccess = addRecipeFoodTypeSuccess || updateRecipeFoodTypeSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const isEdit = !!id;

  const isEditLoading = isEdit && currentRecipeFoodTypeLoading;

  useEffect(() => {
    if (currentRecipeFoodTypeSuccess && !currentRecipeFoodTypeLoading) {
      setNewFoodTypes([
        ...newFoodTypes,
        {
          id: currentRecipeFoodType.foodTypeId,
          label: currentRecipeFoodType.name,
        },
      ]);
      reset(currentRecipeFoodType);
    }
  }, [
    currentRecipeFoodTypeSuccess,
    currentRecipeFoodType,
    currentRecipeFoodTypeLoading,
  ]);

  useEffect(() => {
    if (availableFoodTypes && !availableFoodTypesLoading) {
      setNewFoodTypes([...newFoodTypes, ...availableFoodTypes]);
    }
  }, [
    availableFoodTypesSuccess,
    availableFoodTypes,
    availableFoodTypesLoading,
  ]);

  // console.log(newFoodTypes);

  if (!isSubmitting && isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    data.recipeId = recipeId;
    data.isDislike = true;

    if (isEdit) {
      data["id"] = id;
      updateRecipeFoodType(data);
      return;
    } else {
      addRecipeFoodType(data);
    }
  };

  return (
    <>
      <Button
        component={Link}
        color={"primary"}
        className="baseButton"
        variant="contained"
        onClick={() => navigate(-1)}
        startIcon={<span className="material-icons">keyboard_arrow_left</span>}>
        {"Back"}
      </Button>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Card sx={{ minHeight: "70vh" }}>
          <Box padding={"10px"}>
            <Grid container spacing={2}>
              <Grid item md={12} />

              <Grid item md={6}>
                <BaseSelect
                  name="foodTypeId"
                  id="foodTypeId"
                  label="Food Type"
                  control={control}
                  validationProps={{
                    required: REQUIRED_ERROR("Food Type"),
                  }}
                  errors={errors}
                  options={newFoodTypes || []}
                  loading={availableFoodTypesLoading}
                />
              </Grid>

              <Grid item md={6} />

              <Grid item md={6}>
                <BaseSubmitButton
                  fullWidth
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Save" : "Create"}
                  onClick={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
    </>
  );
}
