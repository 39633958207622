import {
  INVALID_NAME_VALIDATION,
  INVALID_US_PHONE_VALIDATION,
  MIN_LENGTH_VALIDATION,
  REQUIRED_ERROR,
  INVALID_MULTIPLE_EMAIL_VALIDATION,
} from "utils/errorMessages";
import { zipCodeValidation } from "../../../../../../../utils/ValidationRegex";
import { StatesList } from "../../../../../../shared/states.constants";
import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";
import { TimeIntervalList } from "components/shared/timeintervals.constants";
import { DistributorsList } from "components/shared/distributors.constants";

export const informationFormFields = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Name",
    },
    validationProps: {
      required: REQUIRED_ERROR("Name"),
      pattern: INVALID_NAME_VALIDATION(),
      minLength: MIN_LENGTH_VALIDATION("Name", 4),
    },
    size: 6,
  },
  {
    props: {
      name: "address",
      id: "address",
      label: "Address",
    },
    validationProps: {
      required: REQUIRED_ERROR("Address"),
    },
    size: 12,
  },
  {
    props: {
      name: "address2",
      id: "address2",
      label: "Address 2",
    },
    size: 12,
  },
  {
    props: {
      name: "city",
      id: "city",
      label: "City",
    },
    validationProps: {
      required: REQUIRED_ERROR("City"),
    },
    size: 5,
  },
  {
    props: {
      name: "state",
      id: "state",
      label: "State",
    },
    validationProps: {
      required: REQUIRED_ERROR("State"),
    },
    size: 4,
    type: "select",
    options: StatesList,
  },
  {
    props: {
      name: "zip",
      id: "zip",
      label: "Zip",
    },
    validationProps: {
      required: REQUIRED_ERROR("Zip"),
      pattern: {
        value: zipCodeValidation,
        message: "Please enter a 5 or 9 digit zip code",
      },
    },
    size: 3,
  },
  {
    props: {
      name: "phoneNumber",
      id: "phoneNumber",
      label: "Phone Number",
    },
    validationProps: {
      required: REQUIRED_ERROR("Phone Number"),
      pattern: INVALID_US_PHONE_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "faxnumber",
      id: "faxnumber",
      label: "Fax Number",
    },
    validationProps: {
      pattern: INVALID_US_PHONE_VALIDATION("Fax Number"),
    },
    size: 6,
  },
  {
    props: {
      name: "timeZoneId",
      id: "timeZoneId",
      label: "Time Zone",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getTimeZoneSelectList",

    size: 6,
  },
  {
    props: {
      name: "regionId",
      id: "regionId",
      label: "Region",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRegionsSelectList",
    size: 6,
  },
  {
    props: {
      name: "corporateGroupId",
      id: "corporateGroupId",
      label: "Corporate Group",
    },
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getCorporateGroupSelect",

    size: 6,
  },
  {
    props: {
      name: "regionalManagerUserId",
      id: "regionalManagerUserId",
      label: "Regional Manager",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRegionalManagerUserIdSelectList",
    size: 6,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "clientSuccessUserId",
      id: "clientSuccessUserId",
      label: "Client Success Team Member",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getClientSuccessUserSelect",
    size: 6,
  },
  {
    props: {
      name: "vendorId",
      id: "vendorId",
      label: "Vendor",
    },
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getVendorSelectBox",
    size: 6,
  },
  {
    props: {
      name: "purchasingGroupId",
      id: "purchasingGroupId",
      label: "Purchasing Group",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getPurchasingGroupSelectList",
    size: 6,
  },
  {
    props: {
      name: "baseMenuId",
      id: "baseMenuId",
      label: "Base Menu",
    },
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getBaseMenusSelectBox",
    size: 6,
  },
  {
    props: {
      name: "encryptDietitianReports",
      id: "encryptDietitianReports",
      label: "Encrypt Consultant Reports",
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "perdiem",
      id: "perdiem",
      label: "Perdiem",
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "contractReturned",
      id: "contractReturned",
      label: "Contract Returned",
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];

export const DETAIL_FIELDS = [
  {
    props: {
      name: "contractHours",
      id: "contractHours",
      label: "Contract Hours",
      type: "number",
    },

    size: 3,
  },
  {
    props: {
      name: "contractMinimumPer",
      id: "contractMinimumPer",
      label: "Contract Minimum Per",
    },
    type: FORM_TYPES.Select,
    options: TimeIntervalList,
    size: 3,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "noMileage",
      id: "noMileage",
      label: "No Mileage",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      name: "dietitianMileageCap",
      id: "dietitianMileageCap",
      label: "Consultant Mileage Cap",
      type: "number",
      unit: "miles",
      unitPosition: "end",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Consultant mileage cap"),
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
      max: {
        value: 9999,
        message: "Value must be less than 10000.",
      },
    },
    size: 3,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "administrator",
      id: "administrator",
      label: "Administrator",
    },
    size: 6,
  },
  {
    props: {
      name: "administratorEmail",
      id: "administratorEmail",
      label: "Administrator Email",
    },
    validationProps: {
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "fssDsd",
      id: "fssDsd",
      label: "FSS/DSD Contact",
    },

    size: 6,
  },
  {
    props: {
      name: "fssDsdemail",
      id: "fssDsdemail",
      label: "FSS/DSD Contact Email",
      type: "email",
    },
    validationProps: {
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "billingContact",
      id: "billingContact",
      label: "Billing Contact",
    },

    size: 6,
  },
  {
    props: {
      name: "billingContactEmail",
      id: "billingContactEmail",
      label: "Billing Contact Email",
    },
    validationProps: {
      required: REQUIRED_ERROR("Billing Contact Email"),
      pattern: INVALID_MULTIPLE_EMAIL_VALIDATION(),
    },
    size: 6,
  },
  {
    props: {
      name: "billingPeriodId",
      id: "billingPeriodId",
      label: "Consulting Billing Period",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillingPeriodSelect",
    size: 6,
  },
  {
    props: {
      name: "subscriptionPeriodId",
      id: "subscriptionPeriodId",
      label: "S/W Subscription Period",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getSubscriptionPeriodSelect",

    size: 6,
  },

  {
    props: {
      name: "rateIncrease",
      id: "rateIncrease",
      label: "Consulting Rate Increase",
      type: "number",
      unit: "percent",
      unitPosition: "end",
      defaultValue: 0,
    },

    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
      max: {
        value: 100,
        message: "Value must be less than 100.",
      },
    },
    size: 3,
  },
  {
    props: {
      name: "autoRenewal",
      id: "autoRenewal",
      label: "Consulting Auto Renewal",
    },
    size: 3,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: "subscriptionBillingDate",
      id: "subscriptionBillingDate",
      label: "S/W Subscription Billing Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 3,
  },
  {
    props: {
      name: "nextSubscriptionBillingDate",
      id: "nextSubscriptionBillingDate",
      label: "S/W Subscription Next Billing Date",
    },
    type: FORM_TYPES.DatePicker,
    size: 3,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "subscriptionRateIncrease",
      id: "subscriptionRateIncrease",
      label: "S/W Subscription Rate Increase",
      type: "number",
      unit: "percent",
      unitPosition: "end",
      defaultValue: 0,
    },

    validationProps: {
      min: {
        value: 0,
        message: "Value must be greater than 0.",
      },
      max: {
        value: 100,
        message: "Value must be less than 100.",
      },
    },
    size: 3,
  },
  {
    props: {
      text: "",
    },
    size: 3,
    type: FORM_TYPES.Text,
  },
  //from here 19
  {
    props: {
      name: "consultingDiningRdgroupId",
      id: "consultingDiningRdgroupId",
      label: "Consulting Group",
    },
    validationProps: {
      required:
        "A selection must be made for one or both of the Consulting or Menu Group fields before updating is allowed.",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getDiningRDGroupSelectList",

    size: 6,
  },
  {
    props: {
      name: "consultingBillToTypeId",
      id: "consultingBillToTypeId",
      label: "Consulting Bill To",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillToTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "automaticRdreportEmail",
      id: "automaticRdreportEmail",
      label: "Automatic RD Report Email",
      type: "email",
    },
    size: 6,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "peachtreeCustomerId",
      id: "peachtreeCustomerId",
      label: "Sage Customer Id",
    },
    size: 6,
  },
  {
    props: {
      text: "Separate multiple email addresses with a comma.",
      fontSize: 12,
    },
    size: 12,
    type: FORM_TYPES.Text,
  },

  {
    props: {
      name: "comments",
      id: "comments",
      label: "Comments",
      multiline: true,
      rows: 4,
    },
    size: 12,
  },
  {
    props: {
      name: "photoOnTrayCard",
      id: "photoOnTrayCard",
      label: "Enable MealCard Photo",
      defaultValue: true,
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
  // from here 27
  {
    props: {
      name: "menuDiningRdgroupId",
      id: "menuDiningRdgroupId",
      label: "Menu Group",
    },
    validationProps: {
      required:
        "A selection must be made for one or both of the Consulting or Menu Group fields before updating is allowed.",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getDiningRDGroupSelectList",

    size: 6,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "menuBillToTypeId",
      id: "menuBillToTypeId",
      label: "Menu Bill To",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillToTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "menuCharge",
      id: "menuCharge",
      label: "Menu Charge (annual)",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Menu Charge"),
      min: {
        value: 0,
        message: "Value must be 0 or greater.",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "mealcardBillToTypeId",
      id: "mealcardBillToTypeId",
      label: "Mealcard Bill To",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillToTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "mealCardCharge",
      id: "mealCardCharge",
      label: "MealCard Charge (annual)",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("MealCard Charge"),
      min: {
        value: 0,
        message: "Value must be 0 or greater.",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "customizationBillToTypeId",
      id: "customizationBillToTypeId",
      label: "EMR Bill To",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillToTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "customizationCharge",
      id: "customizationCharge",
      label: "EMR Charge (annual)",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("EMR Charge"),
      min: {
        value: 0,
        message: "Value must be 0 or greater.",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "tableSideBillToTypeId",
      id: "tableSideBillToTypeId",
      label: "TableSide Bill To",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getBillToTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "tableSideCharge",
      id: "tableSideCharge",
      label: "TableSide Charge (annual)",
      type: "number",
      unit: "$",
      unitPosition: "start",
      defaultValue: 0,
    },
    validationProps: {
      required: REQUIRED_ERROR("Tableside Charge"),
      min: {
        value: 0,
        message: "Value must be 0 or greater.",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "medicalRecordTypeId",
      id: "medicalRecordTypeId",
      label: "Medical Record Type",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getMedicalRecordTypesSelectList",
    size: 6,
  },
  {
    props: {
      name: "softwareNotes",
      id: "softwareNotes",
      label: "Software Notes",
      multiline: true,
      rows: 4,
    },
    size: 12,
  },
  {
    props: {
      name: "menuNotes",
      id: "menuNotes",
      label: "Menu Notes",
      multiline: true,
      rows: 4,
    },
    size: 12,
  },
  {
    props: {
      name: "pbjidentifier",
      id: "pbjidentifier",
      label: "PBJ Identifier",
    },
    size: 6,
  },
  {
    props: {
      text: "",
    },
    size: 6,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "distributorId",
      id: "distributorId",
      label: "Distributor",
    },
    type: FORM_TYPES.Select,
    options: DistributorsList,
    size: 6,
  },
  {
    props: {
      text: "Selecting a Distributor and entering a Distributor Specific Identifier will turn on Order Transmission from the Menu screen.",
      fontSize: 12,
    },
    size: 12,
    type: FORM_TYPES.Text,
  },
  {
    props: {
      name: "distributorSpecificIdentifier",
      id: "distributorSpecificIdentifier",
      label: "Distributor Specific Identifier",
    },
    size: 6,
  },
  {
    props: {
      name: "distributorSpecificIdentifierAlt",
      id: "distributorSpecificIdentifierAlt",
      label: "Distributor Specific Identifier Alternate",
    },
    size: 6,
  },
  {
    props: {
      text: "Entering a Distributor Specific Identifier and selecting a Distributor will turn on Order Transmission from the Menu screen.",
      fontSize: 12,
    },
    size: 12,
    type: FORM_TYPES.Text,
  },

  {
    props: {
      name: "isTestFacility",
      id: "isTestFacility",
      label: "Is Test Facility",
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];
