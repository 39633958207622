import React, { useEffect } from "react";
import {
  useAddRecipeCategoryMutation,
  useGetRecipeCategoryByIdQuery,
  useUpdateRecipeCategoryMutation,
} from "../../../../../../store/apis/RecipeCategoriesApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";

import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../shared/BaseButton";
import { RECIPE_CATEGORY_FIELDS } from "./forms.constants";

export default function AddRecipeCategory() {
  const [addRecipeCategory, { isError: addError, isLoading: addLoading }] =
    useAddRecipeCategoryMutation();

  const [
    updateRecipeCategory,
    { isError: updateError, isLoading: updateLoading },
  ] = useUpdateRecipeCategoryMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentRecipeCategoryId = searchParams.get("id");

  const isEdit = !!currentRecipeCategoryId;

  const {
    data: currentRecipeCategory,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetRecipeCategoryByIdQuery(currentRecipeCategoryId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentRecipeCategory) {
      reset(currentRecipeCategory);
    }
  }, [currentRecipeCategory]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateRecipeCategory(data);
    } else {
      addRecipeCategory(data);
    }
    navigate(-1);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseButton
            text="Back To Recipe Categories"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />

          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <Box padding={"8px"}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}>
              <GeneratedForm
                control={control}
                list={RECIPE_CATEGORY_FIELDS}
                errors={errors}
              />

              <Grid item md={6}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                />
              </Grid>
              {isError ? (
                <Typography color={"error"}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
