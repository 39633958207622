import {
  Card,
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import LinearProgress from "@mui/material/LinearProgress";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import "../Reports.css";

export default function VendorReport() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetReportMutation();

  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const handleClick = (report, type) => {
    // console.log(report, type);

    const data = {
      name: report,
      parameters: {},
      format: type,
    };

    getReport(data);
  };

  return (
    <Card sx={{ flexGrow: 1, marginY: 2, paddingX: 2 }}>
      <Typography
        sx={{ marginTop: "15px" }}
        color="primary"
        variant="h5"
        className="userFacilityTitle">
        Select Vendor Report to View
      </Typography>

      <Fade
        in={isLoading}
        unmountOnExit
        sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <LinearProgress />
      </Fade>

      <nav aria-label="main mailbox folders">
        <List>
          <ListItem
            key={1}
            disablePadding
            onClick={() => handleClick("VendorPricingProblems", 1)}>
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Vendor Pricing Problems" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={2}
            disablePadding
            component={Link}
            to="IngredientRecipeMissingCost">
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Ingredients/Recipe Missing Cost" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={3}
            disablePadding
            component={Link}
            to="/admin/vendorreport/CompareVendorPricing">
            <ListItemButton>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Compare Vendor Pricing" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>

      {isError ? (
        <Typography color={"error"}>
          Error occured during report generation. Please try again later.
        </Typography>
      ) : undefined}
    </Card>
  );
}
