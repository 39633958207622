import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetNoteTypesQuery } from "../../../../../store/apis/GenericApis";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../shared/baseForm/BaseInput";

import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import {
  useAddResidentNoteMutation,
  useGetResidentNoteByIdQuery,
  useUpdateResidentNoteMutation,
} from "../../../../../store/apis/ResidentApis";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddResidentNotes() {
  const [searchParams] = useSearchParams();
  const [isDirty, setIsDirty] = useState(false);
  const navigate = useNavigate();

  const id = searchParams.get("noteId");
  const residentId = searchParams.get("id");

  const isEdit = !!id;

  const {
    data: currentNote,
    isFetching,
    isSuccess: isNoteSuccess,
  } = useGetResidentNoteByIdQuery(id, { skip: !id });

  const isEditLoading = isFetching && isEdit;

  const { data: noteTypes, isFetching: notesLoading } = useGetNoteTypesQuery();

  const [
    addResidentNote,
    { isLoading: addResidentNoteSubmitting, isSuccess: addResidentNoteSuccess },
  ] = useAddResidentNoteMutation();

  const [
    updateResidentNote,
    {
      isLoading: updateResidentNoteSubmitting,
      isSuccess: updateResidentNoteSuccess,
    },
  ] = useUpdateResidentNoteMutation();

  const isSubmitting =
    addResidentNoteSubmitting || updateResidentNoteSubmitting;
  const isSuccess = addResidentNoteSuccess || updateResidentNoteSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty: valuesChange },
  } = methods;
  useEffect(() => {
    isNoteSuccess && !isFetching ? reset(currentNote) : null;
  }, [isNoteSuccess, isFetching]);

  !isSubmitting && isSuccess ? navigate(-1) : null;

  function onSubmit(data) {
    setIsDirty(false);
    data["residentId"] = residentId;
    if (isEdit) {
      data["id"] = id;
      updateResidentNote(data);
      return;
    }
    addResidentNote(data);
  }
  useEffect(() => {
    setIsDirty(valuesChange);
  }, [valuesChange]);
  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal isDirty={isDirty} />
          <Box padding={"5px"} marginTop={"10px"}>
            <BackButton To={-1} />
            <Grid marginTop={"5px"} container spacing={2}>
              <Grid item md={4}>
                <BaseSelect
                  name="noteTypeId"
                  id="noteTypeId"
                  label="Note Type"
                  control={control}
                  errors={errors}
                  options={noteTypes || []}
                  loading={notesLoading}
                  validationProps={{
                    required: REQUIRED_ERROR("Note Type"),
                  }}
                />
              </Grid>
              <Grid item md={8} />
              <Grid item md={4}>
                <BaseInput
                  multiline
                  minRows={4}
                  name="note"
                  id="note"
                  label="Note"
                  validationProps={{
                    required: REQUIRED_ERROR("Note"),
                  }}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item md={8} />
              <Grid item md={4}>
                <BaseCheckBox
                  name="includeOnTrayCard"
                  id="includeOnTrayCard"
                  label="Include On MealCard"
                  control={control}
                  errors={errors}
                  defaultValue={true}
                />
              </Grid>
              <Grid item md={8} />
              <Grid item md={4}>
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
