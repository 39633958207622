import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Typography, Grid, Skeleton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useCopyVendorIngredientMutation } from "../../../../../../store/apis/IngredientAPIs";
import { useGetVendorSelectBoxQuery } from "../../../../../../store/apis/VendorApis";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "../../../../../shared/BaseButton";

function CopyVendorIngredient() {
  const [
    copyVendorIngredient,
    {
      data: copiedVendor,
      isError: isCopyError,
      isSuccess: copySuccess,
      isLoading: copyLoading,
      error: copyError,
    },
  ] = useCopyVendorIngredientMutation();

  const { data: vendorList, isFetching: vendorListLoading } =
    useGetVendorSelectBoxQuery("");

  const isError = isCopyError;
  const error = copyError;
  const isSuccess = copySuccess;
  const isSubmitting = copyLoading;
  const [searchParams] = useSearchParams();
  const currentVendorIngredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name");
  const vendorName = searchParams.get("vendor");

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Vendor ${vendorName} copied to ${copiedVendor?.data?.vendorName} successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.vendorIngredientId = Number(currentVendorIngredientId);
    copyVendorIngredient(data);
  };

  return (
    <>
      <BaseButton
        text="Back To Vendor Ingredients"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />

      <Typography color="primary" variant="h5" className="userFacilityTitle">
        Copy {ingredientName} from {vendorName}
      </Typography>

      <React.Fragment>
        <FormProvider>
          <form>
            <Grid container spacing={2} style={{ marginTop: "24px" }}>
              <Grid item xs={6}>
                {vendorListLoading ? (
                  <Skeleton />
                ) : (
                  <BaseSelect
                    label="Vendor"
                    name="newVendorId"
                    id="newVendorId"
                    control={control}
                    options={vendorList}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Vendor"),
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                text={"Submit"}
              />
            </Box>
            {isError ? (
              <Typography color={"error"}>{error?.data?.messages}</Typography>
            ) : undefined}
          </form>
        </FormProvider>
      </React.Fragment>
    </>
  );
}

export default CopyVendorIngredient;
