import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  Grid,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  useAddUnitConversionMutation,
  useUpdateUnitConversionMutation,
  useGetUnitConversionByIdQuery,
} from "../../../../../../store/apis/IngredientAPIs";
import { useGetUnitsQuery } from "../../../../../../store/apis/UnitApis";
import BaseInput from "../../../../../../components/shared/baseForm/BaseInput";
import BaseSelect from "../../../../../../components/shared/baseForm/BaseSelect";
import { EditSkeleton } from "../../../../admin.overlays";
import { BaseButton } from "../../../../../shared/BaseButton";

function AddUnitConversion() {
  const [
    addUnitConversion,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddUnitConversionMutation();
  const [
    updateUnitConversion,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdateUnitConversionMutation();

  const { data: unitList, isFetching: unitListLoading } = useGetUnitsQuery("");

  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentUnitConversionId = searchParams.get("id");
  const currentIngredientId = searchParams.get("ingredientId");

  const isEdit = !!currentUnitConversionId;

  const {
    data: currentUnitConversion,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetUnitConversionByIdQuery(currentUnitConversionId, { skip: !isEdit });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentUnitConversion) {
      reset(currentUnitConversion);
    }
  }, [currentUnitConversion]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Unit Conversion ${isEdit ? "updated" : "created"} successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateUnitConversion(data);
      return;
    }
    data.ingredientId = currentIngredientId;
    addUnitConversion(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseButton
            text="Back To Unit Conversions"
            startIcon={"keyboard_arrow_left"}
            onClick={() => navigate(-1)}
          />

          <React.Fragment>
            <FormProvider>
              <form>
                <Grid container spacing={2} style={{ marginTop: "24px" }}>
                  <Grid item xs={3}>
                    <BaseInput
                      label={"Quantity 1"}
                      name={"quantity"}
                      placeholder="Quantity 1"
                      type={"number"}
                      control={control}
                      errors={errors}
                    />
                    {errors && (
                      <FormHelperText error>
                        {errors?.quantity?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {unitListLoading ? (
                      <Skeleton />
                    ) : (
                      <BaseSelect
                        label="Unit 1"
                        name="unitId"
                        id="unitId"
                        control={control}
                        options={unitList}
                        errors={errors}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormHelperText> </FormHelperText>
                  </Grid>

                  <Grid item xs={3}>
                    <BaseInput
                      label={"Quantity 2"}
                      name={"convertToQuantity"}
                      placeholder="Quantity 2"
                      type={"number"}
                      control={control}
                      errors={errors}
                    />
                    {errors && (
                      <FormHelperText error>
                        {errors?.convertToQuantity?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {unitListLoading ? (
                      <Skeleton />
                    ) : (
                      <BaseSelect
                        label="Unit 2"
                        name="convertToUnitId"
                        id="convertToUnitId"
                        control={control}
                        options={unitList}
                        errors={errors}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormHelperText> </FormHelperText>
                  </Grid>
                </Grid>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  {isSubmitting ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled
                      onClick={handleSubmit(onSubmit)}>
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}>
                      Submit
                    </Button>
                  )}
                </Box>
                {isError ? (
                  <Typography color={"error"}>
                    {error?.data?.messages}
                  </Typography>
                ) : undefined}
              </form>
            </FormProvider>
          </React.Fragment>
        </>
      )}
    </>
  );
}

export default AddUnitConversion;
