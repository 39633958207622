import { positiveNumber } from "utils/ValidationRegex";
import { FORM_TYPES } from "../../../shared/generatedForm/GeneratedForm";

export const menuPlannerFields = [
  {
    props: {
      name: "name",
      id: "name",
      label: "Recipe Name/Number ",
      size: "small",
    },
    size: 11,
  },
  {
    props: {
      name: "category",
      id: "category",
      label: "Category",
      size: "small",
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: "getRecipeCategorySelectBox",
    size: 11,
  },
  {
    props: {
      name: "tags",
      id: "tags",
      label: "Tags",
      size: "small",
    },
    type: FORM_TYPES.MultiSelectAPI,
    endpoint: "getRecipeTagsMenuPlanner",
    size: 11,
  },
  // {
  //   props: {
  //     name: "corporateGroup",
  //     id: "corporateGroup",
  //     label: "Corporate Group",
  //     size: "small",
  //   },
  //   type: FORM_TYPES.SelectAPI,
  //   endpoint: "CorporateGroupAccessSelect",
  //   size: 11,
  // },
];

export const menuPlannerFieldCheckbox = [
  {
    props: {
      name: "htiCategory",
      id: "htiCategory",
      label: "HTI Categories/Recipes Only",
      size: "small",
      fontSize: "14px",
    },
    type: FORM_TYPES.Checkbox,
    size: 14,
  },
];

export const RecipesFields = [
  {
    props: {
      name: "menuCategoryId",
      id: "menuCategoryId",
      label: "Category ",
    },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getMenuCategoriesSelectBox",
  },
  {
    props: {
      name: "percentage",
      id: "percentage",
      label: "Percentage ",
    },
    size: 6,
  },
  {
    props: {
      name: "guestPrice",
      id: "guestPrice",
      label: "Guest Price",
      type: "number",
      min: 0,
    },
    validationProps: {
      pattern: {
        value: positiveNumber,
        message: "Please enter positive number",
      },
    },
    size: 6,
  },
  {
    props: {
      name: "maxNumberOfTablesideServings",
      id: "maxNumberOfTablesideServings",
      label: "Max Serving",
      type: "number",
    },
    size: 6,
  },
  {
    props: {
      name: "isSubstituteRecipe",
      id: "isSubstituteRecipe",
      label: "Dislike/Allergy Substitution",
    },
    type: "checkbox",
    size: 12,
  },
  {
    props: {
      name: "hideFromPrintedMenu",
      id: "hideFromPrintedMenu",
      label: "Hide from printed menu ",
    },
    type: "checkbox",
    size: 12,
  },
];
