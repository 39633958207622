import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { informationFormFields } from "./forms.constants";

import GeneratedForm from "components/shared/generatedForm/GeneratedForm";

export default function InfoFields({
  // isEdit,
  control,
  errors,
  defaultCorporateGroup,
  defaultVendor,
  defaultBaseMenu,
}) {
  const [defaultValues, setDefaultValues] = useState([]);
  useEffect(() => {
    setDefaultValues([
      {
        name: "corporateGroupId",
        option: [
          {
            id: defaultCorporateGroup[0]?.id,
            label: defaultCorporateGroup[0]?.label,
          },
        ],
      },
      {
        name: "vendorId",
        option: [
          {
            id: defaultVendor[0]?.id,
            label: defaultVendor[0]?.label,
          },
        ],
      },
      {
        name: "baseMenuId",
        option: [
          {
            id: defaultBaseMenu[0]?.id,
            label: defaultBaseMenu[0]?.label,
          },
        ],
      },
    ]);
  }, [defaultCorporateGroup, defaultVendor, defaultBaseMenu]);

  const [queries] = useState([
    {
      name: "clientSuccessUserId",
      query: { roleId: 3 },
    },
  ]);

  return (
    <Box paddingTop={"8px"}>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}>
        <GeneratedForm
          control={control}
          list={informationFormFields}
          errors={errors}
          queries={queries}
          defaultOptions={defaultValues}
        />
      </Grid>
    </Box>
  );
}
