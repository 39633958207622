import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetRecipeChangeHistoryQuery } from "../../../../../store/apis/PlateFulRecipesApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import utcPlugin from "dayjs/plugin/utc";

export default function ChangeHistory() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  dayjs.extend(utcPlugin);
  const {
    data: history,
    isError: hasError,
    isLoading: loading,
  } = useGetRecipeChangeHistoryQuery({ recipeId: recipeId });

  const isError = hasError;
  const isLoading = loading;

  const HistoryColumns = [
    {
      field: "description",
      headerName: "Change Description",
      flex: 2,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      flex: 1,
      // valueFormatter: (params) => {
      //   if (null === params.value) {
      //     return "";
      //   } else {
      //     return new Date(params.value).toLocaleString();
      //   }
      // },
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs
              .utc(`${params?.value}Z`)
              .local()
              .format(`${SCREEN_CONSTANTS.Date_Format} h:mm:ss A`)
          : "--";
      },
    },
    {
      field: "updatedBy",
      headerName: "Updated By",
      flex: 1,
    },
  ];

  return (
    <>
      <>
        <Box padding={1}>
          <BaseDataGrid
            autoHeight={false}
            rows={history}
            columns={HistoryColumns}
            loading={isLoading}
            error={isError}
            height={{ md: "62vh", lg: "65vh", xl: "68vh" }}
          />
        </Box>
      </>
    </>
  );
}
