import React, { useState } from "react";
import { useGetRecipeCategoryListQuery } from "../../../../../store/apis/RecipeCategoriesApis";
import { Box, MenuItem } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseMenu from "components/shared/BaseMenu";
import { PostAdd } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function RecipeCategories() {
  const SEARCH_PARAM = "search";

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const {
    data: userPagedData,
    isLoading: isLoading,
    isError: isError,
  } = useGetRecipeCategoryListQuery({
    searchText: debouncedValue || "",
    refetchOnMountOrArgChange: true,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const recipeCategories = userPagedData?.items || [];

  const CategoryOptions = ({ index, id, name }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <BaseMenu index={index}>
          <MenuItem
            component={Link}
            to={`./CorporateGroupCategories?id=${id}`}
            disableRipple>
            Corporate Groups
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
            }}
            className="menuDeleteButton"
            disableRipple>
            Delete
          </MenuItem>
        </BaseMenu>

        {openModal === true ? (
          <DeleteModal
            id={id}
            entityName={name}
            apiPath={"deleteRecipeCategory"}
            open={openModal}
            close={() => {
              setOpenModal(false);
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const CategoryColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "adminOnly",
      headerName: "Admin Only",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.adminOnly} />;
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={recipeCategories}
            hasEdit={true}
            editProps={{
              title: "Recipe Category",
              To: `addRecipeCategory?id=${params.row.id}`,
              state: {
                title: "Edit Ingredient",
                data: recipeCategories.find((row) => row.id === params.row.id),
              },
            }}
            childrenLocation={"end"}>
            <CategoryOptions
              index={params.row.id}
              id={params.row.id || 0}
              name={params.row["name"] || ""}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"space-between"}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />

        <BaseButton
          text="Add Recipe Category"
          To="addRecipeCategory"
          endIcon={<PostAdd />}
        />
      </FlexBox>
      <Box>
        <BaseDataGrid
          rows={recipeCategories}
          rowCount={userPagedData?.totalCount || 0}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          columns={CategoryColumns}
          loading={isLoading}
          error={isError}
          autoHeight={false}
          height={{ md: "50dvh", xl: "55dvh" }}
        />
      </Box>
    </>
  );
}
