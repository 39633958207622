import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ResidentMenuElements } from "./residentmenu.constants";
import { useGetResidentDetailQuery } from "../../../../store/apis/ResidentApis";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import "./ResidentMenu.css";
import BaseContent from "../../../shared/baseContent/BaseContent";

export default function ResidentMenu() {
  const facilityId = useSelector(selectFacilityId);
  const facilityName = useSelector((state) => state.userInfo.facilityName);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [value, setValue] = useState(1);
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");
  const [menuItems, setMenuItems] = useState(ResidentMenuElements);
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const { state } = useLocation();
  const {
    data: residentDetails,
    isSuccess: residentSuccess,
    isError: residentIsError,
  } = useGetResidentDetailQuery(
    {
      residentId: residentId,
      facilityId: facilityId,
    },
    { skip: !residentId || !facilityId }
  );

  useEffect(() => {
    let currentLink;
    if (currentLocation) {
      currentLink = menuItems.find((x) =>
        currentLocation?.pathname.includes(x.Link)
      );
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      ResidentMenuElements.indexOf(
        ResidentMenuElements.find((res) =>
          currentLocation.pathname.includes(res.Link)
        )
      ) || 0;
    setValue(currentIndex !== -1 ? currentIndex : 0);
    setMenuItems(
      ResidentMenuElements.map((res) => {
        if (currentLocation.pathname.includes(res.Link)) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [currentLocation]);

  useEffect(() => {
    var setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      setMenuItems(ResidentMenuElements);
    } else if (!setActive) {
      setMenuItems(
        ResidentMenuElements.filter(
          (x) => x.Name !== featureMealExtra?.description
        )
      );
    }
  }, [currentLocation, featureMealExtra]);

  useEffect(() => {
    if (residentIsError) {
      navigate("/MealCard");
    }
  }, [residentIsError]);

  return (
    <>
      <BaseContent
        cardPadding={"0px !Important"}
        transparent={true}
        disableHeight={true}
        cardOverFlow={false}
        cardMarginTop={"2px"}
        backLink={"/MealCard"}
        backText={"Back to Resident List"}
        headerText={`${facilityName ? facilityName : ""}`}>
        <Box>
          <Tabs
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
            value={value}
            aria-label="User Menu nav tabs ">
            {menuItems.map((data, index) => {
              return (
                <LinkTab
                  key={index}
                  value={index}
                  id={residentId}
                  label={`${
                    data.Name === "Details" && residentSuccess
                      ? residentDetails?.resident?.nickname
                        ? `${residentDetails?.resident?.firstName} ${residentDetails?.resident?.lastName} (${residentDetails?.resident?.nickname})`
                        : `${residentDetails?.resident?.firstName} ${residentDetails?.resident?.lastName}`
                      : data.Name
                  }`}
                  href={`${data.Link}?id=${residentId}`}
                  isTitle={data.Name === "Details"}
                  link={data.Link}
                  navigate={navigate}
                  state={state}
                />
              );
            })}
          </Tabs>
          <Box
            height={{ md: "79vh", xl: "82vh" }}
            sx={{
              backgroundColor: "#fff",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingBottom: "5rem",
              paddingX: "5px",
            }}>
            <Outlet />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}

function LinkTab({ label, id, isTitle, link, navigate, state, ...rest }) {
  return (
    <Tab
      component="a"
      className={isTitle ? "residentDetailTab" : "mealCardTabs"}
      onClick={(event) => {
        event.preventDefault();
        navigate(
          {
            pathname: link,
            search: `?id=${id}`,
          },
          { state: { ...state } }
        );
      }}
      label={label}
      {...rest}
    />
  );
}
