import React from "react";
import _ from "lodash";
import { Link, useOutletContext } from "react-router-dom";
import { useGetCorporateGroupDocumentListQuery } from "../../store/apis/DocumentApi";
import corporateDocsImage from "../../assets/imgs/CorporateDocs.png";
import BaseContent from "../shared/baseContent/BaseContent";
import { Box } from "@mui/system";

export default function FrontPageCorpDocument() {
  const currentUserData = useOutletContext();

  const { data: corporateGroupDocumentList } =
    useGetCorporateGroupDocumentListQuery(currentUserData?.facilityId, {
      skip: !currentUserData,
    });

  function generateCorpGroupDocumentList() {
    if (corporateGroupDocumentList) {
      const groupedDocList = _.groupBy(corporateGroupDocumentList, "groupName");

      const arrayGroupedDocList = Object.entries(groupedDocList);

      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}>
          {arrayGroupedDocList.map((group) => (
            <div key={group[0]}>
              <div
                style={{
                  marginRight: "50px",
                  marginLeft: "10px",
                  color: "var(--tealColor)",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}>
                {group[0]}
              </div>
              {group[1].map((doc) => (
                <Link
                  key={doc.id}
                  to={doc.url}
                  target={"_blank"}
                  style={{
                    margin: "0px 10px",
                    color: "#8e8e8e",
                    fontWeight: "bold",
                  }}>
                  <div style={{ margin: "10px 10px 0px 10px" }} key={doc.id}>
                    {doc.name}
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      );
    }
  }

  return (
    <BaseContent disableHeight={true}>
      <Box height={{ md: "78vh", xl: "82vh" }}>
        <Box margin={"20px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              className="corpDocumentSectionButton"
              style={{
                backgroundImage: `url(${corporateDocsImage})`,
              }}>
              Corporate Documents
            </div>
            <div className="corpDocumentSection">
              <div className="sectionHeader"></div>
              {generateCorpGroupDocumentList()}
            </div>
          </div>
        </Box>
      </Box>
    </BaseContent>
  );
}
