import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BaseDataGrid from "../../../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useGetALISResidentListQuery,
  useUpdateALISResidentListMutation,
} from "../../../../../../../store/apis/ALISManagementApi";

export default function ALISImport() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const facilityName = searchParams.get("facilityName");
  const alisFacilityId = searchParams.get("ehridentifier").split("-")[1];

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const {
    data: residents,
    isFetching: fetchLoading,
    isError: fetchError,
    error: fetchErrorObj,
    refetch: refetchResidents,
  } = useGetALISResidentListQuery(
    {
      facilityId: facilityId,
      alisFacilityId: alisFacilityId,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [
    updateALISResidentList,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateErrorObj,
    },
  ] = useUpdateALISResidentListMutation();

  const isError = fetchError || updateError;
  // const isSuccess = fetchSuccess || updateSuccess;
  const isLoading = fetchLoading || updateLoading;
  const error = fetchErrorObj || updateErrorObj;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  const onSubmit = () => {
    // console.log(rowSelectionModel);

    var newArray = [];

    rowSelectionModel.forEach((row) => {
      var resident = residents.find(
        (resident) => resident.ahtPatientId === row
      );
      var newObject = JSON.parse(JSON.stringify(resident));
      newObject.isSelectedForImportUpdate = true;
      newArray.push(newObject);
    });

    // console.log(newArray);

    updateALISResidentList({
      facilityId: facilityId,
      alisFacilityId: alisFacilityId,
      data: newArray,
    });
  };

  useEffect(() => {
    if (updateSuccess) {
      // console.log(updatedResidents);
      refetchResidents();
      setRowSelectionModel([]);
    }
  }, [updateSuccess]);

  const residentsColumns = [
    {
      field: "alisFullName",
      headerName: "ALIS Resident Name",
      flex: 2,
      valueGetter: (params) => {
        return `${params.row.lastName}, ${params.row.firstName}`;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.lastName === null
              ? ""
              : `${params.row.lastName}, ${params.row.firstName}`}
          </>
        );
      },
    },
    {
      field: "htiFullName",
      headerName: "HTI Resident Name",
      flex: 2,
      valueGetter: (params) => {
        return `${params.row.localLastName}, ${params.row.localFirstName}`;
      },
      renderCell: (params) => {
        return (
          <>
            {params.row.localLastName === null
              ? ""
              : `${params.row.localLastName}, ${params.row.localFirstName}`}
          </>
        );
      },
    },
    {
      field: "ahtPatientId",
      headerName: "ALIS Patient ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dateOfBirth",
      headerName: "ALIS DOB",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleDateString();
        }
      },
    },
    {
      field: "localDateOfBirth",
      headerName: "HTI DOB",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleDateString();
        }
      },
    },
  ];

  return (
    <div className="container">
      <Typography variant="h6" component="h2" className="header">
        {`ALIS Resident List for ${facilityName}`}
      </Typography>
      <div className="page-body mealCard">
        <div className="Row">
          <Button
            color={"primary"}
            className="baseButton"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            endIcon={<span className="material-icons">check_box</span>}>
            Import/Update Selected Residents
          </Button>
        </div>

        <BaseDataGrid
          autoHeight={false}
          height={{ md: "37dvh", xl: "40dvh" }}
          rows={residents}
          loading={isLoading}
          columns={residentsColumns}
          error={isError}
          defaultPageSize={100}
          checkboxSelection
          getRowId={(row) => row.ahtPatientId}
          disableRowSelectionOnClick={false}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
        />

        <div className="Row">
          <Button
            color={"primary"}
            className="baseButton"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            endIcon={<span className="material-icons">check_box</span>}>
            Import/Update Selected Residents
          </Button>
        </div>

        <div className="Row">
          {isError && (
            <Typography color={"error"}>{error?.data?.messages}</Typography>
          )}
        </div>
      </div>
    </div>
  );
}
