import { Grid, Box } from "@mui/material";
import React from "react";

import GeneratedForm from "components/shared/generatedForm/GeneratedForm";

import { DETAIL_FIELDS } from "./forms.constants";

export default function DetailFields({ control, errors }) {
  return (
    <Box paddingTop={"8px"}>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}>
        <GeneratedForm control={control} list={DETAIL_FIELDS} errors={errors} />
      </Grid>
    </Box>
  );
}
