import { Box, Grid } from "@mui/material";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PCPFAX_FIELDS, pcpFax_ScreenNames } from "./constants";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { useForm } from "react-hook-form";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useCreateMDFAXMutation } from "store/apis/NutritionalCareAPIs";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";
import { DownloadLinkHelper } from "components/shared/utils/helpers";

const PcpFax = () => {
  // local States
  const [queries, setQueries] = useState([
    {
      name: "doctorId",
      skip: true,
    },
  ]);
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const reportId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");

  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId);
  const { facilityId } = visitData || [];

  // useForm Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // destructuring of Method
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  // APi Section
  const [createFax, { data, isLoading, isSuccess }] = useCreateMDFAXMutation();
  // OnSubmit Section
  const onSubmit = (data) => {
    const dataSent = {
      reportId: reportId,
      visitReportId: visitReportId,
      facilityId: facilityId,
      office: "",
      doctor: "",
      toFAXNumber: "",

      ...data,
    };
    createFax(dataSent);
  };

  // UseEffect Section
  useEffect(() => {
    if (isSuccess) {
      DownloadLinkHelper(data);
      reset({});
    }
  }, [data, isSuccess]);

  useEffect(() => {
    setQueries([
      ...queries.filter((x) => x.name != "doctorId"),
      {
        name: "doctorId",
        query: facilityId,
        skip: !facilityId,
      },
    ]);
  }, []);
  return (
    <>
      <Box padding={"8px"}>
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={pcpFax_ScreenNames}
        />
        <Box
          marginTop={"10px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <BaseButton
            marginLeft={"0px !important"}
            To={`/Consultant/MyRosters/VisitDetails/FacilityDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            text={"Manage Doctor"}
          />
          <BaseSubmitButton
            isSubmitting={isLoading}
            onClick={handleSubmit(onSubmit)}
            text="Create Fax"
          />
        </Box>
        <Grid marginTop={"10px"} width={"50%"} container spacing={2}>
          <GeneratedForm
            control={control}
            list={PCPFAX_FIELDS}
            queries={queries}
            errors={errors}
          />
        </Grid>
      </Box>
    </>
  );
};

export default PcpFax;
