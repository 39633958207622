import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
// import { numberRegex } from "utils/ValidationRegex";
import isEqual from "lodash/isEqual";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseInput.md)
 */
export default function BaseInput({
  validationProps,
  defaultValue,
  control,
  errors,
  unit,
  type,
  helperText,
  unitPosition,
  hideArrows = false,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleIncrement = (value, onChange) => {
    const newValue =
      value > 0 && value < 1
        ? 1
        : value < 0 && value > -1
        ? 0
        : isNaN(value)
        ? 1
        : parseFloat((Number(value) + 1).toPrecision(12));
    onChange({ target: { value: newValue.toString() } });
  };
  const handleDecrement = (value, onChange) => {
    const newValue =
      value < 0 && value > -1
        ? -1
        : value > 0 && value < 1
        ? 0
        : isNaN(value)
        ? -1
        : parseFloat((Number(value) - 1).toPrecision(12));

    onChange({ target: { value: newValue.toString() } });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getInputProps = (value, onChange) => ({
    endAdornment: (
      <>
        {type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
        {!hideArrows && isEqual(type, "number") && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <IconButton
              sx={{ padding: "0px", fontSize: "14px" }}
              aria-label="increment value"
              onClick={() => handleIncrement(value, onChange)}>
              <KeyboardArrowUp fontSize="20px" />
            </IconButton>
            <IconButton
              sx={{ padding: "0px", fontSize: "14px" }}
              size="10px"
              aria-label="decrement value"
              onClick={() => handleDecrement(value, onChange)}>
              <KeyboardArrowDown fontSize="20px" />
            </IconButton>
          </div>
        )}
        <InputAdornment position={unitPosition || "end"}>{unit}</InputAdornment>
      </>
    ),
    inputProps: rest?.denser
      ? {
          min: rest?.min,
          max: rest?.max,
          step: rest?.step,
          sx: {
            paddingY: 0,
          },
        }
      : { min: rest?.min, max: rest?.max, step: rest?.step },
  });

  const getInputLabelProps = () => (type === "time" ? { shrink: true } : null);

  return (
    <FormControl
      sx={{
        width: rest?.width || "100%",
      }}
      fullWidth={rest?.fullWidth || true}
      variant="outlined">
      {control ? (
        <Controller
          render={({ field: { onChange, value, ref } }) => (
            <TextField
              multiline={rest.multiline}
              rows={rest.rows}
              inputRef={ref}
              error={!!errors[rest?.name]}
              helperText={
                errors[rest?.name]?.message
                  ? errors[rest?.name]?.message
                  : helperText
              }
              type={
                type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : type === "number"
                  ? "text"
                  : type
              }
              value={
                (type === "date"
                  ? value?.toString().split("T")[0]
                  : type === "number"
                  ? value?.toString()
                  : value) || ""
              }
              onBlur={(event) => {
                let { value } = event.target;
                value = value.trim(); // Trim the value
                onChange(value);
              }}
              onChange={(event) => {
                let { value } = event.target;
                if (isEqual(type, "number")) {
                  value = value
                    .replace(/(?!^-)[^0-9.]/g, "")
                    .replace(/(?<=\..*)\./g, "");
                }
                onChange(value);
              }}
              autoComplete="new-password"
              {...rest}
              InputLabelProps={getInputLabelProps()}
              InputProps={getInputProps(value, onChange)}
            />
          )}
          control={control}
          name={rest?.name || rest?.id}
          defaultValue={defaultValue}
          rules={{
            ...validationProps,
            // validate: (value) => {
            //   if (
            //     isEqual(type, "number") &&
            //     !numberRegex.test(value) &&
            //     value
            //   ) {
            //     return "Invalid number format";
            //   }
            //   return true;
            //  },
          }}
        />
      ) : (
        <TextField
          multiline={rest.multiline}
          rows={rest.rows}
          InputLabelProps={getInputLabelProps()}
          fullWidth={rest?.fullWidth || true}
          type={
            type === "password"
              ? showPassword
                ? "text"
                : "password"
              : type === "number"
              ? "text"
              : type
          }
          {...rest}
        />
      )}
    </FormControl>
  );
}
