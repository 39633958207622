import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetRecipeIngredientDnDQuery,
  useReOrderRecipeIngredientsMutation,
} from "../../../../../../store/apis/RecipeIngredientApi";
import { useDrag, useDrop } from "react-dnd";
import { Box, Typography } from "@mui/material";
import { EditSkeleton } from "../../../../../admin/admin.overlays";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";

// React DND example from here.
// https://codesandbox.io/s/4vh2n?file=/src/Card.jsx

export default function RecipeIngredientsReOrder() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");

  const { data: RecipeIngredientData, isFetching: loading } =
    useGetRecipeIngredientDnDQuery(
      {
        id: recipeId,
      },
      { skip: !recipeId,
        refetchOnMountOrArgChange: true }
    );

  const [
    reOrder,
    { isLoading: isSubmitting, error: errorResponse, isSuccess: isSuccess },
  ] = useReOrderRecipeIngredientsMutation();

  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  const [cards, setCards] = useState(RecipeIngredientData || []);

  useEffect(() => {
    if (RecipeIngredientData && !loading) {
      setCards(RecipeIngredientData);
    }
  }, [RecipeIngredientData, loading]);

  if (isSuccess) {
    navigate(-1);
  }

  const onSubmit = (data) => {
    data = [];
    for (let i = 0; i < cards.length; i++) {
      data.push({ id: cards[i]?.id, sortOrder: i + 1 });
    }
    reOrder(data);
  };

  return (
    <>
      {loading ? (
        <EditSkeleton />
      ) : (
        <>
          <Box
            maxHeight={{ md: "76vh", xl: "80vh" }}
            overflow={"auto"}
            backgroundColor="white"
            border={"1px solid white"}>
            <Typography
              marginTop={"5px"}
              fontWeight={
                "700"
              }>{`Drag and drop the ingredients to the order that you want them, and click "Save"`}</Typography>
            <Container cards={cards} setCards={setCards} />
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              text={"Save"}
              isSubmitting={isSubmitting}
              validationSummary={errorResponse}
            />
          </Box>
        </>
      )}
    </>
  );
}

// TODO Make this generic
export const Container = ({ cards, setCards }) => {
  {
    const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = cards[dragIndex];
        const updatedCards = [...cards]; // Create a shallow copy of the cards array
        updatedCards.splice(dragIndex, 1); // Remove the dragged card from its original position
        updatedCards.splice(hoverIndex, 0, dragCard); // Insert the dragged card at the new position
        setCards(updatedCards);
      },
      [cards]
    );

    const renderCard = (card, index) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.name}
          moveCard={moveCard}
        />
      );
    };
    return (
      <>
        <Box width={"100%"}>
          {cards.length > 0 && cards.map((card, i) => renderCard(card, i))}
        </Box>
      </>
    );
  }
};

// TODOD: Make this generic
export const Card = ({ id, text, index, moveCard }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "Card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "Card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <Box
      border={isDragging ? "1px solid var(--tealColor)" : "1px solid gray"}
      padding={"0.5rem 1rem"}
      marginBottom={"0.5rem"}
      sx={{
        cursor: "move",
        boxShadow: isDragging ? "var(--tealBoxShadow)" : "",
      }}
      ref={ref}
      data-handler-id={handlerId}>
      <Typography>{text}</Typography>
    </Box>
  );
};
