import { Box, Typography } from "@mui/material";
import React from "react";
import "./EnterpriseInformation.css";
import { selectFacilityId } from "../../../../../../store/slices/authSlice/AuthSlice";
import { useGetFacilityByIdQuery } from "../../../../../../store/apis/FacilityApis";

import { useSelector } from "react-redux";
export const EnterpriseInformation = () => {
  const facilityId = useSelector(selectFacilityId);

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  function renderRow(name, value) {
    return (
      <div className="enterprise-row">
        <Typography variant="p" marginRight={2} className="title">
          {name}
        </Typography>
        <Typography variant="p">{value}</Typography>
      </div>
    );
  }
  return (
    <Box
      padding="10px"
      id="enterprise-information"
      display="Flex"
      width="100%"
      flexDirection="row"
      justifyContent="space-between">
      <Box className="enterprise-box">
        {renderRow("Corporate Group Name ", currentFacility?.corporateGroup)}
        {renderRow("Address ", currentFacility?.address)}
        {renderRow("City ", currentFacility?.city)}
        {renderRow("State ", currentFacility?.state)}
        {renderRow("Zip ", currentFacility?.zip)}
        {renderRow("Report Email ", currentFacility?.automaticRdreportEmail)}
        {renderRow("HTI Contact Person ", currentFacility?.administrator)}
      </Box>
    </Box>
  );
};
