import { Grid } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import React, { useEffect } from "react";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetCommunicationByIdQuery,
  useAddCommunicationMutation,
  useUpdateCommunicationMutation,
  useGetfacilityAssignedUsersListQuery,
} from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function AddCommunityCommunication() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const communicationId = searchParams.get("communicationId");
  const navigate = useNavigate();

  const [newFacilityUsers, setNewFacilityUsers] = React.useState([]);

  const {
    data: availableDiets,
    isLoading: availableDietsLoading,
    isSuccess: availableDietsSuccess,
  } = useGetfacilityAssignedUsersListQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const {
    data: Communication,
    isLoading: CommunicationLoading,
    isSuccess: CommunicationSuccess,
  } = useGetCommunicationByIdQuery(communicationId, { skip: !communicationId });

  const [
    addCommunity,
    { isLoading: addCommunicationLoading, isSuccess: addCommunicationSuccess },
  ] = useAddCommunicationMutation();
  const [
    updateCommunity,
    {
      isLoading: updateCommunicationLoading,
      isSuccess: updateCommunicationSuccess,
    },
  ] = useUpdateCommunicationMutation();

  const isSubmitting = addCommunicationLoading || updateCommunicationLoading;
  const isSuccess = addCommunicationSuccess || updateCommunicationSuccess;

  const communicationTypes = [
    { id: 1, label: "New Service/Product" },
    { id: 2, label: "Issue/Complaint" },
    { id: 3, label: "Technical Support" },
    { id: 4, label: "Other" },
  ];

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const OtherField = watch("clientContact");

  const isEdit = !!communicationId;

  const isEditLoading = isEdit && CommunicationLoading;

  useEffect(() => {
    if (CommunicationSuccess && !CommunicationLoading) {
      setNewFacilityUsers([
        ...newFacilityUsers,
        { id: Communication.clientContact, label: Communication.clientContact },
      ]);

      reset(Communication);

      if (!availableDiets?.some((x) => x.id == Communication.clientContact)) {
        setValue("clientContact", "Other");
        setValue("otherContact", Communication.clientContact);
      }
    }
  }, [CommunicationSuccess, Communication, CommunicationLoading]);

  useEffect(() => {
    const otherFacility = [{ id: "Other", label: "Other" }];

    if (availableDietsSuccess && !availableDietsLoading) {
      setNewFacilityUsers([
        ...newFacilityUsers,
        ...availableDiets,
        ...otherFacility,
      ]);
    }
  }, [availableDietsSuccess, availableDiets, availableDietsLoading]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    data.facilityId = id;

    if (!data.otherContact) {
      data["otherContact"] = "";
    }

    if (isEdit) {
      const dataToSend = new FormData();
      for (var property in data) {
        dataToSend.append(property, data[property]);
      }
      updateCommunity(dataToSend);
      return;
    }
    addCommunity(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={6}>
            <BaseDatePicker
              name="createdDate"
              id="createdDate"
              label="Date"
              validationProps={{
                required: REQUIRED_ERROR("Date"),
              }}
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={6}>
            <BaseSelect
              name="clientContact"
              id="clientContact"
              label="Client Contact"
              control={control}
              validationProps={{
                required: REQUIRED_ERROR("Client Contact"),
              }}
              errors={errors}
              options={newFacilityUsers || []}
              loading={availableDietsLoading}
            />
          </Grid>

          <Grid
            item
            md={6}
            display={OtherField == "Other" ? "block" : "none"}
          />
          <Grid item md={6} display={OtherField == "Other" ? "block" : "none"}>
            <BaseInput
              name="otherContact"
              id="otherContact"
              label="Other"
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={6}>
            <BaseSelect
              name="communicationType"
              id="communicationType"
              label="Communication Type"
              control={control}
              validationProps={{
                required: REQUIRED_ERROR("Communication Type"),
              }}
              errors={errors}
              options={communicationTypes || []}
            />
          </Grid>
          <Grid item md={6} />

          <Grid item md={6}>
            <BaseInput
              name="note"
              id="note"
              label="Note"
              validationProps={{
                required: REQUIRED_ERROR("Note"),
              }}
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={6}>
            <BaseSubmitButton
              fullWidth
              isSubmitting={isSubmitting}
              text={isEdit ? "Save" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
