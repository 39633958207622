import React from "react";
import { Typography, List, ListItem, ListItemText } from "@mui/material";
import _ from "lodash";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/ValidationErrorSummary.md)
 */
export default function ValidationErrorSummary({ errors, formErrors }) {
  let errorsResponse = errors?.errors;
  errorsResponse ??= errors?.data?.errors;
  errorsResponse ??= errors?.data?.data?.errors;
  errorsResponse ??= errors;

  const uniqueErrors = errorsResponse
    ? Object.values(errorsResponse).flat()
    : [];
  const hasErrors = errorsResponse
    ? Object.keys(errorsResponse).length > 0
    : false;

  return (
    <>
      {(hasErrors || !_.isEmpty(formErrors)) && (
        <Typography color={"error"}>
          There are errors in the form, please fix them before submitting.
        </Typography>
      )}

      {hasErrors && (
        <List dense disablePadding>
          {uniqueErrors.map((error, index) => (
            <ListItem dense disablePadding key={index}>
              <ListItemText
                primaryTypographyProps={{ color: "error" }}
                primary={error?.message || error}></ListItemText>
            </ListItem>
          ))}
        </List>
      )}

      {!_.isEmpty(formErrors) && (
        <List dense disablePadding>
          {Object.entries(formErrors).map(([key, error], index) => (
            <ListItem dense disablePadding key={key + index}>
              <ListItemText
                primaryTypographyProps={{ color: "error" }}
                primary={error.message}></ListItemText>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
}
