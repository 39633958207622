export const RecipeSetupElements = [
    {
        Name: "Diets",
        Link: "/admin/recipes/diets",
        IsActive: false,
    },
    {
        Name: "Ingredients",
        Link: "/admin/recipes/ingredients",
        IsActive: false,
    },
    // {
    //     Name: "Pureed Recipe Ingredients",
    //     Link: "/admin/recipes/recipeingredients",
    //     IsActive: false,
    // },
    {
        Name: "Recipe Categories",
        Link: "/admin/recipes/categories",
        IsActive: false,
    },
    // {
    //     Name: "Recipe Sharing Groups",
    //     Link: "/admin/recipes/sharinggroups",
    //     IsActive: false,
    // },
    {
        Name: "Standard Text",
        Link: "/admin/recipes/standardtext",
        IsActive: false,
    },
    {
        Name: "Tags",
        Link: "/admin/recipes/tags",
        IsActive: false,
    },
]