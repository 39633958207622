import React, { useEffect } from "react";
import BaseRichTextEditor from "../../../../../shared/baseForm/BaseRichTextEditor";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetStandardTextByIdQuery,
  useUpdateStandardTextMutation,
} from "../../../../../../store/apis/StandardTextApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function EditStandardText() {
  const [updateStandardText, { isError: isError, isLoading: isLoading }] =
    useUpdateStandardTextMutation();

  const [searchParams] = useSearchParams();
  const currentStandardTextId = searchParams.get("id");

  const {
    data: currentStandardText,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetStandardTextByIdQuery(currentStandardTextId);

  const navigate = useNavigate();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentStandardText) {
      reset(currentStandardText);
    }
  }, [currentStandardText]);

  const onSubmit = (data) => {
    updateStandardText(data);
    navigate(-1);
  };

  return (
    <>
      <BaseButton
        text="Back To Standard Text"
        startIcon={"keyboard_arrow_left"}
        onClick={() => navigate(-1)}
      />
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      {currentStandardText ? (
        <Typography color="primary" variant="h5" className="userFacilityTitle">
          Edit {currentStandardText.description}
        </Typography>
      ) : null}
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <FormProvider>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BaseRichTextEditor
                name="text"
                id="text"
                label="Text"
                control={control}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {isLoading ? (
              <Button
                variant="contained"
                color="primary"
                disabled
                onClick={handleSubmit(onSubmit)}>
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}>
                Submit
              </Button>
            )}
          </Box>
          {isError ? (
            <Typography color={"error"}>
              There was an error submitting the form, please try again.
            </Typography>
          ) : undefined}
        </form>
      </FormProvider>
    </>
  );
}
