import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Typography, Grid, Fade, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetDietitian1099ReportMutation } from "../../../../store/apis/ReportAPIs";
import { DownloadLinkHelper } from "../../../shared/utils/helpers";
import LinearProgress from "@mui/material/LinearProgress";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BaseButton } from "components/shared/BaseButton";

export default function ExportDietitian1099() {
  const [
    getReport,
    {
      isError: isError,
      isSuccess: isSuccess,
      isLoading: isLoading,
      data: report,
    },
  ] = useGetDietitian1099ReportMutation();

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;
  const startDate = watch("reportStartDate");
  const endDate = watch("reportEndDate");
  useEffect(() => {
    if (isSuccess) {
      if (report?.data !== "") {
        DownloadLinkHelper(report?.data);
      }
    }
  }, [report?.data]);

  useEffect(() => {
    if (
      dayjs(startDate).format("MM-DD-YYYY") <=
      dayjs(endDate).format("MM-DD-YYYY")
    ) {
      clearErrors("reportStartDate");
      clearErrors("reportEndDate");
    }
  }, [startDate, endDate]);
  const onSubmit = (data) => {
    getReport({
      ...data,
    });
  };

  return (
    <>
      <Box marginTop="10px">
        <BaseButton
          text="Back To Billing Reports"
          startIcon={"keyboard_arrow_left"}
          onClick={() => navigate(-1)}
        />
      </Box>

      <Box marginLeft="10px">
        <Typography
          sx={{ marginTop: "10px" }}
          color="primary"
          variant="h5"
          className="userFacilityTitle">
          Export Consultant 1099 Report
        </Typography>
      </Box>

      <React.Fragment>
        <FormProvider>
          <form>
            <Grid container spacing={2} style={{ marginTop: "24px" }}>
              <Grid item xs={6}>
                <BaseDatePicker
                  label={"Start Date"}
                  name={"reportStartDate"}
                  control={control}
                  placeholder=""
                  errors={errors}
                  validationProps={{
                    max: {
                      value: endDate ? new Date(endDate) : null,
                      message: "Start Date must be before End Date",
                    },
                    required: REQUIRED_ERROR("Start Date"),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <BaseDatePicker
                  label={"End Date"}
                  name={"reportEndDate"}
                  control={control}
                  errors={errors}
                  validationProps={{
                    min: {
                      value: startDate ? new Date(startDate) : null,
                      message: "End Date must be before Start Date",
                    },
                    required: REQUIRED_ERROR("End Date"),
                  }}
                />
              </Grid>
            </Grid>

            <Stack spacing={2} sx={{ marginTop: "10px" }}>
              {" "}
              {/* sx={{ display: "flex", flexDirection: "row", pt: 2 }} */}
              {isLoading ? (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    onClick={handleSubmit(onSubmit)}>
                    Export
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}>
                    Export
                  </Button>
                </div>
              )}
              <Fade
                in={isLoading}
                unmountOnExit
                sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <LinearProgress />
              </Fade>
              {isError ? (
                <Typography color={"error"}>
                  Error occured during report generation. Please try again
                  later.
                </Typography>
              ) : undefined}
            </Stack>
          </form>
        </FormProvider>
      </React.Fragment>
    </>
  );
}
