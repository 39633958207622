import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function DietInformationSection({
  residentId,
  resident,
  facilityDiets,
  facilityTextures,
  residentDetails,
}) {
  return (
    <Grid
      item
      xs={12}
      boxShadow="0px 4px 4px 0px #00000040"
      sx={{
        borderRadius: "4px",
      }}>
      <Box display="flex" flexDirection="column">
        <Box
          backgroundColor="#F8D061"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="8px 16px"
          marginLeft="4px !important"
          sx={{ borderRadius: "4px 4px 0px 0px" }}>
          <span style={{ fontSize: "22px", fontWeight: "700" }}>
            Diet Information
          </span>
          <Link
            to={`/MealCard/addResident?id=${residentId}&step=2`}
            className="newEditButton">
            <Typography component="span" fontWeight={700}>
              Edit
            </Typography>
          </Link>
        </Box>

        <Grid
          container
          spacing={1}
          sx={{ padding: "0.5rem 1rem" }}
          display="flex"
          justifyContent="space-between">
          <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Diet:
            </Typography>
            <Typography>
              {facilityDiets
                ? facilityDiets.find((diet) => diet.dietId === resident.dietId)
                    ?.dietName
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Diet Texture:
            </Typography>
            <Typography>
              {facilityTextures
                ? facilityTextures.find(
                    (texture) => texture.dietId === resident.dietTextureId
                  )?.dietName
                : ""}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Diet Other:
            </Typography>
            <Typography>{resident.dietOther}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Liquid Consistency:
            </Typography>
            <Typography>
              {residentDetails ? residentDetails.liquidConsistency : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ padding: "0rem 1rem 0rem 1rem" }}
          display="flex"
          justifyContent="space-between">
          <Grid item xs={6} sm={2.4} display="flex" gap="0.7rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              NPO:
            </Typography>
            <Typography>{resident.isNpo ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.7rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Tube Feeding:
            </Typography>
            <Typography>{resident.isTubeFeeding ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.7rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Fluid Restriction:
            </Typography>
            <Typography>{resident.hasFluidRestrict ? "Yes" : "No"}</Typography>
          </Grid>
          <Grid item xs={6} sm={2.4} display="flex" gap="0.7rem" flexGrow={1}>
            <Typography fontWeight={700} component="span">
              Fluid Restriction Quantity:
            </Typography>
            <Typography>{resident.restrictQty}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
