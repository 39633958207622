import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  useGetMenuBulkVendorProductsQuery,
  useUpdateMenuBulkVendorProductMutation,
} from "../../../../store/apis/MenuApis";
import "./MenuBulkProductEntry.css";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useGetUnitsQuery } from "../../../../store/apis/UnitApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../../shared/baseContent/BaseContent";
import BaseSearch from "../../../shared/baseSearch/BaseSearch";

export default function MenuBulkProductEntry() {
  const SEARCH_PARAM = "search";
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [menuId] = useState(searchParams.get("menuId"));
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const { data: unitList } = useGetUnitsQuery("");

  const {
    data: menuVendorProducts,
    isLoading: isLoading,
    isError: isError,
  } = useGetMenuBulkVendorProductsQuery(
    { menuId: menuId, searchText: debouncedValue },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateMenuBulkVendorProduct] =
    useUpdateMenuBulkVendorProductMutation();

  useEffect(() => {
    if (menuVendorProducts) {
      setRows(menuVendorProducts?.productValuesList);
    }
  }, [menuVendorProducts]);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (newRow) => {
    newRow.itemNumber = newRow.itemNumber?.trim();
    const updateData = {
      menuName: menuVendorProducts?.menuName,
      menuId: menuVendorProducts?.menuId,
      vendorName: menuVendorProducts?.vendorName,
      vendorId: menuVendorProducts?.vendorId,
      productValuesList: [newRow],
    };
    updateMenuBulkVendorProduct(updateData);
    const updatedRow = { ...newRow };
    setRows(
      rows.map((row) =>
        row.ingredientId === newRow.ingredientId ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const VendorProductsColumns = [
    {
      field: "ingredientName",
      headerName: "Product Description",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "itemNumber",
      headerName: "Item Number",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (params) => {
        return `$${params.value.toFixed(2)}`;
      },
    },
    {
      field: "packSize",
      headerName: "Pack Size",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      flex: 1,
      minWidth: 100,
      editable: true,
      valueFormatter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "unitId",
      headerName: "Unit",
      flex: 1,
      minWidth: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: unitList,
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.label,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={id}
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <div
            key={id}
            className="editIcon"
            style={{ cursor: "pointer" }}
            onClick={handleEditClick(id)}>
            <span className="material-icons">edit</span>
          </div>,
        ];
      },
    },
  ];

  const handleCellClick = React.useCallback((params) => {
    if (!params.isEditable) {
      return;
    }

    setRowModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: { mode: GridRowModes.View },
          }),
          {}
        ),
        [params.id]: { mode: GridRowModes.Edit },
      };
    });
  }, []);

  return (
    <>
      <BaseContent
        headerText={"Bulk Product Entry"}
        backLink={"/plateful/Menus"}
        disableHeight={true}>
        <Box height={{ md: "82.3vh", xl: "84vh" }}>
          <div className="MenuBulkHeader">
            <div className="MenuBulkHeaderLeft">
              <div className="MenuMainTitle">
                {menuVendorProducts?.menuName} Bulk Product Entry
              </div>
              <div className="MenuVendorName">
                Vendor: {menuVendorProducts?.vendorName}
              </div>
            </div>
            <div className="MenuBulkHeaderRight">
              <div className="MenuBulkHeaderRightRow">
                <div>Item missing from latest back-feed download</div>
                <div className="MenuBulkBlueSample"></div>
              </div>
              <div className="MenuBulkHeaderRightRow">
                <div>Vendor Item not configured</div>
                <div className="MenuBulkYellowSample"></div>
              </div>
            </div>
          </div>
          <div className="actionsRow">
            <div className="leftActions">
              <BaseSearch
                placeholder="Search..."
                disableUnderline={true}
                type="text"
                sx={{ ml: 1 }}
                SEARCH_PARAM={SEARCH_PARAM}
                value={searchValue}
                setSearchValue={setSearchValue}
                setDebouncedValue={setDebouncedValue}
                setSearchParams={setSearchParams}
                isMenuId={true}
                Id={menuId}
              />
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              height: "auto",
              width: "100%",
              "& .MuiInputBase-root": {
                "& input[type=number]": {
                  textAlign: "right",
                },
              },
              "& .super-app-theme--NotVendorItem": {
                backgroundColor: "#FFEFB6",
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                  backgroundColor: "#FFEFB6",
                },
                ":hover": {
                  backgroundColor: "rgba(255, 239, 182, 0.5) !important",
                },
              },
              "& .super-app-theme--NotBackfeedItem": {
                backgroundColor: "#AEE4E5",
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                  backgroundColor: "#AEE4E5",
                },
                ":hover": {
                  backgroundColor: "rgba(174, 228, 229, 0.5) !important",
                },
              },
            }}>
            <BaseDataGrid
              getRowId={(row) => row.ingredientId}
              rows={rows}
              loading={isLoading}
              columns={VendorProductsColumns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              processRowUpdate={processRowUpdate}
              error={isError}
              getRowClassName={(params) =>
                !params.row.isVendorItem
                  ? "super-app-theme--NotVendorItem"
                  : !params.row.isBackfeedItem
                  ? "super-app-theme--NotBackfeedItem"
                  : ""
              }
              onCellClick={handleCellClick}
              autoHeight={false}
              height={{ md: "65vh", xl: "70vh" }}
            />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}
