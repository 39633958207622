import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetTablesideTicketListQuery,
  useOpenTablesideTicketReportMutation,
} from "../../../store/apis/TableSideApis";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import GeneratedForm from "../../shared/generatedForm/GeneratedForm";
import { TicketPrintingFields } from "./form.constants";
import { useForm } from "react-hook-form";
import BaseSubmitButton from "../../shared/baseSubmitButton/BaseSubmitButton";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { isNull, isUndefined } from "lodash";
import BaseContent from "components/shared/baseContent/BaseContent";
import { Roles, SCREEN_CONSTANTS } from "components/shared/constants";
import { useUserFacilitiesQuery } from "store/apis/UserApis";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetFacilityByIdQuery } from "store/apis/FacilityApis";
import useMsalAccount from "utils/useMsalAccount";

export default function TicketPrinting() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const navigate = useNavigate();
  const { userId, roles } = useMsalAccount();
  const isUserAdmin = roles?.includes(Roles.Admin);
  const { data: userFacilities, isLoading: userFacilitiesLoading } =
    useUserFacilitiesQuery(userId, {
      skip: !userId || isUserAdmin,
    });
  const { data: facility, isLoading: facilityLoading } =
    useGetFacilityByIdQuery(facilityId, {
      skip: !facilityId || !isUserAdmin,
    });

  const [params, setParams] = useState({
    facilityId: facilityId,
  });
  const [defaultValues] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const facilityName = useMemo(() => {
    if (facility) {
      return facility.name;
    }
    if (userFacilities) {
      return userFacilities.find(
        (facility) => facility.facilityId == facilityId
      )?.facilityName;
    }
  }, [userFacilities, facility]);

  useEffect(() => {
    if (!isUserAdmin)
      if (!userFacilitiesLoading && !facilityName)
        navigate("/forbidden", { state: { isAuthorized: false } });
  }, [userFacilities, userFacilitiesLoading]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = methods;
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const {
    data: tableSideTicketData,
    isFetching: loading,
    error: hasError,
  } = useGetTablesideTicketListQuery(
    { params },
    { skip: !facilityId, refetchOnMountOrArgChange: true }
  );

  const [
    tickets,
    { data: report, isSuccess, isError, isLoading: isSubmitting },
  ] = useOpenTablesideTicketReportMutation();

  useEffect(() => {
    if (report && isSuccess) {
      const link = document.createElement("a");
      link.href = report?.data;
      link.setAttribute("download", `Report.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [report, isSuccess]);

  const residentsColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "diningDate",
      headerName: "Dining Date",
      flex: 1,
      minWidth: 50,
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs(params?.value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "meal",
      headerName: "Meal",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "day",
      headerName: "Day",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "building",
      headerName: "Building",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "wing",
      headerName: "Wing",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "roomNumber",
      headerName: "Room Number",
      flex: 1,
      minWidth: 100,
    },
  ];

  const [queries] = useState([
    {
      name: "diningAreaId",
      query: { facilityId: facilityId },
      skip: !facilityId,
    },
  ]);

  useEffect(() => {
    const isTrue =
      !isNull(endDate) &&
      !isNull(startDate) &&
      !isUndefined(endDate) &&
      !isUndefined(startDate);
    if (
      dayjs(endDate).format("YYYY-MM-DD") <
        dayjs(startDate).format("YYYY-MM-DD") &&
      isTrue
    ) {
      setError("startDate", {
        message: "Start date must be before  end Date",
      });
      setError("endDate", {
        message: "End date must be after  Start Date",
      });
    } else if (
      startDate &&
      endDate &&
      dayjs(endDate).format("YYYY-MM-DD") >
        dayjs(startDate).format("YYYY-MM-DD")
    ) {
      clearErrors("startDate");
      clearErrors("endDate");
    }
  }, [startDate, endDate]);
  const onSubmit = (data) => {
    setParams({
      facilityId: facilityId,
      mealId: data?.mealId,
      diningAreaId: data?.diningAreaId,
      startDate: data?.startDate,
      endDate: data?.endDate,
      showAll: data.showAll,
    });
  };

  const handleClear = () => {
    setParams({
      facilityId: facilityId,
      mealId: 0,
      diningAreaId: null,
      startDate: "",
      endDate: "",
      showAll: false,
    });
    reset();
  };

  const printTickets = () => {
    tickets({
      facilityId: facilityId,
      ticketIds: selectedRowIds,
    });
  };

  return (
    <>
      <BaseContent
        headerText={`Current Open Tickets for ${facilityName}`}
        headerTextLoading={userFacilitiesLoading || facilityLoading}>
        {userFacilitiesLoading || facilityLoading ? (
          <EditSkeleton />
        ) : (
          <div className="page-body mealCard">
            <div
              style={{
                margin: "20px",
                width: "100%",
                boxShadow: "var(--darkBoxShadow)",
                borderRadius: "18px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "20px",
                  paddingBottom: "10px",
                }}>
                <GeneratedForm
                  list={TicketPrintingFields}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  defaultOptions={defaultValues}
                  queries={queries}
                />
                <Grid item md={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end">
                    <BaseSubmitButton
                      style={{ marginRight: "10px" }}
                      text="Clear"
                      endIcon="close"
                      variant="outlined"
                      color="error"
                      onClick={handleClear}
                      // isSubmitting={addLoading}
                    />
                    <BaseSubmitButton
                      text="Search"
                      endIcon="search"
                      variant="outlined"
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={loading}
                    />
                  </Box>
                </Grid>
              </Grid>
            </div>
            <Box
              sx={{
                display: "flex",
                height: "auto",
                width: "100%",
                "& .super-app-theme--IsPrinted": {
                  color: "red",
                },
              }}>
              <BaseDataGrid
                checkboxSelection
                getRowId={(row) => row.tableSideOrderId}
                rows={tableSideTicketData}
                loading={loading}
                columns={residentsColumns}
                error={hasError}
                autoHeight={false}
                height={{ lg: "37vh", xl: "39vh" }}
                getRowClassName={(params) =>
                  params.row.isPrinted ? "super-app-theme--IsPrinted" : ""
                }
                onRowSelectionModelChange={(ids) => {
                  setSelectedRowIds(ids);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "100%",
                pt: 2,
              }}>
              <BaseSubmitButton
                sx={{ width: "175px" }}
                fullWidth
                onClick={handleSubmit(printTickets)}
                isSubmitting={isSubmitting}
                text={"Print Selected"}
              />
              {isError ? (
                <Typography color={"error"}>
                  Please select at least 1 ticket to print.
                </Typography>
              ) : undefined}
            </Box>
          </div>
        )}
      </BaseContent>
    </>
  );
}
