import { Button, Divider, Grow, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useGetCloseDietitiansQuery } from "../../../../../store/apis/DietitianAPis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";

export default function NearbyDietitianModal({
  facilityId,
  open,
  close,
  handleDietitianChange,
}) {
  const {
    data: nearbyList,
    isFetching: nearbyLoading,
    isError: nearbyError,
  } = useGetCloseDietitiansQuery(facilityId, {
    skip: !facilityId || facilityId === 0,
  });
  const nearbyColumns = [
    {
      field: "firstName",
      headerName: "First",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "lastName",
      headerName: "Last",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "distance",
      headerName: "Distance",
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`systemMessage-modal-title`}
      aria-describedby={`systemMessage-modal-description`}>
      <Grow
        in={true}
        out={true}
        style={{ transformOrigin: "100 500 0" }}
        timeout={500}>
        <Box
          position={"absolute"}
          width={"90vw !important"}
          boxShadow={"var(--tealBoxShadow)"}
          borderRadius={"var(--borderRadius)"}
          borderTop={"32px var(--yellowColor) solid"}
          backgroundColor={"var(--whiteColor)"}
          left={"5%"}
          top={"5%"}
          paddingLeft={"20px"}
          paddingRight={"20px"}>
          <Box
            display={"flex"}
            maxHeight={"80vh"}
            overflow={"auto"}
            alignItems={"center"}>
            <BaseDataGrid
              columns={nearbyColumns}
              rows={nearbyList}
              error={nearbyError}
              loading={nearbyLoading}
              onRowClick={handleDietitianChange}
            />
          </Box>
          <Divider sx={{ my: 0.5 }} />
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            padding={"10px"}
            paddingRight={"24px"}
            backgroundColor={"var(--whiteColor)"}>
            <Button
              className="baseButton"
              variant="contained"
              onClick={close}
              color={"warning"}>{`Close`}</Button>
          </Box>
        </Box>
      </Grow>
    </Modal>
  );
}
