import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DMStyles } from "styles/Styles";

export const MovableItem = ({
  name,
  id,
  index,
  currentColumnName,
  moveCardHandler,
  setItems,
  categoryId,
}) => {
  const changeItemColumn = (currentItem, columnName) => {
    setItems((prevState) => {
      return prevState.map((e) => ({
        ...e,
        column: e.id === currentItem.id ? columnName : e.column,
      }));
    });
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      const sourceColumn = item.currentColumnName;
      const targetColumn = currentColumnName;

      if (dragIndex === hoverIndex && sourceColumn === targetColumn) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCardHandler(dragIndex, hoverIndex, sourceColumn, targetColumn);
      item.index = hoverIndex;
      item.currentColumnName = targetColumn;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { index, name, currentColumnName, id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        const { name } = dropResult;
        changeItemColumn(item, name);
      }
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity, width: "100%" }}
      data-handler-id={handlerId}>
      <Box
        paddingX={"8px"}
        marginY={"1px"}
        borderRadius={DMStyles.BorderRadius}
        display={"flex"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        border={DMStyles.Border2}>
        {categoryId ? (
          <Typography
            display={"inline"}
            bgcolor={"var(--grayColor)"}
            borderRadius={DMStyles.BorderRadius}
            paddingY={"2px"}
            paddingX={"5px"}
            fontSize={"0.5rem"}
            marginRight={"2px"}>
            {"CATEGORY"}
          </Typography>
        ) : (
          <Typography
            display={"inline"}
            bgcolor={DMStyles.Teal}
            borderRadius={DMStyles.BorderRadius}
            paddingY={"2px"}
            paddingX={"5px"}
            color={DMStyles.White}
            fontSize={"0.5rem"}
            marginRight={"2px"}>
            {"RECIPE"}
          </Typography>
        )}
        <Typography textAlign={"center"} display={"inline"}>
          {name}
        </Typography>
      </Box>
    </div>
  );
};
