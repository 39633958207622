import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetResidentChangeHistoryQuery } from "../../../../store/apis/ResidentApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../../shared/baseContent/BaseContent";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import utcPlugin from "dayjs/plugin/utc";

export default function ResidentChangeHistory() {
  const [searchParams] = useSearchParams();
  const [residentId] = useState(searchParams.get("id"));
  dayjs.extend(utcPlugin);
  const {
    data: residentChange,
    isFetching: loading,
    isError: hasError,
  } = useGetResidentChangeHistoryQuery(
    {
      residentId: residentId,
    },
    { refetchOnMountOrArgChange: true }
  );
  const data = residentChange?.residentChanges;

  const residentsColumns = [
    {
      field: "lastUpdated",
      headerName: "Date",
      flex: 1,
      // valueFormatter: (params) => {
      //   if (null === params.value) {
      //     return "";
      //   } else {
      //     return new Date(params.value).toLocaleString();
      //   }
      // },
      valueGetter: (params) => dayjs(params?.value).toDate(),
      valueFormatter: (params) => {
        const isDateValid = dayjs(params?.value).isValid();
        return isDateValid
          ? dayjs
              .utc(params?.value)
              .local()
              .format(`${SCREEN_CONSTANTS.Date_Format} h:mm:ss A`)
          : "--";
      },
    },
    {
      field: "updatedBy",
      headerName: "UserId",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
  ];

  return (
    <BaseContent
      headerText={`Change History for ${residentChange?.residentName}`}
      disableHeight={true}>
      <Box height={{ md: "81vh", xl: "85vh" }}>
        <BaseDataGrid
          rows={data}
          columns={residentsColumns}
          loading={loading}
          error={hasError}
          autoHeight={false}
          height={{ md: "80vh", xl: "82vh" }}
        />
      </Box>
    </BaseContent>
  );
}
