import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";
import {
  INVALID_US_PHONE_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const pcpFax_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "PCP FAX",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];

export const PCPFAX_FIELDS = [
  {
    props: {
      name: "fromFAXNumber",
      id: "fromFAXNumber",
      label: "From Fax Number",
      type: "number",
    },
    validationProps: {
      required: REQUIRED_ERROR("From Fax Number"),
      pattern: INVALID_US_PHONE_VALIDATION("From Fax Number"),
      // pattern: NUMBER_WITH_HYPHEN_VALIDATION(),
    },
    size: 10,
  },

  {
    props: {
      name: "doctorId",
      id: "doctorId",
      label: "Select Doctor",
    },
    validationProps: {
      required: REQUIRED_ERROR("Select Doctor"),
    },
    size: 10,
    type: FORM_TYPES.SelectAPI,
    endpoint: "getFacilityDoctorSelectBox",
  },

  {
    props: {
      name: "saveFAXReport",
      id: "saveFAXReport",
      label: "Save this Fax Report",
    },
    size: 10,
    type: FORM_TYPES.Checkbox,
  },
];
