import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  FormHelperText,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
// import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import { AccessPermissionFields } from "./forms.constants";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";

export default function AccessPermissionsForm({
  control,
  errors,
  setValue,
  getValues,
}) {
  const { SecurityCheckBoxes, MenuCheckBoxes } = AccessPermissionFields;

  const [menuChecked, setMenuChecked] = useState(
    MenuCheckBoxes.map((item) => {
      return getValues(item.props?.name);
    })
  );

  function handleMenuChange(event, index) {
    const { checked } = event.target;

    if (index === -1) {
      setMenuChecked(
        MenuCheckBoxes.map((item) => {
          setValue(item.props?.name, checked);
          return checked;
        })
      );
      return;
    }

    const newMenuChecked = [...menuChecked];
    newMenuChecked[index] = checked;
    setMenuChecked(newMenuChecked);
    setValue(MenuCheckBoxes[index].props?.name, checked);
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={4}>
        <Typography variant="h5">Menu/Recipe Access</Typography>
        <BaseCheckBox
          label={"Use Menu/Recipe Screens"}
          name={"allowMenuRecipe"}
          control={control}
          errors={errors}></BaseCheckBox>
        <BaseDatePicker
          label={"Expiration Date"}
          control={control}
          name={"menuExpirationDate"}
          errors={errors}
        />
        <Divider variant="middle"></Divider>
        <Typography variant="h5">MealCard Access</Typography>
        <BaseCheckBox
          label={"Use MealCard Functionality"}
          name={"allowTrayCard"}
          control={control}
          errors={errors}
        />
        <BaseDatePicker
          label={"Expiration Date"}
          name={"traycardExpirationDate"}
          control={control}
          errors={errors}
        />
        <Divider variant="middle"></Divider>
        <Typography variant="h5">TableSide Access</Typography>
        <BaseCheckBox
          label={"TableSide Ordering"}
          name={"allowTableSideOrdering"}
          control={control}
          errors={errors}
        />
        <BaseCheckBox
          label={"TableSide Setup (Incl. Print Tickets)"}
          control={control}
          name={"allowTableSideSetup"}
          errors={errors}></BaseCheckBox>
        <BaseDatePicker
          label={"Expiration Date"}
          control={control}
          name={"tableSideExpirationDate"}
          errors={errors}
        />
        <Divider variant="middle"></Divider>
        <Typography variant="h5">Consultant Access</Typography>
        <BaseCheckBox
          label={"Use Consultant Screens"}
          name={"dietitian"}
          control={control}
          errors={errors}></BaseCheckBox>
        <BaseDatePicker
          label={"Expiration Date"}
          name={"dietitianExpirationDate"}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid item md={4}>
        <Typography variant="h5">Security</Typography>
        <BaseCheckBox
          label={"Administrator"}
          control={control}
          name={"administrator"}
          errors={errors}
        />
        <FormHelperText error>
          {
            "Checking the 'Administrator' box will give user access to all communities and data in Dining Manager. Make sure this is desired before checking the 'Administrator' box."
          }
        </FormHelperText>
        <Divider variant="middle"></Divider>
        {SecurityCheckBoxes.map((data) => {
          const props = data?.props;
          return (
            <BaseCheckBox
              key={props?.name}
              control={control}
              {...props}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
          );
        })}
      </Grid>
      <Grid item md={4}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}>
          <Typography variant="h5">Menus</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  Object.values(menuChecked).filter((value) => value !== true)
                    .length < 1
                }
                indeterminate={
                  !menuChecked.every((value) => value === true) &&
                  !menuChecked.every((value) => value === false) &&
                  !menuChecked.every((value) => value === undefined)
                }
                onChange={() => handleMenuChange(event, -1)}
              />
            }
            label="Select All"
          />
        </Box>
        {MenuCheckBoxes.map((data, index) => {
          const props = data?.props;
          return (
            <BaseCheckBox
              key={props?.name}
              control={control}
              {...props}
              checked={menuChecked[index] || false}
              onChange={() => handleMenuChange(event, index)}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
