import React from "react";
import { useGetTagsQuery } from "../../../../../store/apis/TagsApi";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PostAdd } from "@mui/icons-material";

export default function Tags() {
  const {
    data: tags,
    isError: hasError,
    isLoading: loading,
  } = useGetTagsQuery(1);

  const isError = hasError;
  const isLoading = loading;

  const TagColumns = [
    {
      field: "typeId",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        let type = "Unknown";
        switch (params.value) {
          case 1:
            type = "Recipe";
            break;
          default:
            break;
        }
        return type;
      },
    },
    {
      field: "name",
      headerName: "Tag Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={tags}
            hasEdit={true}
            editProps={{
              To: `addTag?id=${params.row.id}`,
              title: "Tag",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteTag",
              title: "Tag",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"space-between"} marginY={"8px"}>
        <BaseButton
          marginLeft={"0px !important"}
          text={"Add Tag"}
          To={"addTag"}
          endIcon={<PostAdd />}
        />
      </FlexBox>

      <BaseDataGrid
        rows={tags}
        loading={isLoading}
        columns={TagColumns}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
