import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { GppBad, VerifiedUser } from "@mui/icons-material";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/YesNoDisplay.md)
 */
export default function YesNoDisplay({ value }) {
  const text = value ? "Yes" : "No";
  return (
    <>
      <Typography variant="p" width={20}>
        {text}
      </Typography>
      <Tooltip title={text}>
        {value ? (
          <VerifiedUser sx={{ ml: "4px" }} color="primary" />
        ) : (
          <GppBad sx={{ ml: "4px" }} color="error" />
        )}
      </Tooltip>
    </>
  );
}
