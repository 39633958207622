import React, { useEffect, useState } from "react";
import {
  useGetCorporateDocumentsByIdQuery,
  useAddCorporateDocumentsMutation,
  useUpdateCorporateDocumentsMutation,
} from "../../../../../../../store/apis/CorporateGroupDocumentApis";
import {
  useNavigate,
  useSearchParams,
  Link,
  useLocation,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import {
  BorderClear,
  Description,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AddDocument() {
  const [addDocument, { isError: addError, isLoading: addLoading }] =
    useAddCorporateDocumentsMutation();

  const [updateDocument, { isError: updateError, isLoading: updateLoading }] =
    useUpdateCorporateDocumentsMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;

  const [searchParams] = useSearchParams();

  const corporateId = searchParams.get("corporateId");
  const docId = searchParams.get("id");

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const location = useLocation();
  const container = location.state?.container;

  const isEdit = !!docId;

  const {
    data: documentData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateDocumentsByIdQuery(docId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && documentData) {
      reset(documentData);
    }
  }, [documentData]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("Name", data.name);
    formData.append("GroupName", data.groupName);
    formData.append("SortOrder", data.sortOrder);
    formData.append("CorporateGroupId", corporateId);
    formData.append("ContainerName", container);

    formData.append("File", uploadedFile);

    if (isEdit) {
      formData.append("Id", docId);
      formData.append("Url", data.url);
      formData.append("IsImage", uploadedFile ? true : false);
      updateDocument(formData);
    } else {
      addDocument(formData);
    }

    if (!isError) {
      navigate(-1);
    }
  };

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      setUploadedFile(file);
      const { name } = file;
      setFileName(name);
    } else null;
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <FlexBox justifyContent={"start"} my={1}>
            <Button
              component={Link}
              color={"primary"}
              variant="contained"
              onClick={() => navigate(-1)}
              startIcon={<KeyboardArrowLeft />}>
              {"Back to Document  List"}
            </Button>
          </FlexBox>
          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <FormProvider>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <BaseInput
                    name="name"
                    id="name"
                    label="Document Name"
                    control={control}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Document Name"),
                    }}
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />

                <Grid item xs={5}>
                  <BaseInput
                    name={"groupName"}
                    id="groupName"
                    label="Group Name"
                    control={control}
                    errors={errors}
                    validationProps={{
                      required: REQUIRED_ERROR("Group Name"),
                    }}
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />

                <Grid item xs={5}>
                  <BaseInput
                    name="sortOrder"
                    id="sortOrder"
                    label="Sort Order"
                    control={control}
                    errors={errors}
                    type="number"
                  />
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />

                <Grid item xs={5}>
                  <Button
                    fullWidth
                    component="label"
                    variant="outlined"
                    startIcon={uploadedFile ? <Description /> : <BorderClear />}
                    sx={{ marginRight: "1rem" }}>
                    {uploadedFile ? `Selected File: ` : `Select File`}
                    <input
                      type="file"
                      accept=".pdf"
                      hidden
                      onChange={handleFileUpload}
                    />
                    {fileName}
                  </Button>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />

                <Grid item xs={5}>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <BaseSubmitButton
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                      text={isEdit ? "Submit" : "Create"}
                    />
                  </Box>
                  {isError ? (
                    <Typography color={"error"}>
                      There was an error submitting the form, please try again.
                    </Typography>
                  ) : undefined}
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
}
