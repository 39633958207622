/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  useGetFacilityCareTypeBulkEditQuery,
  useAddFacilityCareTypedBulkEditMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";

export default function EditCommunityCareType() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const facilityId = searchParams.get("id");

  const { data: facilityCareTypes, isFetching: careTypesLoading } =
    useGetFacilityCareTypeBulkEditQuery(facilityId);

  const [
    updateFacilityCareTypes,
    { isError: updateError, isLoading: updateLoading },
  ] = useAddFacilityCareTypedBulkEditMutation();

  const [careTypes, setCareTypes] = useState([]);
  const [isCareType, setIsCareType] = useState(false);

  const isError = updateError;
  const isSubmitting = updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit } = methods;

  useEffect(() => {
    facilityCareTypes?.map((item) => {
      careTypes.push({
        careTypeId: item.careTypeId,
        name: item.name,
        selected: item.selected,
      });
    });
    setIsCareType(!isCareType);
  }, [facilityCareTypes]);

  const onSubmit = () => {
    updateFacilityCareTypes({
      careTypes: careTypes,
      facilityName: "",
      facilityId: facilityId,
    });
    if (!isError) navigate(-1);
  };

  const updateftn = (data, index) => {
    const newArr = [...careTypes];
    newArr[index].selected = !data.selected;
    setCareTypes(newArr);
  };

  return (
    <>
      {careTypesLoading ? (
        <EditSkeleton />
      ) : (
        <div style={{ padding: 15 }}>
          <FormProvider>
            <form>
              <Grid container spacing={2}>
                {careTypes?.map((data, index) => {
                  return (
                    <Grid key={index} item xs={10}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data?.selected}
                            onClick={() => {
                              updateftn(data, index);
                            }}
                          />
                        }
                        label={data?.name}
                      />
                    </Grid>
                  );
                })}

                <Grid item xs={5}>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <BaseSubmitButton
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                    />
                  </Box>
                  {isError ? (
                    <Typography color={"error"}>
                      There was an error submitting the form, please try again.
                    </Typography>
                  ) : undefined}
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </form>
          </FormProvider>
        </div>
      )}
    </>
  );
}
