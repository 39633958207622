import React, { useEffect, useState } from "react";
import "./Faq.css";
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useGetFaqsQuery } from "../../store/apis/FaqApis";
import Card from "@mui/material/Card";
import _ from "lodash";
import expand from "../../assets/imgs/symbol_dropdown-arrow_gray.png";
// import reactStringReplace from "react-string-replace";
import BaseContent from "../shared/baseContent/BaseContent";
import Markdown from "react-markdown";

function Faq() {
  const {
    data: faqs,
    isFetching: loading,
    refetch: refetchData,
  } = useGetFaqsQuery();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    refetchData();
  }, [isAuthenticated]);

  return (
    <BaseContent disableBack={true} headerText={`Frequently Asked Questions`}>
      <UnauthenticatedTemplate>
        <h3>Please login to see full FAQ list</h3>
      </UnauthenticatedTemplate>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      ) : null}
      <FaqAccordion faqs={faqs} />
    </BaseContent>
  );
}

function FaqAccordion({ faqs }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Card mb={6} style={{ overflowY: "auto" }}>
      <CardContent>
        {Object.entries(_.groupBy(faqs, "section")).map((entry) => (
          <div className="FAQ-section" key={entry[0]}>
            <Typography
              gutterBottom
              sx={{
                color: "var(--tealColor)",
                fontWeight: "bold",
                fontSize: "x-large",
                fontFamily: "inherit",
              }}>
              {entry[0]}
            </Typography>
            <div>
              {entry[1].map((innerEntry) => (
                <Accordion
                  key={innerEntry.title}
                  expanded={expanded === innerEntry.title}
                  onChange={handleChange(innerEntry.title)}>
                  <AccordionSummary
                    expandIcon={<img className="expand-icon" src={expand} />}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontFamily: "inherit",
                      }}>
                      {innerEntry.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      textAlign: "left",
                      margin: "20px",
                    }}>
                    {/* <Typography sx={{ fontFamily: "inherit" }}>
                      {reactStringReplace(
                        innerEntry.body,
                        /(\[https?:\/\/.*?\])/g,
                        (match, i) => (
                          <a
                            key={match + i}
                            href={match.match(/(https?:\/\/\S+?(?=\|))/g)}
                            target="_blank"
                            rel="noreferrer">
                            {match.match(/((?<=\|).*)+?(?=\])/g)}
                          </a>
                        )
                      )}
                    </Typography> */}
                    {/* <Typography sx={{ fontFamily: "inherit" }}> */}
                      <Markdown className="FAQ-body"
                      components={{
                        a: (props) => <a {...props} target="_blank" rel="noreferrer">{props.children}</a>
                      }}>{innerEntry.body}</Markdown>                      
                    {/* </Typography> */}
                    {innerEntry.url ? (
                      <CardMedia
                        className="FAQ-video"
                        component="video"
                        height="140"
                        src={innerEntry.url}
                        title="Road"
                        controls
                      />
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}

export default Faq;
