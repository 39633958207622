import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetAllergiesQuery } from "../../../../../store/apis/AllergyAPIs";
import { Button } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PostAdd } from "@mui/icons-material";

export default function MealCardAllergies() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: allergiesPagedData,
    isError: hasError,
    isLoading: loading,
  } = useGetAllergiesQuery({
    searchTerm: debouncedValue || "",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const allergies = allergiesPagedData?.items || [];

  const isError = hasError;
  const isLoading = loading;

  const AllergiesColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={allergies}
            hasEdit={true}
            editProps={{
              To: `addallergy?id=${params.row.id}`,
              title: "Allergies",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteAllergies",
              title: "Allergies",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
        <Button
          component={Link}
          to={`addallergy`}
          color={"primary"}
          variant="contained"
          endIcon={<PostAdd />}>
          Add New
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={allergies}
        rowCount={allergiesPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={AllergiesColumns}
        loading={isLoading}
        error={isError}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
