import React, { useEffect } from "react";
import {
  useAddCorporateGroupPBJMutation,
  useAddCorporateGroupPBJExcelMutation,
} from "../../../../../../store/apis/CorporateGroupApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseCheckBox from "../../../../../shared/baseForm/BaseCheckBox";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import dayjs from "dayjs";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function ExportPBJ() {
  const [searchParams] = useSearchParams();
  const corporateId = searchParams.get("id");
  const corporateName = searchParams.get("name");

  const [
    addExportPBJ,
    {
      data: report,
      isError: addError,
      isLoading: addLoading,
      isSuccess: isSuccess,
    },
  ] = useAddCorporateGroupPBJMutation();

  const [
    addExportPBJExcel,
    {
      data: reportExcel,
      isError: addErrorExcel,
      isLoading: addLoadingExcel,
      isSuccess: isSuccessExcel,
    },
  ] = useAddCorporateGroupPBJExcelMutation();

  useEffect(() => {
    if (report && isSuccess) {
      const downloadUrl = window.URL.createObjectURL(new Blob([report]));
      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      // TODO: Replace this with the name of your file
      link.setAttribute(
        "download",
        `${corporateName}-${dayjs(startDate).format("MM-DD-YYYY")}-${dayjs(
          endDate
        ).format("MM-DD-YYYY")}.zip`
      );
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Cleanup
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    }
  }, [report, isSuccess]);

  useEffect(() => {
    if (isSuccessExcel) {
      DownloadLinkHelper(reportExcel?.data);
    }
  }, [reportExcel?.data]);

  const isError = addError || addErrorExcel;
  const isSubmitting = addLoading || addLoadingExcel;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    watch,
    clearErrors,
    control,
    formState: { errors },
  } = methods;
  const startDate = watch("reportStartDate");
  const endDate = watch("reportEndDate");
  useEffect(() => {
    if (
      dayjs(startDate).format("MM-DD-YYYY") <=
      dayjs(endDate).format("MM-DD-YYYY")
    ) {
      clearErrors("reportStartDate");
      clearErrors("reportEndDate");
    }
  }, [startDate, endDate]);

  const onSubmit = (data) => {
    if (data?.excelFormat) {
      addExportPBJExcel({
        ...data,
        corporateGroupId: corporateId,
        corporateGroupName: corporateName,
      });
    } else {
      addExportPBJ({
        ...data,
        corporateGroupId: corporateId,
        corporateGroupName: corporateName,
      });
    }
  };

  return (
    <>
      <FlexBox justifyContent={"start"} my={1}>
        <Button
          component={Link}
          color={"primary"}
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={<KeyboardArrowLeft />}>
          {"Back to  Corporate list"}
        </Button>
      </FlexBox>
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <FormProvider>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <BaseDatePicker
                label={"Report Start Date:"}
                name={"reportStartDate"}
                placeholder="Report Start Date"
                control={control}
                errors={errors}
                validationProps={{
                  max: {
                    value: endDate ? new Date(endDate) : null,
                    message: "Start Date must be before End Date",
                  },
                  required: REQUIRED_ERROR("Report Start Date"),
                }}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              <BaseDatePicker
                label={"Report End Date:"}
                name={"reportEndDate"}
                placeholder="Report End Date"
                control={control}
                errors={errors}
                validationProps={{
                  min: {
                    value: startDate ? new Date(startDate) : null,
                    message: "End Date must be before Start Date",
                  },
                  required: REQUIRED_ERROR("Report End Date"),
                }}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              <BaseCheckBox
                name="excelFormat"
                id="excelFormat"
                label="Excel Format"
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={"Export"}
                />
              </Box>
              {isError ? (
                <Typography color={"error"}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
