import React from "react";
import { useGetResidentLiquidQuery } from "../../../../store/apis/ResidentApis";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Grid } from "@mui/material";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";

export default function ResidentLiquid() {
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");

  const {
    data: residentThickenedLiquid,
    isLoading,
    isError,
  } = useGetResidentLiquidQuery(residentId, { skip: !residentId });

  const residentLiquidColumns = [
    {
      field: "thickenedLiquidName",
      minWidth: 50,
      flex: 4,
      headerName: "Liquid Consistency",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={residentThickenedLiquid}
            hasEdit={true}
            editProps={{
              To: `addresidentLiquid?residentId=${residentId}`,
              title: "Resident Liquid",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.thickenedLiquidName,
              apiPath: "deleteResidentLiquid",
              title: "Resident Liquid",
            }}
          />
        );
      },
    },
  ];

  return (
    <Card sx={{ padding: "10px", minHeight: "80vh" }}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={"8px"}>
        <Button
          component={Link}
          to={`addresidentLiquid?residentId=${residentId}`}
          state={{
            name:
              residentThickenedLiquid?.resident?.firstName +
              " " +
              residentThickenedLiquid?.resident?.lastName,
          }}
          color={"primary"}
          variant="contained"
          endIcon={<PostAdd />}>
          Add Liquid Consistency
        </Button>
      </Grid>

      <BaseDataGrid
        rows={residentThickenedLiquid}
        columns={residentLiquidColumns}
        error={isError}
        loading={isLoading}
        autoHeight={false}
        height={{ md: "62vh", xl: "67vh" }}
      />
    </Card>
  );
}
