import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BaseRadio from "../../../../shared/baseForm/BaseRadio";
import BaseAutoComplete from "../../../../shared/baseForm/BaseAutoComplete";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { useForm } from "react-hook-form";
import {
  useGetPlateFulRecipesSelectBoxQuery,
  useGetRecipeQuery,
  usePrintRecipeMutation,
} from "../../../../../store/apis/PlateFulRecipesApis";
import { debounce } from "lodash";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";
import { QtyInputs, Checkboxes } from "./forms.constants";
import { useSelector } from "react-redux";
import { selectFacilityId } from "store/slices/authSlice/AuthSlice";
import { BackButton } from "components/shared/BackButton";
import useMsalAccount from "utils/useMsalAccount";

export default function RecipePrint() {
  const [searchParams] = useSearchParams();
  const [recipeId, setRecipeId] = useState(searchParams.get("recipeId"));
  const [defaultRecipe, setDefaultRecipe] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [useCustomServings, setUseCustomServings] = useState(0);

  const facilityId = useSelector(selectFacilityId);
  const { userId } = useMsalAccount();

  const radioGroup = [
    { value: 0, label: "Default # of servings" },
    { value: 1, label: "Custom # of servings" },
  ];

  const { data: recipesData, isFetching: loading } =
    useGetPlateFulRecipesSelectBoxQuery(
      {
        searchTerm: debouncedSearch,
        archive: false,
        userId: userId,
        facilityId: facilityId,
        categoryId: "",
        tagIds: [],
      },
      { refetchOnMountOrArgChange: true }
    );

  const {
    data: currentRecipeData,
    isLoading: isCurrentRecipeLoading,
    isSuccess: isCurrentRecipeSuccess,
  } = useGetRecipeQuery(recipeId, { skip: !recipeId });

  const [
    printRecipe,
    {
      isError: printError,
      isSuccess: printSuccess,
      isLoading: printLoading,
      data: report,
    },
  ] = usePrintRecipeMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (isCurrentRecipeSuccess && !isCurrentRecipeLoading) {
      setDefaultRecipe([
        {
          id: currentRecipeData?.id,
          label: currentRecipeData?.name,
        },
      ]);
    }
  }, [isCurrentRecipeSuccess, currentRecipeData]);

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedSearch(query);
      }, 400),
    []
  );

  useEffect(() => {
    const newRecipeId = watch("recipeId");
    if (newRecipeId !== undefined && newRecipeId !== recipeId) {
      setRecipeId(newRecipeId);
    }
  }, [watch("recipeId")]);

  useEffect(() => {
    setUseCustomServings(watch("useCustomServings"));
  }, [watch("useCustomServings")]);

  useEffect(() => {
    if (printSuccess) {
      DownloadLinkHelper(report?.data);
    }
  }, [report?.data]);

  const onSubmit = (data) => {
    const customServings = parseInt(useCustomServings) !== 0;
    data.recipeId = recipeId;
    data.useCustomServings = customServings;
    printRecipe(data);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white !important",
        }}
        height={{ sm: "60vh", md: "65vh", lg: "70vh", xl: "80vh" }}>
        <Grid container spacing={2} padding={"20px"}>
          <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
            <BackButton />
          </Grid>
          <Grid item xs={9} sm={9} md={10} lg={10} xl={10}></Grid>
          <Grid item xs={6}>
            <BaseAutoComplete
              formSelectProps={{
                props: {
                  name: "recipeId",
                  id: "recipeId",
                  label: "Recipe",
                },
                validationProps: {},
              }}
              control={control}
              errors={errors}
              options={recipesData || []}
              loading={loading}
              defaultOptions={defaultRecipe}
              handleSearch={handleSearch}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <BaseRadio
              label={"Servings"}
              id={"useCustomServings"}
              name={"useCustomServings"}
              control={control}
              defaultValue={0}
              options={radioGroup}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            {parseInt(useCustomServings) === 1 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}>
                {QtyInputs.map((data) => {
                  const props = data?.props;
                  return (
                    <BaseInput
                      key={props.name}
                      type="number"
                      sx={{ marginBottom: "10px" }}
                      label={props.label}
                      name={props.name}
                      placeholder={props.label}
                      control={control}
                      errors={errors}
                    />
                  );
                })}
              </Box>
            )}
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={6}>
            {Checkboxes.map((data) => {
              const props = data?.props;
              return (
                <BaseCheckBox
                  key={props.id}
                  name={props.name}
                  id={props.id}
                  label={props.label}
                  defaultValue={false}
                  control={control}
                  errors={errors}
                />
              );
            })}
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={printLoading}
                text={"Print"}
                className="baseButton"
              />
            </Box>
            {printError ? (
              <Typography color={"error"}>
                There was an error submitting the form, please try again.
              </Typography>
            ) : undefined}
          </Grid>
          <Grid item xs={9} />
        </Grid>
      </Box>
    </>
  );
}
