import {
  Box,
  Card,
  Grid,
  Select,
  Stack,
  Chip,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  useAddRecipeTagsMutation,
  useGetRecipeTagsListQuery,
} from "../../../../../store/apis/RecipeTagsAPIs";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { Labels } from "../../../../plateFul/recipes/plateFul.constant";

import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";

import { useGetRecipeTagsQuery } from "../../../../../store/apis/PlateFulRecipesApis";

import InfoIcon from "@mui/icons-material/Info";
import useMsalAccount from "utils/useMsalAccount";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";

export const RecipeTags = () => {
  const [searchParams] = useSearchParams();
  const currentLocation = useLocation();
  const canEdit = currentLocation.state?.canEdit;
  const recipeId = searchParams.get("recipeId");
  const { userName: UserName } = useMsalAccount();
  const [tags, setTags] = useState(Labels.EMPTY_ARRAY);
  const [selectableTags, setSelectableTags] = useState([]);
  const { data: RecipeTagsData2 } = useGetRecipeTagsQuery();

  const {
    data: RecipeTagsData,
    isFetching: loading,
    isError: hasError,
  } = useGetRecipeTagsListQuery(
    {
      id: recipeId,
    },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (RecipeTagsData2) {
      if (RecipeTagsData) {
        setSelectableTags(
          RecipeTagsData2.filter(
            (item) => !RecipeTagsData.some((item2) => item2.tagId === item.id)
          )
        );
      } else {
        setSelectableTags(RecipeTagsData2);
      }
    }
  }, [RecipeTagsData2, RecipeTagsData]);

  const [
    addRecipeTags,
    { isLoading: addRecipeTagsSubmitting, isSuccess: addRecipeTagsSuccess },
  ] = useAddRecipeTagsMutation();

  const isSubmitting = addRecipeTagsSubmitting;
  const isSuccess = addRecipeTagsSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  if (!isSubmitting && isSuccess) {
    // console.log("Success");
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    data.recipeId = recipeId;
    data.tagId = tags.map((item) => item.id);
    data.createdby = UserName;

    addRecipeTags(data);

    setTags(Labels.EMPTY_ARRAY);
  };

  const RecipeTagssColumn = [
    {
      field: "tagName",
      flex: 3,
      headerName: "Recipe Tags",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "right",
      align: "right",

      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={RecipeTagsData}
            hasDelete={canEdit}
            deleteProps={{
              entityName: params.row?.tagName,
              apiPath: "deleteRecipeTags",
              title: "Recipe Tags",
            }}
          />
        );
      },
    },
  ];

  return (
    <Card>
      <Grid display={"flex"} padding={1}>
        {canEdit ? (
          <FlexBox my={1} justifyContent="space-between">
            <Select
              name="tagId2"
              id="tagId2"
              multiple
              value={tags}
              placeholder="Select Tags"
              className="selectInput"
              control={control}
              errors={errors}
              onChange={(event) => {
                setTags(event.target.value);
              }}
              size="small"
              displayEmpty
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.length === 0
                    ? "Select Tags.."
                    : selected.map((option) => (
                        <Chip
                          name="tagId"
                          id="tagId"
                          key={option.id}
                          label={option.name}
                          onDelete={() =>
                            setTags(
                              tags.filter((item) => item.id !== option.id)
                            )
                          }
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                </Stack>
              )}>
              {selectableTags?.map((option) => (
                <MenuItem
                  key={option.id}
                  value={option}
                  sx={{ justifyContent: "space-between" }}>
                  {option?.name}
                  {tags?.map((options) =>
                    options?.name?.includes(option?.name) ? (
                      <CheckIcon key={options.id} color="info" />
                    ) : null
                  )}
                </MenuItem>
              ))}
            </Select>
            <FlexBox justifyContent={"end"}>
              <Tooltip
                title={"Please select one or more tags from the select box."}>
                <InfoIcon color="primary" cursor="pointer" />
              </Tooltip>
              <Tooltip
                title={
                  tags.length === 0 && !isSubmitting ? "Please select tags" : ""
                }>
                <span>
                  <BaseSubmitButton
                    style={{
                      marginLeft: "20px",
                    }}
                    disabled={tags.length === 0}
                    isSubmitting={isSubmitting}
                    text="Add"
                    onClick={handleSubmit(onSubmit)}
                    fullWidth
                  />
                </span>
              </Tooltip>
            </FlexBox>
          </FlexBox>
        ) : null}
      </Grid>

      <Box px={1}>
        <BaseDataGrid
          rows={RecipeTagsData}
          columns={RecipeTagssColumn}
          error={hasError}
          loading={loading}
          autoHeight={false}
          height={{ md: "52vh", lg: "55vh", xl: "57vh" }}
        />
      </Box>
    </Card>
  );
};
