import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, TextField, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseRichTextEditor.md)
 */
const BaseRichTextEditor = ({ validationProps, control, errors, ...rest }) => {
  const nameOrId = rest?.name || rest?.id;

  const renderQuill = ({ field: { onChange, value } }) => (
    <ReactQuill
      theme="snow"
      modules={modules}
      value={value}
      onChange={onChange}
      style={{ height: "200px" }}
    />
  );

  return (
    <FormControl sx={{ width: "100%", height: "250px" }}>
      {control ? (
        <>
          <Controller
            render={renderQuill}
            control={control}
            name={nameOrId}
            defaultValue=""
            rules={validationProps}
          />
          <Typography color="error">{errors?.[nameOrId]?.message}</Typography>
        </>
      ) : (
        <TextField sx={{ width: "100% !important" }} {...rest} />
      )}
    </FormControl>
  );
};

export default BaseRichTextEditor;
