import React, { useEffect, useState } from "react";
import { MenuItem, Divider, Button, Tooltip, Box } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./UserSetup.css";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { DownloadLinkHelper } from "../../../../shared/utils/helpers";

import TempPassword from "./tempPassword/TempPassword";
import {
  useDownloadUsersReportQuery,
  useSearchUsersByNameQuery,
} from "../../../../../store/apis/UserApis";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSnackbar } from "notistack";
import { b2cPolicies, loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";
import BaseActionBox from "components/shared/BaseActionBox";
import { ArrowDropDown, Check, Close, PersonAdd } from "@mui/icons-material";
import { USER_MENU_ITEMS } from "./UserSetup.constants";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseMenu from "components/shared/BaseMenu";

function UserSetup() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValueDebounced, setSearchValueDebounced] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const [showArchived, setShowArchived] = useState(
    searchParams?.get("archived") === "true"
  );
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: userPagedData,
    isFetching: loading,
    isError: hasError,
  } = useSearchUsersByNameQuery({
    searchTerm: searchValueDebounced || "",
    archived: showArchived?.toString() || "false",
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const usersData = userPagedData?.items || [];

  const [reportName, setReportName] = useState(null);

  const {
    data: usersReport,
    isFetching: reportsFetching,
    isSuccess: reportsSuccess,
  } = useDownloadUsersReportQuery(reportName, { skip: reportName === null });
  useEffect(() => {
    setSearchParams({
      search: searchValueDebounced,
      ["archived"]: showArchived,
    });
  }, [showArchived]);

  useEffect(() => {
    if (reportsSuccess) {
      DownloadLinkHelper(usersReport?.data);
      enqueueSnackbar(`${usersReport?.messages[0]}`, {
        variant: "success",
      });
    }
  }, [usersReport?.data]);

  const usersColumns = [
    {
      field: "fullName",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <>
            {params?.row?.fullName}
            {params?.row?.archived && (
              <Tooltip title="Archived">
                <span style={{ marginLeft: "8px" }} className="material-icons">
                  inventory_2
                </span>
              </Tooltip>
            )}
            {params?.row?.administrator && (
              <Tooltip title="Administrator">
                <span
                  style={{ marginLeft: "8px" }}
                  className="material-icons greenColor">
                  admin_panel_settings
                </span>
              </Tooltip>
            )}
          </>
        );
      },
      flex: 1,
      minWidth: 100,
    },
    { field: "facilityName", headerName: "Facility", flex: 1, minWidth: 200 },
    {
      field: "dietitian",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Contracted Consultant",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.dietitian} />;
      },
    },
    {
      field: "advancedUser",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Advanced",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.advancedUser} />;
      },
    },
    {
      field: "lastLogin",
      headerName: "Last Logon",
      flex: 1,
      minWidth: 80,
      valueFormatter: (params) => {
        if (null === params.value) {
          return "";
        } else {
          return new Date(params.value).toLocaleString();
        }
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={usersData}
            hasEdit={true}
            childrenLocation={"end"}
            editProps={{ To: `adduser?id=${params.row.id}`, title: "User" }}
            title="User">
            <UserOptions
              index={params.row.id}
              id={params.row.id || 0}
              dietitian={params.row["dietitian"]}
              name={params.row["fullName"] || ""}
              username={params.row["username"] || ""}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Box>
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setSearchValueDebounced}
            setSearchParams={setSearchParams}
            isArchived={true}
            showArchived={showArchived}
            setPaginationModel={setPaginationModel}
          />
        </Box>
        <FlexBox justifyContent={"space-between"}>
          <FlexBox justifyContent={"start"}>
            <Button
              component={Link}
              to="adduser"
              color={"primary"}
              variant="contained"
              endIcon={<PersonAdd />}>
              Add User
            </Button>
            <Button
              size="small"
              color={"primary"}
              variant="contained"
              endIcon={showArchived ? <Check /> : <Close />}
              onClick={() => {
                setShowArchived(!showArchived);
              }}>
              Show Archived
            </Button>
          </FlexBox>

          <ReportButton
            reportsFetching={reportsFetching}
            reportsSuccess={reportsSuccess}
            reportName={reportName}
            usersReport={usersReport}
            setReportName={setReportName}
          />
        </FlexBox>
      </FlexBox>

      <BaseDataGrid
        height={"55dvh"}
        rows={usersData}
        rowCount={userPagedData?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={usersColumns}
        loading={loading}
        error={hasError}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}

const UserOptions = ({ index, id, name, dietitian, username }) => {
  const { instance } = useMsal();
  const [openModal, setOpenModal] = useState(false);
  const [openModalPass, setOpenModalPass] = useState(false);
  const navigate = useNavigate();

  async function handleLogOnAs(userName) {
    const request = {
      authority: b2cPolicies.authorities.logonAs.authority,
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        targetEmail: userName,
        // eslint-disable-next-line no-undef
        server: process.env.REACT_APP_BASE,
      },
    };

    await instance.loginPopup(request);
    navigate("/home");
  }

  return (
    <>
      <BaseMenu index={index}>
        {USER_MENU_ITEMS({ dietitian, id, name }).map((item, idx) => (
          <MenuItem key={idx} component={Link} to={item.link} disableRipple>
            {item.label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setOpenModalPass(true);
          }}
          disableRipple>
          Reset Password
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          disableRipple
          onClick={() => {
            handleLogOnAs(username);
          }}>
          Log on as
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModal(true);
          }}
          className="menuDeleteButton"
          disableRipple>
          Delete
        </MenuItem>
      </BaseMenu>
      {openModal && (
        <DeleteModal
          id={id}
          entityName={name}
          apiPath={"deleteUser"}
          open={openModal}
          close={() => setOpenModal(false)}
        />
      )}
      {openModalPass && (
        <TempPassword
          id={id}
          fullname={name}
          username={username}
          open={openModalPass}
          close={() => setOpenModalPass(false)}
        />
      )}
    </>
  );
};

const ReportButton = ({
  reportsFetching,
  reportsSuccess,
  reportName,
  usersReport,
  setReportName,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload = (name) => {
    if (!reportsFetching && reportsSuccess && reportName === name) {
      DownloadLinkHelper(usersReport?.data);
      enqueueSnackbar(`${usersReport?.messages[0]}`, {
        variant: "success",
      });
      return;
    }
    setReportName(() => name);
  };

  return (
    <>
      <BaseMenu
        text="Reports"
        endIcon={<ArrowDropDown />}
        color="error"
        variant="contained"
        icon="false"
        prefix={"report"}
        loading={reportsFetching}>
        <MenuItem onClick={() => handleDownload("DietitianList")} disableRipple>
          Download Consultant Credentials
        </MenuItem>
        <MenuItem onClick={() => handleDownload("UserList")} disableRipple>
          Download List of all user
        </MenuItem>
      </BaseMenu>
    </>
  );
};

export default UserSetup;
