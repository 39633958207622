import { Typography, Button, Grid, Box } from "@mui/material";
import React from "react";
import { useSearchParams, Link, useLocation } from "react-router-dom";
import { useGetOrderTransmissionsListQuery } from "../../../../store/apis/MenuApis";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import BaseContent from "../../../shared/baseContent/BaseContent";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";

export default function OrderTransmission() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("menuId");
  const location = useLocation();
  const title = location?.state?.title;

  const {
    data: ItemsData,
    isError: hasError,
    isLoading,
  } = useGetOrderTransmissionsListQuery({
    menuId: id,
  });

  const orderColumns = [
    {
      field: "startDay",
      flex: 1,
      headerName: "Start Day",
    },
    {
      field: "endDay",
      flex: 1,
      headerName: "End Day",
    },
    {
      field: "dateSent",
      flex: 1,
      headerName: "Date Sent",
      valueFormatter: (params) =>
        params.value !== null
          ? dayjs(params.value).format(
              `${SCREEN_CONSTANTS.Date_Format} hh:mm:ss A`
            )
          : "-",
    },
    {
      field: "sentBy",
      flex: 1,
      headerName: "Sent By",
    },
  ];

  return (
    <BaseContent disableHeight={true}>
      <Box height={{ md: "82.3vh", xl: "86vh" }}>
        <Grid container>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            marginY={"3px"}>
            <Typography variant="h6">
              Order Transmission for {title} menu
            </Typography>
            <Button
              component={Link}
              to={`newOrder?menuId=${id}`}
              color={"primary"}
              state={{
                title: title,
              }}
              className="baseButton"
              variant="contained"
              endIcon={<span className="material-icons">post_add</span>}>
              {"New Order"}
            </Button>
          </Box>
          <BaseDataGrid
            rows={ItemsData}
            loading={isLoading}
            columns={orderColumns}
            error={hasError}
            height={{ md: "73vh", xl: "78vh" }}
            autoHeight={false}
          />
        </Grid>
      </Box>
    </BaseContent>
  );
}
