import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VendorIngredientInfoForm from "./forms/VendorIngredientInfo";
import { useSnackbar } from "notistack";
import {
  useAddVendorIngredientMutation,
  useUpdateVendorIngredientMutation,
  useGetVendorIngredientByIdQuery,
} from "../../../../../../store/apis/IngredientAPIs";
import { useGetUnitsQuery } from "../../../../../../store/apis/UnitApis";
import { useGetVendorSelectBoxQuery } from "../../../../../../store/apis/VendorApis";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { BackButton } from "components/shared/BackButton";

function AddVendorIngredient() {
  const currentLocation = useLocation();
  const [
    addVendorIngredient,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddVendorIngredientMutation();
  const [
    updateVendorIngredient,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdateVendorIngredientMutation();

  const { data: vendorList, isFetching: vendorListLoading } =
    useGetVendorSelectBoxQuery("");
  const { data: unitList, isFetching: unitListLoading } = useGetUnitsQuery("");

  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const currentVendorIngredientId = searchParams.get("id");
  const currentIngredientId = searchParams.get("ingredientId");

  const isEdit = !!currentVendorIngredientId;

  const {
    data: currentVendorIngredient,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetVendorIngredientByIdQuery(currentVendorIngredientId, {
    skip: !isEdit,
  });
  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
    watch,
    getValues,
  } = methods;
  const { enqueueSnackbar } = useSnackbar();

  const type = watch("type") || "0";

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentVendorIngredient) {
      reset(currentVendorIngredient);
    }
  }, [currentVendorIngredient]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(
        `Vendor: ${isEdit ? "Updated" : "Created"} Successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateVendorIngredient(data);
      return;
    }
    data.ingredientId = currentIngredientId;
    addVendorIngredient(data);
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          {currentLocation.pathname ===
          "/admin/recipes/ingredients/VendorIngredient/addVendorIngredient" ? (
            <BackButton text={"Back To Vendor Ingredients"} />
          ) : null}

          <React.Fragment>
            <FormProvider>
              <form>
                <VendorIngredientInfoForm
                  type={type}
                  errors={errors}
                  getValue={getValues}
                  control={control}
                  vendorList={vendorList}
                  vendorListLoading={vendorListLoading}
                  unitList={unitList}
                  unitListLoading={unitListLoading}
                />

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography color={"error"}>
                    {error?.data?.messages}
                  </Typography>
                ) : undefined}
              </form>
            </FormProvider>
          </React.Fragment>
        </>
      )}
    </>
  );
}

export default AddVendorIngredient;
