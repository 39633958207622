import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetRecipeByIdUserFacilityQuery } from "../../../../store/apis/RecipeAPIs";
import { useGetRecipeIngredientQuery } from "../../../../store/apis/RecipeIngredientApi";
import "./RecipeDetailCard.css";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../../store/slices/authSlice/AuthSlice";
import { BaseButton } from "components/shared/BaseButton";
import { EditSkeleton } from "components/admin/admin.overlays";
import { Roles } from "components/shared/constants";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import useMsalAccount from "utils/useMsalAccount";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function RecipeDetailCard() {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const recipeId = Number(searchParams.get("recipeId"));
  const { userId, roles: userRoles } = useMsalAccount();

  const facilityId = useSelector(selectFacilityId);
  const navigate = useNavigate();

  const isAdmin = userRoles.includes(Roles.Admin);

  const {
    data: currentRecipe,
    isLoading: currentRecipeLoading,
    error: currentRecipeError,
  } = useGetRecipeByIdUserFacilityQuery(
    {
      recipeId,
      userId,
      facilityId,
    },
    { skip: !recipeId || !userId || !facilityId }
  );

  if (currentRecipeError?.status === 403)
    navigate("/forbidden", { state: { isAuthorized: false } });

  const { data: recipeIngredientData, isFetching: loading } =
    useGetRecipeIngredientQuery(
      {
        id: recipeId,
      },
      { skip: !recipeId }
    );

  const isLoading = currentRecipeLoading || loading;

  if (isLoading) {
    return <EditSkeleton />;
  }

  return (
    <>
      <Box
        sx={{
          color: "black !important",
        }}>
        <FlexBox justifyContent={"start"} my={1}>
          {isAdmin || currentRecipe?.canEdit ? (
            <BaseButton
              To={`/plateFul/Recipes/addRecipe?recipeId=${currentRecipe?.id}`}
              endIcon={<span className="material-icons">edit</span>}
              text="Edit"
              state={state}
              colors="primary"
            />
          ) : null}
        </FlexBox>

        <Box
          my={1}
          sx={{
            width: "99%",
            height: "auto",
            padding: "5px",
            border: `2px solid`,
            borderColor: "#00BCBE",
            display: "flex",
            borderRadius: "5px",
          }}
          height={{
            md: isAdmin ? "63vh" : "66vh",
            lg: isAdmin ? "65vh" : "70vh",
            xl: isAdmin ? "70vh" : "72vh",
          }}>
          <Box width="49%" marginX="3px" padding="10px">
            <Box>
              <Typography variant="h6" fontWeight="bold">
                {currentRecipe?.name}
              </Typography>
              <Typography variant="p">
                Recipe Number : {currentRecipe?.id}
              </Typography>
            </Box>
            <Grid container marginBottom={"10px"} marginTop="5px">
              <Grid item md={12} lg={12} xl={12} gap={0.5}>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  sx={{
                    bgcolor: "#ECECEC",
                    borderRadius: "5px",
                    padding: "5px",
                  }}>
                  <Box
                    width="15px"
                    height="15px"
                    backgroundColor={
                      currentRecipe?.baseRecipe ? "#00BCBE" : null
                    }
                    border="1px solid"
                    borderColor="#00BCBE"
                    borderRadius={1}
                  />
                  <Typography fontWeight="bold">Base Recipe</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container rowSpacing={0.5} marginTop="4px" paddingX="10px">
              <Grid item md={6} lg={6} xl={6}>
                <Typography variant="p" fontWeight="bold">
                  Recipe Category:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} textAlign="end">
                <Typography>{currentRecipe?.category}</Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item md={6} lg={6} xl={6}>
                <Typography variant="p" fontWeight="bold">
                  Menu Category:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} textAlign="end">
                <Typography>{currentRecipe?.menuCategory}</Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Typography variant="p" fontWeight="bold">
                  Ingredients:
                </Typography>
              </Grid>
              <Grid item md={8} lg={8} xl={8}>
                {recipeIngredientData?.map((item, index) => (
                  <ul key={index}>
                    <li>{`${item?.quantity} ${item?.unit} ${item?.ingredient}`}</li>
                  </ul>
                ))}
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item md={6} lg={6} xl={6}>
                <Typography variant="p" fontWeight="bold">
                  Description for Posted Menu:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} textAlign="end">
                <Typography>
                  {currentRecipe?.descriptionForPrintedMenus || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Portion Size:
                </Typography>
                <Typography>{currentRecipe?.portionSize || ""}</Typography>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xl={6}
                gap="10px"
                display="flex"
                justifyContent="end">
                <Typography fontWeight="bold">Number Served:</Typography>
                <Typography>
                  {currentRecipe?.baseNumOfServings || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Small Portion Size  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Small Portion Size:
                </Typography>
                <Typography>
                  {currentRecipe?.smallPortionSize
                    ? currentRecipe?.smallPortionSize
                    : "--"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xl={6}
                gap="10px"
                display="flex"
                justifyContent="end">
                <Typography fontWeight="bold">Number Served:</Typography>
                <Typography>
                  {currentRecipe?.smallPortionNumOfServings || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* large Portion Size  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Large Portion Size:
                </Typography>
                <Typography>
                  {currentRecipe?.largePortionSize
                    ? currentRecipe?.largePortionSize
                    : "--"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xl={6}
                gap="10px"
                display="flex"
                justifyContent="end">
                <Typography fontWeight="bold">Number Served:</Typography>
                <Typography>
                  {currentRecipe?.largePortionNumOfServings || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Pan Size  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Pan Size:
                </Typography>
                <Typography>
                  {currentRecipe?.panSize ? currentRecipe?.panSize : "--"}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                lg={6}
                xl={6}
                gap="10px"
                display="flex"
                justifyContent="end">
                <Typography fontWeight="bold">Cooking Temperature:</Typography>
                <Typography>
                  {currentRecipe?.cookingTemperature || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Recipe Variations  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Recipe Variations:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} gap="10px" textAlign="end">
                <Typography>{currentRecipe?.variations || ""}</Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Advanced Preparation menu  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Advanced Preparation Menu:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} gap="10px" textAlign="end">
                <Typography>
                  {currentRecipe?.advancedPreparation || ""}
                </Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Recipe description  */}
              <Grid item md={6} lg={6} xl={6} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Recipe Description:
                </Typography>
              </Grid>
              <Grid item md={6} lg={6} xl={6} gap="10px" textAlign="end">
                <Typography>{currentRecipe?.description || ""}</Typography>
              </Grid>
              <Grid item md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              {/* Last updated  */}
              <Grid item md={4} lg={4} xl={4} display="flex" gap="10px">
                <Typography variant="p" fontWeight="bold">
                  Last Update:
                </Typography>
              </Grid>
              <Grid item md={8} lg={8} xl={8} gap="10px" textAlign="end">
                <Typography>{`${currentRecipe?.userUpdated} - ${dayjs(
                  currentRecipe?.lastUpdated
                ).format(SCREEN_CONSTANTS.DateTime_Format)}`}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider
            orientation="vertical"
            sx={{ borderColor: "#00BCBE", height: "auto" }}
            variant="middle"
            flexItem
          />

          <Box width="49%" marginX="3px" paddingX="10px" paddingY="5px">
            <Typography variant="p" fontWeight="bold">
              Recipe Instructions:
            </Typography>
            <Typography
              sx={{
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                padding: "8px",
              }}>
              {currentRecipe?.directions || ""}
            </Typography>
            {currentRecipe?.imageUrl ? (
              <>
                <Typography fontWeight="bold">Recipe Photo: </Typography>
                <Box
                  border="1px solid"
                  borderColor="#00BCBE"
                  borderRadius="5px"
                  height="15vh"
                  alignItems="center"
                  style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={currentRecipe?.imageUrl}
                    alt="Recipe photo"
                    style={{
                      maxWidth: "40vw",
                      height: "auto",
                      maxHeight: "99%",
                    }}
                  />
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
}
