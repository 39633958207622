import React, { useState, useEffect } from "react";
import { Box, Card, FormControlLabel, Grid, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import {
  useRecalculateVendorCostsMutation,
  useRecipeVendorCostsQuery,
} from "../../../../../store/apis/RecipeAPIs";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import DeleteButton from "../../../../shared/DeleteButton";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { Labels } from "../../../menus/Menu.constant";
import { BaseButton } from "components/shared/BaseButton";
import { useSnackbar } from "notistack";

export default function VendorCost() {
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const { enqueueSnackbar } = useSnackbar();
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get(SEARCH_PARAM) || Labels.EMPTY_STRING
  );
  const recipeId = searchParams.get("recipeId");

  const {
    data: recipeVendorCosts,
    isLoading,
    isError,
  } = useRecipeVendorCostsQuery(
    {
      searchTerm: debouncedValue,
      id: recipeId,
    },
    { skip: !recipeId, refetchOnMountOrArgChange: true }
  );

  const [
    recalculate,
    {
      isLoading: isRecalculating,
      error: recalculateError,
      isSuccess: recalculateSuccess,
    },
  ] = useRecalculateVendorCostsMutation();

  useEffect(() => {
    if (recalculateSuccess) {
      enqueueSnackbar(`Recalculated successfully.`, { variant: "success" });
    }
  }, [recalculateSuccess]);

  const vendorCostColumns = [
    {
      field: "vendorName",
      headerName: "Vendor",
      flex: 2,
    },
    {
      field: "costPerServing",
      headerName: "Cost",
      flex: 1,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "Details",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Details",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return !params?.row?.overrideCost ? (
          <FormControlLabel
            control={
              <Link
                to={`vendorProducts?vendorId=${params?.row?.vendorId}&recipeId=${recipeId}`}
                className="editIcon">
                <span className="material-icons">visibility</span>
              </Link>
            }
          />
        ) : (
          <Typography>Cost Overridden</Typography>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: false,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <DeleteButton
            index={params.row.id}
            entityName={params.row?.vendorName}
            apiPath="deleteRecipeVendorCost"
            text={"Delete Cost Info"}
          />
        );
      },
    },
  ];

  return (
    <>
      <Card padding={"5px"}>
        <Grid display={"flex"} marginY={"10px"}>
          <BaseButton
            component={Link}
            To={`addVendorCost?recipeId=${recipeId}`}
            text="Add New cost Override"
            endIcon={<span className="material-icons">playlist_add</span>}
          />

          <BaseSubmitButton
            className="baseButton"
            onClick={() => recalculate(recipeId)}
            isSubmitting={isRecalculating}
            validationSummary={recalculateError}
            text={"Recalculate"}
          />
        </Grid>
        <Box
          display={"flex"}
          margin={"8px"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"start"}>
          <BaseSearch
            sx={{ width: "500px" }}
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isRecipeId={true}
            Id={recipeId}
          />
        </Box>
        <Box
          sx={{
            paddingBottom: 2,
            paddingX: 1,
          }}>
          <BaseDataGrid
            autoHeight={false}
            height={{ md: "47vh", lg: "51vh", xl: "53vh" }}
            rows={recipeVendorCosts?.recipeVendors}
            loading={isLoading}
            columns={vendorCostColumns}
            error={isError}
          />
        </Box>
      </Card>
    </>
  );
}
