import React, { useState, useMemo } from "react";
import { useAddCorporateUserMutation } from "../../../../../../../store/apis/CorporateGroupUserApis";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import BaseAutoComplete from "../../../../../../shared/baseForm/BaseAutoComplete";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { debounce } from "lodash";
import { useGetUserSelectBoxQuery } from "../../../../../../../store/apis/UserApis";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function AddSuperUser() {
  const [addSuperUser, { isError: addError, isLoading: addLoading }] =
    useAddCorporateUserMutation();

  const isError = addError;
  const isSubmitting = addLoading;
  const [searchParams] = useSearchParams();
  const corporateId = searchParams.get("id");

  const [userDebouncedSearch, setUserDebouncedSearch] = useState("");

  const {
    data: userList,
    isFetching: loadingUser,
    isError: userError,
  } = useGetUserSelectBoxQuery(userDebouncedSearch, {
    skip: !userDebouncedSearch,
  });

  const handleUserSearch = useMemo(
    () =>
      debounce((query) => {
        setUserDebouncedSearch(query);
      }, 400),
    []
  );

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    addSuperUser({ ...data, corporateGroupId: corporateId });

    if (!isError) {
      navigate(-1);
    }
  };

  return (
    <>
      <FlexBox justifyContent={"start"} my={1}>
        <Button
          component={Link}
          color={"primary"}
          variant="contained"
          onClick={() => navigate(-1)}
          startIcon={<KeyboardArrowLeft />}>
          {"Back to User list"}
        </Button>
      </FlexBox>
      <Divider sx={{ marginBottom: "15px", border: "none" }} />
      <FormProvider>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <BaseAutoComplete
                options={userList || []}
                defaultOptions={[]}
                loading={loadingUser}
                control={control}
                handleSearch={handleUserSearch}
                formSelectProps={{
                  props: {
                    label: "UserName or Email",
                    id: "userIdentifier",
                    name: "userIdentifier",
                  },
                  validationProps: {
                    required: REQUIRED_ERROR("UserName or Email"),
                  },
                }}
                errors={errors}
                loadingError={userError}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} />

            <Grid item xs={5}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={"Add"}
                />
              </Box>
              {isError ? (
                <Typography color={"error"}>
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
