import React, { useState } from "react";
import { Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";
import "./SystemMessages.css";
import clsx from "clsx";
import { useGetSystemMessagesOptionalQuery } from "../../../../../store/apis/SystemMessagesAPIs";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import { API_ENDPOINTS } from "../../../../../store/store.constants";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { AddComment, Check, Close } from "@mui/icons-material";

const { SYSTEM_MESSAGES } = API_ENDPOINTS;

function SystemMessages() {
  const theme = useTheme();
  const [showArchived, setShowArchived] = useState(false);
  const url = showArchived
    ? `/${SYSTEM_MESSAGES}?skip=0&take=1000`
    : `/${SYSTEM_MESSAGES}/GetSystemMessageActivelist`;
  const {
    data: systemMessages,
    isFetching: loading,
    isError: hasError,
  } = useGetSystemMessagesOptionalQuery(url, {
    refetchOnMountOrArgChange: true,
  });

  const mTypes = [
    { key: 0, value: "System" },
    { key: 1, value: "Corporate Group" },
    { key: 2, value: "Facility" },
    { key: 3, value: "User" },
  ];

  const systemMessagesColumns = [
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      flex: 1,
      cellClassName: (params) => {
        return clsx("super-app", {
          expired: new Date(params.value) < Date.now(),
        });
      },
      valueFormatter: (params) => {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 3,
      valueFormatter: (params) => {
        return params.value.replace(/(<([^>]+)>)/gi, "");
      },
    },
    {
      field: "type",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Type",
      flex: 1,
      valueFormatter: (params) => {
        return mTypes.find((mType) => mType.key === params.value).value;
      },
    },
    {
      field: "priority",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Priority",
      flex: 1,
    },
    {
      field: "title",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={systemMessages}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addSystemMessage?id=${params.row.id}`,
              state: {
                title: "Edit System Message",
                data: systemMessages.find((row) => row.id === params.row.id),
              },
              title: "System Message",
            }}
            deleteProps={{
              entityName: params.row?.title || "",
              apiPath: "deleteSystemMessages",
              title: "System Message",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"space-between"} my={"8px"}>
        <Button
          color={"primary"}
          variant="contained"
          endIcon={showArchived ? <Check /> : <Close />}
          onClick={() => {
            setShowArchived(!showArchived);
          }}>
          Show Archived
        </Button>
        <Button
          component={Link}
          to="addSystemMessage"
          color={"primary"}
          variant="contained"
          endIcon={<AddComment />}>
          Add System Message
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={systemMessages}
        columns={systemMessagesColumns}
        error={hasError}
        loading={loading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "53dvh" }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          "& .super-app.expired": {
            color: theme.palette.error.dark,
          },
        }}
      />
    </>
  );
}

export default SystemMessages;
