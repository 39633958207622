import React, { useEffect } from "react";
import {
  useGetAllergiesByIdQuery,
  useAddAllergiesMutation,
  useUpdateAllergiesMutation,
} from "../../../../../../store/apis/AllergyAPIs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, Typography } from "@mui/material";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { ALLERGY_FIELDS } from "./forms.constants";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { BaseButton } from "../../../../../shared/BaseButton";

export default function AddAllergy() {
  const [addAllergy, { isError: addError, isLoading: addLoading }] =
    useAddAllergiesMutation();

  const [updateAllergy, { isError: updateError, isLoading: updateLoading }] =
    useUpdateAllergiesMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();
  const EditId = searchParams.get("id");

  const isEdit = !!EditId;

  const {
    data: allergy,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetAllergiesByIdQuery(EditId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && allergy) {
      reset(allergy);
    }
  }, [allergy]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateAllergy(data);
    } else {
      addAllergy(data);
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <div className="leftActions">
            <BaseButton
              text="Back To Allergy list"
              startIcon={"keyboard_arrow_left"}
              onClick={() => navigate(-1)}
            />
          </div>
          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <Box padding={"8px"}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}>
              <GeneratedForm
                control={control}
                list={ALLERGY_FIELDS}
                errors={errors}
              />

              <Grid item xs={5}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <BaseSubmitButton
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                    text={isEdit ? "Submit" : "Create"}
                  />
                </Box>
                {isError ? (
                  <Typography color={"error"}>
                    There was an error submitting the form, please try again.
                  </Typography>
                ) : undefined}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
