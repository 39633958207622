import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { MealCardSetupElements } from "./mealcardsetup.constants";
import { Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

export default function MealCardSetup() {
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const [menuItems, setMenuItems] = useState(MealCardSetupElements);
  useEffect(() => {
    var setActive = featureMealExtra?.enabled == "true";
      if(setActive)
      {
        setMenuItems(MealCardSetupElements);
      }
      else if (!setActive)
      {
        setMenuItems(MealCardSetupElements.filter((x) => x.Name !== featureMealExtra?.description));
      }
    }, [featureMealExtra]);

  return (
    <>
      <AdminTabs Data={menuItems} />
      <Card
        sx={{
          padding: "5px",
          overflow: "auto",
          borderTopLeftRadius: "0px !important",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}>
        <Box height={"auto"}>
          <Outlet />
        </Box>
      </Card>
    </>
  );
}
