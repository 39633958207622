import React from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import mealCardIcon from "../../../assets/imgs/meal_card_check.svg";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import profileImg from "../../../assets/imgs/profile_img.svg";
import uploadIcon from "../../../assets/imgs/upload_icon.svg";
import deleteIcon from "../../../assets/imgs/delete_icon.svg";
import dayjs from "dayjs";

export default function ResidentDetailsCardSection({
  photo,
  residentId,
  resident,
  photoEnabled,
  handleFileUpload,
  handleDeletePhoto,
}) {
  return (
    <Grid
      container
      mt={2}
      sx={{
        borderRadius: "4px",
        marginLeft: "12px !important",
        boxShadow: "0px 4px 4px 0px #00000040",
      }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "var(--tealColor)",
          borderRadius: "4px 4px 0 0",
        }}>
        <Box
          display="flex"
          justifyContent="space-between"
          padding="8px 16px 0px 16px">
          <Box display="flex" gap="0.8rem" padding="8px 16px">
            <Box
              component="img"
              alt="Profile Image"
              src={photo ? photo : profileImg}
              sx={{
                border: "1px solid white",
                borderRadius: "4px !important",
                padding: "0.1rem",
                width: "6.25rem",
                height: "6.25rem",
                objectFit: "contain",
              }}></Box>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Typography
                  component="span"
                  textTransform="uppercase"
                  fontSize={28}
                  fontWeight={800}
                  sx={{ color: "white !important" }}>
                  {resident.firstName} {resident.lastName}
                </Typography>
                <Typography
                  component="span"
                  fontWeight={600}
                  sx={{ color: "white !important" }}>
                  Preferred Name: {resident.nickname}
                </Typography>
                <Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "white !important",
                      lineHeight: "24.55px",
                      marginTop: "0.6rem",
                    }}>
                    Print Meal Card:
                    {resident.printTrayCard ? (
                      <Box
                        component="img"
                        alt="Default Profile Image"
                        src={mealCardIcon}></Box>
                    ) : (
                      <span
                        style={{ margin: "5px" }}
                        className="material-icons redColor">
                        gpp_bad
                      </span>
                    )}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" alignItems="start" paddingTop="0.6rem">
            <Link
              to={`/MealCard/addResident?id=${residentId}`}
              className="newEditButton">
              <Typography component="span" fontWeight={700}>
                Edit
              </Typography>
            </Link>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          paddingLeft="1.03rem"
          gap="0.2rem">
          {photoEnabled ? (
            <Button
              component="label"
              sx={{
                height: "30px",
              }}>
              <input
                type="file"
                accept=".png, .jpg, ,.jpeg, .bmp"
                hidden
                onChange={handleFileUpload}
              />
              <Tooltip title="Click to upload a resident profile picture">
                <Box
                  component="img"
                  alt="Default Profile Image"
                  sx={{
                    border: "1px solid white",
                    padding: "0.3rem 0.4rem",
                    borderRadius: "6px",
                  }}
                  src={uploadIcon}></Box>
              </Tooltip>
            </Button>
          ) : null}

          {photo ? (
            <Box
              component="img"
              size="small"
              sx={{
                border: "1px solid white",
                padding: "0.3rem 0.5rem",
                borderRadius: "6px",
                cursor: "pointer",
                marginLeft: "-4px !important",
              }}
              onClick={handleDeletePhoto}
              src={deleteIcon}></Box>
          ) : null}
        </Box>

        <Grid item xs={12} mt={2}>
          <Box display="flex" flexDirection="column" padding="0 1rem">
            <Grid
              container
              spacing={1}
              padding="0 16px 8px 16px"
              display="flex"
              justifyContent="space-between">
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                alignItems="center"
                gap="0.5rem"
                flexGrow={1}>
                <Typography
                  fontWeight={800}
                  sx={{ color: "white !important", fontSize: "20px" }}
                  component="span">
                  Room Number:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.roomNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                alignItems="center"
                gap="0.5rem"
                flexGrow={1}>
                <Typography
                  fontWeight={800}
                  sx={{ color: "white !important", fontSize: "20px" }}
                  component="span">
                  Bed:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.bed}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                alignItems="center"
                gap="0.5rem"
                flexGrow={1}>
                <Typography
                  fontWeight={800}
                  sx={{ color: "white !important", fontSize: "20px" }}
                  component="span">
                  Wing:
                </Typography>
                <Typography
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.wing}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                alignItems="center"
                gap="0.5rem"
                flexGrow={1}>
                <Typography
                  fontWeight={800}
                  sx={{ color: "white !important", fontSize: "20px" }}
                  component="span">
                  Building:
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.building}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2.4}
                display="flex"
                alignItems="center"
                gap="0.5rem"
                flexGrow={1}>
                <Typography
                  fontWeight={800}
                  sx={{ color: "white !important", fontSize: "20px" }}
                  component="span">
                  EHR Identifier:
                </Typography>
                <Typography
                  component="span"
                  sx={{ color: "white !important", fontSize: "20px" }}>
                  {resident.ahtpatientId}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        display="flex"
        sx={{ padding: "0.5rem 1rem" }}
        justifyContent="space-between">
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            DOB:
          </Typography>
          <Typography>
            {resident.dateOfBirth
              ? dayjs(resident.dateOfBirth).format(SCREEN_CONSTANTS.Date_Format)
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Isolation:
          </Typography>
          <Typography>{resident.isIsolation ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Room Tray:
          </Typography>
          <Typography>{resident.isRoomTray ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Unlimited Meals
          </Typography>
          <Typography component="span">
            {resident.hasUnlimitedMeals ? "Yes" : "No"}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Care Plan Due Date:
          </Typography>
          <Typography component="span">
            {resident.carePlanDueDate
              ? dayjs(resident.carePlanDueDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        display="flex"
        sx={{ padding: "0rem 1rem 0rem 1rem" }}
        justifyContent="space-between">
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Sex:
          </Typography>
          <Typography>{resident.gender}</Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Physician:
          </Typography>
          <Typography>{resident.physician}</Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Admission Date:
          </Typography>
          <Typography>
            {resident.admissionDate
              ? dayjs(resident.admissionDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Assessment Due Date:
          </Typography>
          <Typography component="span">
            {resident.assessmentDueDate
              ? dayjs(resident.assessmentDueDate).format(
                  SCREEN_CONSTANTS.Date_Format
                )
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2.4} display="flex" gap="0.5rem" flexGrow={1}>
          <Typography fontWeight={700} component="span">
            Resident Id:
          </Typography>
          <Typography component="span">{resident.residentIdNumber}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
