import { Print } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import { BackButton } from "components/shared/BackButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { BaseButton } from "components/shared/BaseButton";
import { WarningText } from "components/shared/WarningText";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { DownloadLinkHelper } from "components/shared/utils/helpers";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  useGenerateVisitReportMutation,
  useGetVisitDetailsQuery,
  useGetVisitReportsQuery,
} from "store/apis/ConsultantApis";
import { DMStyles } from "styles/Styles";

export default function VisitsReport() {
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("facilityId");
  const visitId = searchParams.get("visitId");
  const visitPlanId = searchParams.get("visitPlanId");
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: visitDetailsData,
    isLoading: visitLoading,
    isError: hasError,
  } = useGetVisitReportsQuery(visitId, {
    skip: !visitId,
  });

  const isSummaryReportTrue = visitDetailsData?.some(
    (typeId) => typeId?.visitReportTypeId == 1
  );

  const [
    GenerateReport,
    {
      data: visitReport,
      isError: visitReportError,
      isSuccess: isVisitSuccess,
      isLoading: visitReportLoading,
      error: visitTypeError,
    },
  ] = useGenerateVisitReportMutation();

  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId);
  const { id, isFinal } = visitData || [];

  // Veriables
  const isError = hasError || visitReportError;
  const isLoading = visitLoading || visitReportLoading;
  const error = visitTypeError;
  // useEffect Section
  useEffect(() => {
    if (isVisitSuccess) {
      DownloadLinkHelper(visitReport?.data);
    }
    if (isError && error) {
      if (error?.data?.messages) {
        enqueueSnackbar(`Report Does not Exist`, {
          variant: "warning",
        });
      }
    }
  }, [isError, visitReport, error]);

  // Function which will be called mutation Function
  const onSubmit = (ID, e) => {
    e.preventDefault();
    GenerateReport({
      id: ID,
    });
  };

  // Column Section For Data Grid
  const viewReportColumns = [
    {
      field: "visitReportType",
      minWidth: 200,
      flex: 1,
      headerName: "Report Types",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "description",
      minWidth: 200,
      flex: 1,
      headerName: "Description",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            childrenLocation={"start"}
            // data={MenusData}
            hasEdit={!isFinal}
            index={params.row.id}
            editProps={{
              title: "Visit Report",
              To:
                params.row.visitReportTypeId == 1 ||
                params.row.visitReportTypeId == 2 ||
                params.row.visitReportTypeId == 3
                  ? `/Consultant/MyRosters/VisitDetails/VisitReport?Id=${params.row.id}&visitPlanId=${visitPlanId}&visitReportTypeId=${params.row.visitReportTypeId}&visitId=${params.row.visitId}`
                  : params.row.visitReportTypeId == 5
                  ? `/Consultant/MyRosters/VisitDetails/addMemo?visitId=${params.row.visitId}&visitReportTypeId=${params.row.visitReportTypeId}&id=${params.row.id}&visitPlanId=${visitPlanId}`
                  : params.row.visitReportTypeId
                  ? `/Consultant/MyRosters/VisitDetails/NutritionalCares?visitPlanId=${visitPlanId}&visitReportId=${params.row.id}`
                  : `/Consultant/MyRosters/VisitDetails/AddMenus?recipeId=${params.row.id}`,
            }}
            hasDelete={!isFinal}
            deleteProps={{
              entityName: params.row?.visitReportType,
              apiPath: "deleteVisitReport",
            }}>
            <Button
              component={Link}
              color={"primary"}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(params.row.id, e);
              }}
              variant="contained"
              endIcon={<Print />}>
              Print
            </Button>
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <Grid container spacing={0.1}>
        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
          <BackButton
            text="Back to Visit list"
            To={`/admin/communities/communitydetail/visits?id=${facilityId}`}
          />
        </Grid>
        <Grid
          item
          xs={0}
          sm={0}
          md={
            isSummaryReportTrue && isFinal
              ? 8
              : isFinal
              ? 6
              : isSummaryReportTrue
              ? 6
              : 4
          }
          lg={
            isSummaryReportTrue && isFinal
              ? 7.5
              : isFinal
              ? 5
              : isSummaryReportTrue
              ? 5
              : 2.5
          }
          xl={
            isSummaryReportTrue && isFinal
              ? 8.3
              : isFinal
              ? 6.6
              : isSummaryReportTrue
              ? 6.6
              : 4.9
          }></Grid>
        {!isSummaryReportTrue ? (
          <Grid item xs={4} sm={4} md={2} lg={2.5} xl={1.7}>
            <BaseButton
              text={"Create Consultant Summary "}
              To={`/Consultant/MyRosters/VisitDetails/VisitReport?visitPlanId=${visitPlanId}&visitId=${id}&visitReportTypeId=1`}
            />
          </Grid>
        ) : null}
        <Grid item xs={4} sm={4} md={2} lg={2.5} xl={1.7}>
          <Tooltip
            title={
              !isSummaryReportTrue
                ? "This report cannot be submitted until a Consult Summary has been Created."
                : null
            }
            placement="top">
            <span>
              <BaseButton
                text={"Send Report to Community"}
                To={`/Consultant/MyRosters/VisitDetails/SendReport?visitPlanId=${visitPlanId}&isEdit=true`}
                colors={"white"}
                fontColor={DMStyles.Teal}
                border={`1px solid ${DMStyles.Teal}`}
                disabled={!isSummaryReportTrue}
              />
            </span>
          </Tooltip>
        </Grid>
        {!isFinal ? (
          <Grid item xs={4} sm={4} md={2} lg={2.5} xl={1.7}>
            <Tooltip
              title={
                !isSummaryReportTrue
                  ? "This report cannot be submitted until a Consult Summary has been Created."
                  : null
              }
              placement="top">
              <span>
                <BaseButton
                  text={"Submit Report to DiningRD"}
                  To={`/Consultant/MyRosters/VisitDetails/SubmitReportToHealthTech?visitPlanId=${visitPlanId}`}
                  colors={"white"}
                  fontColor={DMStyles.Teal}
                  border={`1px solid ${DMStyles.Teal}`}
                  disabled={!isSummaryReportTrue}
                  // toolTip={!isSummaryReportTrue}
                />
              </span>
            </Tooltip>
          </Grid>
        ) : null}
        {visitDetailsData?.length > 0 ? (
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <WarningText
              sx={{ marginLeft: "0px !important" }}
              text={
                isFinal
                  ? "These reports have been submitted to DiningRD, no further changes can be made."
                  : visitDetailsData?.length == 1
                  ? "This report has not been submitted to DiningRD yet."
                  : "These reports have not been submitted to DiningRD yet."
              }
              color={isFinal ? "" : "red"}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          marginTop={visitDetailsData?.length > 0 ? 0 : 0.5}>
          <BaseDataGrid
            rows={visitDetailsData || []}
            columns={viewReportColumns}
            loading={isLoading}
            error={isError}
            autoHeight={false}
            height={{
              lg: visitDetailsData?.length > 0 ? "50vh" : "53vh",
              xl: visitDetailsData?.length > 0 ? "54vh" : "45vh",
            }}
            // getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
