import { Box } from "@mui/material";
import { BackButton } from "components/shared/BackButton";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import FlexBox from "components/shared/styledComponents/FlexBox";
import dayjs from "dayjs";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";

export default function ReportLog() {
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const facilityId = searchParams.get("facilityId");

  const {
    data: visitData,
    isLoading,
    isError,
  } = useGetVisitDetailsQuery(
    visitPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { dietitianReportSendLog } = visitData || [];

  const viewReportColumn = [
    {
      field: "senderEmail",
      minWidth: 100,
      flex: 1,
      headerName: "From",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
    },
    {
      field: "sentTo",
      minWidth: 100,
      flex: 1,
      headerName: "To",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
    {
      field: "dateSent",
      minWidth: 100,
      flex: 1,
      headerName: "Date Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        const isDateValid = dayjs(params.row.dateSent).isValid();
        return isDateValid
          ? dayjs(params.row.dateSent).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "reportsSent",
      minWidth: 100,
      flex: 1,
      headerName: "Report Sent",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
    },
  ];

  return (
    <>
      <>
        <FlexBox justifyContent="space-between">
          <BackButton
            text="Back to Visit list"
            To={`/admin/communities/communitydetail/visits?id=${facilityId}`}
          />
        </FlexBox>
        <Box marginBlock={3}>
          <BaseDataGrid
            rows={dietitianReportSendLog || []}
            columns={viewReportColumn}
            loading={isLoading}
            error={isError}
            autoHeight={false}
            height={{ lg: "60vh", xl: "55vh" }}
            // getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        </Box>
      </>
    </>
  );
}
