import { Grid, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect } from "react";
import Fade from "@mui/material/Fade";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { InteractionStatus } from "@azure/msal-browser";
import { EditSkeleton } from "components/admin/admin.overlays";

const ErrorContainer = styled(Box)(() => ({
  marginTop: "75px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
  padding: "20px",
}));

const ErrorTitle = styled(Typography)({
  fontWeight: "bold",
  marginTop: "20px",
});

const ErrorMessage = styled(Typography)({
  marginTop: "10px",
});

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/Forbidden.md)
 */
export default function Forbidden() {
  // Can't use useSearchParams() because it splits before the searchParams in the redirectURL
  const url = window.location.href?.split("redirectURL=");
  const redirectURL = url.length > 1 ? url[1] : "";
  const { inProgress, instance } = useMsal();

  const location = useLocation();
  const state = location.state;
  const isAuthorized =
    state?.isAuthorized === null || state?.isAuthorized === undefined
      ? true
      : state?.isAuthorized;
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && isAuthorized) navigate(redirectURL);
  }, [isAuthenticated, isAuthorized, instance?.getActiveAccount()]);

  if (inProgress !== InteractionStatus.None) {
    return <EditSkeleton text={"Checking permissions"} color={"error"} />;
  }

  return (
    <Fade in={true} timeout={500}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <ErrorContainer className="errorContainer">
          <Typography color="error" textAlign="center" variant="h3">
            Forbidden!
          </Typography>
          <ErrorTitle>
            {"You don't have permission to view this page!"}
          </ErrorTitle>
          <ErrorMessage>
            Please contact your administrator for more details.
          </ErrorMessage>
        </ErrorContainer>
      </Grid>
    </Fade>
  );
}
