import React, { useEffect, useState } from "react";
// import { useIsAuthenticated } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
// import { useSnackbar } from "notistack";
import { useGetDietsQuery } from "../../../../../store/apis/DietsApi";

import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import YesNoDisplay from "../../../../shared/YesNoDisplay";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PostAdd } from "@mui/icons-material";
// import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

export default function Diets() {
  const SEARCH_PARAM = "search";
  //   const appInsights = useAppInsightsContext();
  //   const isAuthenticated = useIsAuthenticated();
  //   const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [maxRankValue, setMaxRankValue] = useState(0);
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState(
    searchParams.get("search") || ""
  );

  const {
    data: diets,
    isError: hasError,
    isLoading: loading,
  } = useGetDietsQuery(debouncedValue);

  const isError = hasError;
  const isLoading = loading;

  useEffect(() => {
    if (diets?.length > 0) {
      const maxValue = Math.max(...diets.map((x) => x.dietRank));

      // keep max diet rank in state
      if (maxValue > maxRankValue) {
        setMaxRankValue(maxValue);
      }
    }
  }, [diets]);

  const DietColumns = [
    {
      field: "name",
      headerName: "Diet Name",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "includeInMenu",
      headerName: "Include in Menus",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.includeInMenu} />;
      },
    },
    {
      field: "isTexture",
      headerName: "Is a Texture",
      flex: 1,
      headerAlign: "center",
      align: "center",
      minWidth: 50,
      renderCell: (params) => {
        return <YesNoDisplay value={params?.row?.isTexture} />;
      },
    },
    {
      field: "dietRank",
      headerName: "Rank",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "sortOrder",
      headerName: "Sort Order",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 50,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={diets}
            hasEdit={true}
            editProps={{
              To: `addDiet?id=${params.row.id}`,
              title: "Diet",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row["name"],
              apiPath: "deleteDiet",
              title: "Diet",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={1}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
        <BaseButton
          To={`addDiet?maxrank=${maxRankValue}`}
          endIcon={<PostAdd />}
          text={"Add Diet"}
        />
      </FlexBox>

      <BaseDataGrid
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
        rows={diets}
        columns={DietColumns}
        loading={isLoading}
        error={isError}
        getRowHeight={() => "auto"}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
