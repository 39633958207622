import React from "react";
import {
  useRecalculateNutrientAnalysisMutation,
  useRecipeNutrientAnalysisQuery,
} from "../../../../../store/apis/RecipeAPIs";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";

export default function RecipeNutrientAnalysis() {
  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const {
    data: results,
    isFetching: loading,
    // isError,
  } = useRecipeNutrientAnalysisQuery(recipeId, {
    skip: !recipeId,
  });

  const [recalculate, { isLoading: isSubmitting }] =
    useRecalculateNutrientAnalysisMutation();

  const nutrients = results?.data?.nutrients?.map((item) => ({
    ...item,
    id: item?.nutrientId,
  }));
  //  split array by half
  const splitArrayInHalf = (array) => {
    const mid = Math.ceil(array?.length / 2);
    return [array?.slice(0, mid), array?.slice(mid)];
  };

  const nutrientsData = splitArrayInHalf(nutrients);

  const detailedNutrients = results?.data?.nutrientDetails?.map((item) => ({
    ...item,
    id: `${item?.nutrientId}_${item?.ingredientId}_${crypto.randomUUID()}`,
  }));
  // Group objects by nutrientId and store it in array
  const groupByNutrientId = (array) => {
    return array?.reduce((acc, item) => {
      const { nutrientId } = item;
      if (!acc[nutrientId]) {
        acc[nutrientId] = [];
      }
      acc[nutrientId].push(item);
      return acc;
    }, {});
  };
  const groupedNutrients = Object.values(
    groupByNutrientId(detailedNutrients) || []
  );
  const nutrientsDetailsData = splitArrayInHalf(groupedNutrients);

  return (
    <>
      {loading ? (
        <EditSkeleton />
      ) : (
        <Card sx={{ height: { md: "90%", xl: "95%" }, overflow: "auto" }}>
          <Box padding={"5px"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"10px"}>
              <Typography
                fontSize={"32px"}
                fontWeight={"600"}
                lineHeight={"43.65px"}>
                {results?.data?.recipeName}
              </Typography>
              {/* <WarningText
                margin={"5px"}
                sx={{ marginLeft: "0px !important" }}
                text={
                  "Please see Menu Instruction Booklet for Menu/Recipe Nutrient Analysis limitations."
                }
                width={"60%"}
              /> */}
              <BaseButton
                text={"Recalculate"}
                isSubmitting={isSubmitting}
                onClick={() => recalculate(recipeId)}
              />
            </Box>
            <Box>
              <Typography
                fontSize={"19px"}
                fontWeight={"600"}
                lineHeight={"27.28px"}>
                {results?.data?.servingSize
                  .replace(/[()]/g, "") // Remove the parentheses
                  .replace("ServingSize", "Serving Size")}
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginY={"10px"}
                  width={"90%"}>
                  {nutrientsData.map((data, ind) => (
                    <Box key={ind} width={"49%"}>
                      {data.map((item, index) => (
                        <Box
                          key={index}
                          display={"flex"}
                          justifyContent={"space-between"}
                          borderBottom={"1px solid black"}>
                          <Typography
                            fontSize={"16px"}
                            fontWeight={"700"}
                            variant="p">
                            {item?.nutrient?.name}
                          </Typography>
                          <Typography fontSize={"14px"} variant="span">
                            {item?.quantity +
                              " " +
                              item?.nutrient.unitOfMeasure}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="h6"
                fontWeight={"700"}
                color={DMStyles.White}
                borderRadius={"5px"}
                padding={"5px"}
                sx={{ backgroundColor: DMStyles.Teal }}>
                Details By Nutrients
              </Typography>

              <Box
                paddingX={"10px"}
                paddingTop={"10px"}
                display={"flex"}
                justifyContent={"space-between"}>
                {nutrientsDetailsData?.map((nutrient, i) => (
                  <Box
                    key={i}
                    width={"49%"}
                    border={"2px solid black"}
                    borderRadius={"10px"}
                    padding={"16px"}>
                    {nutrient.map((data, index) => (
                      <Box
                        key={index}
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginY={"5px"}
                        borderBottom={" 1px solid black"}>
                        <Typography
                          width={"30%"}
                          fontSize={"18px"}
                          fontWeight={"700"}
                          lineHeight={"24px"}>
                          {data[0]?.nutrient}
                        </Typography>
                        <Box width={"60%"}>
                          {data.map((item, ind) => (
                            <Box
                              key={ind}
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}>
                              <Typography
                                fontSize={"14px"}
                                lineHeight={"19px"}
                                fontWeight={"500"}
                                variant="p">
                                {item.ingredient}
                              </Typography>
                              <Typography fontSize={"14px"} variant="span">
                                {item?.nutrientQuantity.toFixed(2) + " "}
                                {item?.baseUnit === "gram"
                                  ? "g"
                                  : item?.baseUnit}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}
