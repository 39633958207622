import { Grid, Typography, Button, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useCorporateGroupAccessSelectQuery } from "../../../../store/apis/GenericApis";
import BaseSelect from "../../../shared/baseForm/BaseSelect";
import { useShareCorporategroupAccessMutation } from "../../../../store/apis/MenuApis";
import BaseContent from "../../../shared/baseContent/BaseContent";

export default function CorporateGroupAccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("menuId");
  const location = useLocation();

  const title = location?.state?.title;

  const { data: corporateDropdown, isFetching: corporateLoading } =
    useCorporateGroupAccessSelectQuery();

  const [
    shareCorporategroupAccess,
    { isLoading: addSubmitting, isSuccess: addSuccess },
  ] = useShareCorporategroupAccessMutation();

  const isSubmitting = addSubmitting;
  const isSuccess = addSuccess;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    let interval;
    if (!isSubmitting && isSuccess) {
      interval = setInterval(() => navigate(-1), 3000);
    }
    return () => clearInterval(interval);
  }, [isSubmitting, isSuccess]);

  function onSubmit(data, shareWith, removeFrom) {
    shareCorporategroupAccess({
      corporateGroupId: data?.corporateGroupId,
      menuId: id,
      shareWith,
      removeFrom,
    });
  }

  return (
    <>
      <BaseContent
        headerText={"Edit Corporate Group Access"}
        backLink={"/plateFul/Menus"}
        disableHeight={true}>
        <Box height={{ md: "78vh", xl: "84vh" }}>
          <Typography variant="h5" marginY={"10px"}>
            Share {title} Menu
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <BaseSelect
                name="corporateGroupId"
                id="corporateGroupId"
                label="corporate group"
                control={control}
                errors={errors}
                options={corporateDropdown || []}
                loading={corporateLoading}
              />
            </Grid>
            <Grid item md={6} />

            <Grid item md={6}>
              <Button
                sx={{ marginBlock: "5px" }}
                to="addcommunity"
                color={"primary"}
                className="baseButton btn-share"
                variant="contained"
                onClick={handleSubmit((data) => onSubmit(data, true, false))}>
                Share with Corporate Group
              </Button>
              <Button
                color={"primary"}
                className="baseButton btn-remove"
                variant="contained"
                onClick={handleSubmit((data) => onSubmit(data, false, true))}
                isSubmitting={isSubmitting}>
                Remove from Corporate Group
              </Button>
            </Grid>
          </Grid>
        </Box>
      </BaseContent>
    </>
  );
}
