import React from "react";
import { Outlet } from "react-router-dom";
import { CommunityMenuElements } from "./communityMenu.constants";
import { Box, Card } from "@mui/material";
import AdminTabs from "../../shared/AdminTabs";

export default function CommunitiesMenu() {
  return (
    <>
      <AdminTabs Data={CommunityMenuElements} />
      <Card
        sx={{
          padding: "5px",
          overflow: "auto",
          borderTopLeftRadius: "0px !important",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}>
        <Box>
          <Outlet />
        </Box>
      </Card>
    </>
  );
}
