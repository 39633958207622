import { Box } from "@mui/material";
import React from "react";
import styles from "./Resource.module.css";

import { useNavigate, Link } from "react-router-dom";

export const Resources = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box padding="20px">
        <ul className={styles.resourcemain}>
          <li className={styles.resourcemainli}>
            <Link onClick={() => navigate("DietManual")}>Diet Manual</Link>
          </li>
          <li className={styles.resourcemainli}>
            <Link onClick={() => navigate("GuidelineManual")}>
              Guidelines and procedures
            </Link>
          </li>
        </ul>

        {/*  old design source code
        <Box className={styles.resourceMain}>
          <Box
            className={styles.resourceButton}
            onClick={() => navigate("DietManual")}>
            <Typography
              variant="p"
              component={"p"}
              color={"primary"}
              fontWeight={600}>
              Diet Manual
            </Typography>
            <Typography>
              <span className={`${styles["material-icons"]} material-icons`}>
                class_outlined
              </span>
            </Typography>
          </Box>
          <Box
            className={styles.resourceButton}
            onClick={() => navigate("GuidelineManual")}>
            <Typography
              variant="p"
              component={"p"}
              color={"primary"}
              fontWeight={600}>
              Guidelines and procedures
            </Typography>
            <span className={`${styles["material-icons"]} material-icons`}>
              import_contacts_outlined
            </span>
          </Box>
        </Box> */}
      </Box>
    </>
  );
};
