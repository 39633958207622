import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { matchPath, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./AdminTabs.css";
import { KeyboardArrowLeft } from "@mui/icons-material";

export default function AdminTabs({ Data, query, backQuery }) {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [value, setValue] = useState(Data[0].Link);

  useEffect(() => {
    if (
      pathname === "/admin/usermenu" ||
      pathname === "/admin/vendors" ||
      pathname === "/admin/dietitian" ||
      pathname === "/admin/communities" ||
      pathname === "/admin/recipes" ||
      pathname === "/admin/mealcard"
    ) {
      navigate(Data[0].Link);
    }

    let currentLink;
    if (pathname) {
      currentLink = Data.find((x) =>
        matchPath({ path: x.Link, end: false }, pathname)
      );
      if (!currentLink) {
        currentLink = Data[0];
      }
    }
    setValue(currentLink.Link);
  }, [pathname]);

  return (
    <Box width={"100%"}>
      <Box borderBottom={1} borderColor={"divider"}>
        <Tabs
          TabIndicatorProps={{ sx: { display: "none" } }}
          sx={{
            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap",
            },
          }}
          value={value}
          aria-label="Navigation Tabs">
          {Data.map((data, index) => {
            return (
              <LinkTab
                key={index}
                value={data.Link}
                label={data.Name}
                query={query}
                href={data.Link}
                backquery={backQuery}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
}

function LinkTab({ ...rest }) {
  return (
    <Tab
      component={NavLink}
      to={
        rest.label === "Back"
          ? `${rest.href}${rest?.backquery ? rest?.backquery : ""}`
          : `${rest.href}${rest?.query ? rest?.query : ""}`
      }
      className={rest.label === "Back" ? "backTab" : "adminTabs"}
      icon={rest.label === "Back" ? <KeyboardArrowLeft /> : null}
      {...rest}
    />
  );
}
