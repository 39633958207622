import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import { REQUIRED_ERROR } from "utils/errorMessages";

export default function RatesSetup({
  dietitianTypes,
  loadingDietitianTypes,
  errors,
  control,
  register,
}) {
  useEffect(() => {}, [dietitianTypes]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BaseSelect
          options={dietitianTypes || []}
          label="Type"
          name="dietitianTypeId"
          id="dietitianTypeId"
          validationProps={{
            required: REQUIRED_ERROR("Consultant Type"),
          }}
          loading={loadingDietitianTypes}
          control={control}
          errors={errors}></BaseSelect>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="defaultMileageRate">
            Default Mileage Rate
          </InputLabel>
          <OutlinedInput
            {...register("defaultMileageRate", {
              required: REQUIRED_ERROR("Default Mileage Rate"),
              pattern: {
                value: /^\d*\.?\d{0,4}$/,
                message: "Please enter a number with up to 4 decimal places.",
              },
            })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Default Mileage Rate"
            id="defaultMileageRate"
            // defaultValue={0}
          />
          <p style={{ color: "red" }}>{errors.defaultMileageRate?.message}</p>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="defaultHourlyRate">
            Default Hourly Rate
          </InputLabel>
          <OutlinedInput
            id="defaultHourlyRate"
            {...register("defaultHourlyRate", {
              required: REQUIRED_ERROR("Default Hourly Rate"),
              pattern: {
                value: /^\d*\.?\d{0,4}$/,
                message: "Please enter a number with up to 4 decimal places.",
              },
            })}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Default Hourly Rate"
            // defaultValue={0}
          />
          <p style={{ color: "red" }}>{errors.defaultHourlyRate?.message}</p>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          id="comments"
          label="Comments"
          multiline
          {...register("comments")}
          rows={4}
          defaultValue=""
        />
      </Grid>
    </Grid>
  );
}
